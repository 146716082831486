import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import {
  Cached,
  ExpandMore,
  Notifications,
  TrendingUp,
} from '@material-ui/icons';
import { useValueObserver } from '@superdispatch/hooks';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { CheckIconFilled } from 'orders/assets/CheckIconFilled';
import { LightBulbIcon } from 'orders/assets/LightBulbIcon';
import { useOrder } from 'orders/data/OrderAPI';
import { ReactNode, useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import Order from 'shared/types/order';
import styled from 'styled-components';
import {
  NotificationSettingsRecommendation,
  useNotificationSettingsRecommendation,
} from './NotificationSettingsRecommendation';
import {
  OrderAutoRepostRecommendation,
  useAutoRepostRecommendation,
} from './OrderAutoRepostRecommendation';
import {
  OrderDetailsRecommendation,
  useOrderDetailsRecommendation,
} from './OrderDetailsRecommendation';
import {
  OrderPriceRecommendation,
  useOrderPriceRecommendation,
} from './OrderPriceRecommendation';
import { RecommendationsProgressBar } from './RecommendationsProgressBar';

const Container = styled.div`
  padding-right: 24px;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    padding-left: 32px;
  }
`;

const Subtitle = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: flex-start;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

interface RecommendationStepProps {
  title: string;
  subtitle: ReactNode;
  icon: ReactNode;
  isCompleted: boolean;
  defaultExpanded?: boolean;
  children: ReactNode;
}

function RecommendationStep({
  title,
  subtitle,
  icon,
  isCompleted,
  defaultExpanded,
  children,
}: RecommendationStepProps) {
  if (isCompleted) {
    return (
      <Box
        padding="small"
        borderWidth="small"
        borderRadius="small"
        borderColor="Silver400"
      >
        <Columns space="xsmall" collapseBelow="tablet">
          <Column width="content">
            <CheckIconFilled htmlColor={Color.Green300} />
          </Column>

          <Column>
            <Stack space="xxsmall">
              <TextBox variant="heading-4" color="secondary">
                {title}
              </TextBox>
              {subtitle}
            </Stack>
          </Column>
        </Columns>
      </Box>
    );
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      TransitionProps={{
        timeout: {
          enter: 1000,
          exit: 1000,
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Columns space="xsmall" collapseBelow="tablet">
          <Column width="content">{icon}</Column>

          <Column>
            <Stack space="xxsmall">
              <TextBox variant="heading-4">{title}</TextBox>
              {subtitle}
            </Stack>
          </Column>
        </Columns>
      </AccordionSummary>

      <AccordionDetails>
        <Container>{children}</Container>
      </AccordionDetails>
    </Accordion>
  );
}

function useOrderRecommendations(order?: Order) {
  const [success, setSuccess] = useState<string[]>([]);

  const orderStatus = useOrderDetailsRecommendation(order);
  const priceStatus = useOrderPriceRecommendation(order);
  const { status: notificationStatus, isNotificationsDontSuggestAgainClicked } =
    useNotificationSettingsRecommendation();
  const { status: autoRepostStatus, isAutoRepostDontSuggestAgainClicked } =
    useAutoRepostRecommendation();

  useValueObserver(priceStatus, (prev) => {
    if (prev === 'error' && priceStatus === 'success') {
      setSuccess((states) => [...states, 'price']);
    }
  });

  useValueObserver(notificationStatus, (prev) => {
    if (prev === 'error' && notificationStatus === 'success') {
      setSuccess((states) => [...states, 'notification']);
    }
  });

  useValueObserver(autoRepostStatus, (prev) => {
    if (prev === 'error' && autoRepostStatus === 'success') {
      setSuccess((states) => [...states, 'autoRepost']);
    }
  });

  useValueObserver(orderStatus, (prev) => {
    if (prev === 'error' && orderStatus === 'success') {
      setSuccess((states) => [...states, 'order']);
    }
  });

  const errorSteps = [
    orderStatus === 'error' && 'order',
    autoRepostStatus === 'error' && 'autoRepost',
    priceStatus === 'error' && 'price',
    notificationStatus === 'error' && 'notification',
  ].filter(Boolean);

  const steps = [
    (orderStatus === 'error' || success.includes('order')) && 'order',
    (autoRepostStatus === 'error' || success.includes('autoRepost')) &&
      'autoRepost',
    (priceStatus === 'error' || success.includes('price')) && 'price',
    (notificationStatus === 'error' || success.includes('notification')) &&
      'notification',
  ].filter(Boolean);

  const totalStepsCount = steps.length;
  const completedStepsCount = success.length;

  return {
    steps,
    errorSteps,
    success,
    totalStepsCount,
    completedStepsCount,
    orderStatus,
    autoRepostStatus,
    priceStatus,
    notificationStatus,
    isNotificationsDontSuggestAgainClicked,
    isAutoRepostDontSuggestAgainClicked,
  };
}

export function OrderRequestRecommendations({ order }: { order: Order }) {
  const { data } = useOrder(order.guid, true); // Order requests list doesn't have vehicle info
  const isRecommandationsEnabled = useFeatureToggle(
    'order-faster-booking-recommendations.enabled.ui',
  );

  const {
    errorSteps,
    totalStepsCount,
    completedStepsCount,
    orderStatus,
    autoRepostStatus,
    priceStatus,
    success,
    notificationStatus,
    isNotificationsDontSuggestAgainClicked,
    isAutoRepostDontSuggestAgainClicked,
  } = useOrderRecommendations(data);

  if (!isRecommandationsEnabled) return null;

  const [firstError] = errorSteps;

  return (
    <Box padding={['small', 'small', 'large']}>
      <Stack space="large">
        <RecommendationsProgressBar
          completedStepsCount={completedStepsCount}
          totalStepsCount={totalStepsCount}
        />

        <Stack space="small">
          {(orderStatus === 'error' || success.includes('order')) && (
            <RecommendationStep
              defaultExpanded={firstError === 'order'}
              title="Maximize carrier reach"
              isCompleted={orderStatus === 'success'}
              icon={<TrendingUp htmlColor="#5F6368" />}
              subtitle={
                <Subtitle>
                  <LightBulbIcon
                    display="inline-block"
                    color="primary"
                    fontSize="small"
                    style={{ paddingBottom: '2px' }}
                  />
                  <TextBox color="secondary">
                    Get requests 5 times faster by providing additional details
                    to your postings.
                  </TextBox>
                </Subtitle>
              }
            >
              <OrderDetailsRecommendation order={order} />
            </RecommendationStep>
          )}

          {(autoRepostStatus === 'error' || success.includes('autoRepost')) && (
            <RecommendationStep
              defaultExpanded={firstError === 'autoRepost'}
              title="Keep receiving requests by reducing downtime"
              isCompleted={autoRepostStatus === 'success'}
              icon={<Cached htmlColor={Color.Dark100} />}
              subtitle={
                <Stack space="small">
                  <TextBox color="secondary">
                    Keep loads posted on the Super Loadboard—automatically
                    repost canceled loads for faster bookings.
                  </TextBox>
                  {isAutoRepostDontSuggestAgainClicked && (
                    <TextBox>
                      Thanks for letting us know. This recommendation won’t
                      appear for you again.
                    </TextBox>
                  )}
                </Stack>
              }
            >
              <OrderAutoRepostRecommendation />
            </RecommendationStep>
          )}

          {(priceStatus === 'error' || success.includes('price')) && (
            <RecommendationStep
              title={
                priceStatus === 'error'
                  ? 'Below Recommended Price'
                  : 'Good Price'
              }
              defaultExpanded={firstError === 'price'}
              isCompleted={priceStatus === 'success'}
              icon={<RecommendedPriceIcon />}
              subtitle={
                <Stack space="small">
                  <TextBox color="secondary">
                    Leverage intelligent price recommendations driven by current
                    market trends to get loads booked faster.
                  </TextBox>

                  {!!order.recommended_price && priceStatus === 'success' && (
                    <TextBox>
                      {formatCurrency(order.recommended_price, {
                        maximumFractionDigits: 0,
                      })}{' '}
                      is Recommended price for faster booking.
                    </TextBox>
                  )}
                </Stack>
              }
            >
              <OrderPriceRecommendation order={order} />
            </RecommendationStep>
          )}

          {(notificationStatus === 'error' ||
            success.includes('notification')) && (
            <RecommendationStep
              title="Speed up bookings with notifications"
              defaultExpanded={firstError === 'notification'}
              isCompleted={notificationStatus === 'success'}
              icon={<Notifications color="action" />}
              subtitle={
                <Stack space="small">
                  <Subtitle>
                    <LightBulbIcon
                      color="primary"
                      fontSize="small"
                      style={{ paddingBottom: '2px' }}
                    />
                    <TextBox color="secondary">
                      Get up to 30% faster bookings by enabling SMS
                      notifications.
                    </TextBox>
                  </Subtitle>

                  {isNotificationsDontSuggestAgainClicked && (
                    <TextBox>
                      Thanks for letting us know. This recommendation won’t
                      appear for you again.
                    </TextBox>
                  )}
                </Stack>
              }
            >
              <NotificationSettingsRecommendation order={order} />
            </RecommendationStep>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
