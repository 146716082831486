import { atom, useAtom } from 'jotai';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  OnboardingStepKey,
  useMarkOnboardingStepsAsDone,
} from './OnboardingAPI';
import { OnboardingAction } from './onboardingMap';
import { usePostToSuperLoadboard } from './usePostToSuperLoadboard';

export interface UseHandleOnboardingActionProps {
  stepKey: OnboardingStepKey;
  orderGuid?: string;
  onComplete?: () => void;
}

export const isCalendlyOpenAtom = atom(false);

export function useHandleOnboardingAction({
  stepKey,
  orderGuid,
  onComplete,
}: UseHandleOnboardingActionProps) {
  const navigate = useNavigate();
  const { markOnboardingStepsAsDone } = useMarkOnboardingStepsAsDone();
  const [, setIsCalendlyOpen] = useAtom(isCalendlyOpenAtom);
  const currentActionRef = useRef<OnboardingAction | null>(null);

  function handleCompleteAction() {
    if (currentActionRef.current?.shouldCompleteStep) {
      markCurrentStepAsDone();
    }

    onComplete?.();
  }

  function markCurrentStepAsDone() {
    void markOnboardingStepsAsDone([stepKey]);
  }

  const { mutate: postToSuperLoadboard, isLoading: isPostingToSuperLoadboard } =
    usePostToSuperLoadboard({
      onError: handleCompleteAction,
      onSuccess: handleCompleteAction,
    });

  function handleAction(action: OnboardingAction) {
    currentActionRef.current = action;

    if (action.type === 'GET_HELP_CALENDLY') {
      setIsCalendlyOpen(true);
      handleCompleteAction();
    } else if (action.type === 'NAVIGATE') {
      if (action.to.includes(':orderGuid') && orderGuid) {
        navigate(action.to.replace(':orderGuid', orderGuid));
      } else {
        navigate(action.to);
      }

      handleCompleteAction();
    } else if (action.type === 'POST_TO_SUPER_LOADBOARD' && orderGuid) {
      postToSuperLoadboard({
        orderGuid,
      });
    }
  }

  return {
    handleAction,
    markCurrentStepAsDone,
    isAsyncActionLoading: isPostingToSuperLoadboard,
  };
}
