import { Link } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { FormikCheckboxField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import {
  useShipperProfile,
  useShipperProfileAPI,
} from 'shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from 'shipper-profile/data/ShipperProfileDTO';
import styled from 'styled-components';
import { trackOrderRecommendations } from './OrderRequestRecommendationAnalytics';

const DONT_SUGGEST_AGAIN_LS_KEY =
  'is_dont_suggest_again_auto_repost_recommendation_clicked';
const AUTO_REPOST_RECOMMENDATION_SUBMITTED_LS_KEY =
  'auto_repost_recommendation_submitted';

const Container = styled.div`
  margin-left: 30px;
`;

export function useAutoRepostRecommendation() {
  const { data: profile } = useShipperProfile();
  const [status, setStatus] = useState<null | 'error' | 'success'>(null);
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const isAutoRepostRecommendationEnabled = useFeatureToggle(
    'auto-repost-recommendation.enabled.ui',
  );
  const isAutoRepostDontSuggestAgainClicked = Boolean(
    useStorageValue(DONT_SUGGEST_AGAIN_LS_KEY),
  );
  const isAutoRepostRecommendationSubmitted = Boolean(
    useStorageValue(AUTO_REPOST_RECOMMENDATION_SUBMITTED_LS_KEY),
  );

  useEffect(() => {
    if (!profile) {
      return;
    }

    const isFullyConfigured =
      !!profile.is_auto_repost_for_decline_offers_enabled &&
      !!profile.is_auto_repost_for_cancel_offers_enabled;

    const isHalfConfigured =
      !!profile.is_auto_repost_for_decline_offers_enabled ||
      !!profile.is_auto_repost_for_cancel_offers_enabled;

    if (
      isFullyConfigured ||
      !isAutoRepostRecommendationEnabled ||
      isAutoRepostDontSuggestAgainClicked ||
      !canUpdateProfile ||
      (isAutoRepostRecommendationSubmitted && isHalfConfigured)
    ) {
      setStatus('success');
    } else {
      setStatus('error');
    }
  }, [
    profile,
    canUpdateProfile,
    isAutoRepostRecommendationEnabled,
    isAutoRepostDontSuggestAgainClicked,
    isAutoRepostRecommendationSubmitted,
  ]);

  return {
    status,
    isAutoRepostDontSuggestAgainClicked,
  };
}

export function OrderAutoRepostRecommendation() {
  const { data: profile, refetch } = useShipperProfile();
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();

  const formik = useFormikEnhanced({
    initialValues: { ...profile },
    onSubmit: (values) => {
      return updateLoadboardSettings(values as ShipperProfileDTO);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackOrderRecommendations({
        is_auto_repost_for_decline_offers_enabled:
          updatedValues.is_auto_repost_for_decline_offers_enabled,
        is_auto_repost_for_cancel_offers_enabled:
          updatedValues.is_auto_repost_for_cancel_offers_enabled,
      });
      writeStorageItem(
        AUTO_REPOST_RECOMMENDATION_SUBMITTED_LS_KEY,
        String(true),
      );
      void refetch();
    },
    onSubmitFailure(submitError) {
      addSnackbar(submitError.message, { variant: 'error' });
    },
  });

  const handleDontSuggestClick = () => {
    writeStorageItem(DONT_SUGGEST_AGAIN_LS_KEY, String(true));
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="large">
          <Stack space="small">
            <FormikCheckboxField
              name="is_auto_repost_for_decline_offers_enabled"
              label="Repost when Carrier Declines the Load Offer"
            />
            <Stack space="none">
              <FormikCheckboxField
                name="is_auto_repost_for_cancel_offers_enabled"
                label="Repost when Carrier Cancels Order after Accepting"
              />
              <Container>
                <TextBox color="secondary">
                  Loads won&apos;t be reposted if the carrier cancels for any of
                  these reasons:
                </TextBox>

                <Box marginLeft="xsmall">
                  <Inline>
                    &bull;<TextBox color="secondary">Unit Not Ready</TextBox>
                  </Inline>
                  <Inline>
                    &bull;
                    <TextBox color="secondary">
                      Unknown Inoperable Vehicle
                    </TextBox>
                  </Inline>
                  <Inline>
                    &bull;
                    <TextBox color="secondary">Carrier leaves a note</TextBox>
                  </Inline>
                </Box>
              </Container>
            </Stack>

            <Stack space="xxsmall">
              <TextBox variant="body-semibold">
                All reposted orders will keep:
              </TextBox>
              <Box marginLeft="xsmall">
                <Inline>
                  &bull;
                  <TextBox>
                    The original pickup and delivery dates as specified prior to
                    any negotiation or carrier request.
                  </TextBox>
                </Inline>
                <Inline>
                  &bull;
                  <TextBox>
                    The total carrier price as established before any
                    negotiation or carrier request.
                  </TextBox>
                </Inline>
              </Box>
            </Stack>

            <Inline verticalAlign="center">
              <Help fontSize="small" color="action" />
              <Link
                color="textSecondary"
                href="https://support.superdispatch.com/en/articles/10058400-how-to-set-up-shipper-tms-automatic-load-reposting"
              >
                Learn how automatic load repost works
              </Link>
            </Inline>
          </Stack>
          <Inline space="small">
            <Button type="submit" disabled={!formik.dirty}>
              Save
            </Button>
            <Button variant="text" onClick={handleDontSuggestClick}>
              Don’t suggest again
            </Button>
          </Inline>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
