import { useState } from 'react';
import { getNudgingDialogProps } from './getNudgingDialogProps';
import { useCurrentOnboardingStep } from './OnboardingAPI';
import {
  onboardingMap,
  OnboardingPopup,
  OnboardingPopupData,
} from './onboardingMap';
import { OnboardingNudgingDialog } from './OnboardingNudgingDialog';
import { UseHandleOnboardingActionProps } from './useHandleOnboardingAction';
import { useHandleOnboardingPopupAction } from './useHandleOnboardingPopupAction';

export function OnboardingImmediateDialogManager() {
  const { stepKey, stepOrderGuid } = useCurrentOnboardingStep();

  if (!stepKey) {
    return null;
  }

  const { popup } = onboardingMap[stepKey];

  if (!popup?.isImmediate || isDialogClosed(stepKey)) {
    return null;
  }

  return (
    <AdaptedDialog stepKey={stepKey} orderGuid={stepOrderGuid} popup={popup} />
  );
}

function AdaptedDialog(
  props: Omit<UseHandleOnboardingActionProps, 'onComplete'> & {
    popup: OnboardingPopup;
  },
) {
  const [open, setOpen] = useState(true);

  const { handlePopupAction, isAsyncActionLoading } =
    useHandleOnboardingPopupAction({
      ...props,
      onComplete: () => {
        setOpen(false);
        saveClosedDialog(props.stepKey);
      },
    });

  const PopupComponent = props.popup.Component;

  if (PopupComponent) {
    return (
      <PopupComponent
        open={open}
        onClose={() => setOpen(false)}
        onAction={handlePopupAction}
      />
    );
  }

  return (
    <OnboardingNudgingDialog
      {...getNudgingDialogProps({
        popupData: props.popup.data as OnboardingPopupData,
        handlePopupAction,
        isAsyncActionLoading,
      })}
      open={open}
      onClose={() => setOpen(false)}
    />
  );
}

const CLOSED_DIALOGS_LOCAL_STORAGE_KEY = 'onboarding-closed-dialogs';

function getClosedDialogs() {
  const json = localStorage.getItem(CLOSED_DIALOGS_LOCAL_STORAGE_KEY);

  if (!json) {
    return [];
  }

  return JSON.parse(json) as string[];
}

function saveClosedDialog(dialogId: string) {
  const closedDialogs = getClosedDialogs();
  closedDialogs.push(dialogId);
  localStorage.setItem(
    CLOSED_DIALOGS_LOCAL_STORAGE_KEY,
    JSON.stringify(closedDialogs),
  );
}

function isDialogClosed(dialogId: string) {
  const closedDialogs = getClosedDialogs();
  return closedDialogs.includes(dialogId);
}
