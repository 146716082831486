import { Typography } from '@material-ui/core';
import { CheckCircle, Lock } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { VerificationSidebar } from './VerificationSidebar';

const LockContainer = styled.div`
  width: fit-content;
  border-radius: 50%;
  padding: 8px;
  background-color: ${Color.White10};
`;

function VerificationPoints({ text }: { text: string }) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <Columns align="center" space="xsmall">
      <Column width="content">
        <CheckCircle fontSize={isMobile ? 'small' : 'default'} />
      </Column>
      <Column>
        <Typography variant={isMobile ? 'caption' : 'body2'}>{text}</Typography>
      </Column>
    </Columns>
  );
}

interface VerificationApplicationSidebarProps {
  actions?: ReactNode;
}

export function VerificationApplicationSidebar({
  actions,
}: VerificationApplicationSidebarProps) {
  return (
    <VerificationSidebar
      bottomText="Verification may take up to two business days to complete."
      actions={actions}
      fullMobileHeight={true}
    >
      <Stack space="medium">
        <LockContainer>
          <Lock />
        </LockContainer>
        <Typography variant="h2">
          Unlock the Power of Super Dispatch:
        </Typography>
        <Stack space="small">
          <VerificationPoints text="Access Thousands of Verified Carriers" />
          <VerificationPoints text="Send Direct Offers to Preferred Carriers" />
          <VerificationPoints text="Post on the Super LoadBoard" />
          <VerificationPoints text="Manage Orders & Payments Seamlessly" />
          <VerificationPoints text="Say Goodbye to Endless Calls & Paperwork" />
        </Stack>
      </Stack>
    </VerificationSidebar>
  );
}
