import { Divider, Link, MenuItem } from '@material-ui/core';
import { FormikCheckboxField, FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import {
  SHIPPER_TYPE,
  VerificationCompanyInformationDTO,
} from '../data/VerificationApplicationDTO';
import { ShipperTypeHelpDrawer } from './ShipperTypeHelpDrawer';

export const SHIPPER_TYPES_MAP: Array<[typeof SHIPPER_TYPE[number], string]> = [
  ['ONLINE_MARKETPLACE', 'Online Marketplace'],
  ['BROKER', 'Broker'],
  ['DEALER', 'Dealer'],
  ['CARRIER_DRIVER', 'Carrier/Driver'],
  ['CORP_RELOCATION', 'Corp Relocation'],
  ['OEM', 'OEM'],
  ['AUCTION', 'Auction'],
  ['RENTAL', 'Rental'],
  ['IMPORT_EXPORT', 'Import/Export'],
  ['FINANCIAL_INSTITUTION', 'Financial Institution'],
  ['SALVAGE', 'Salvage'],
  ['WHOLESALER', 'Wholesaler'],
  ['UPFITTER', 'Upfitter'],
];

export function YourCompanyForm() {
  const isDBANameEnabled = useFeatureToggle('dba_name_displayed.enabled.ui');
  const [currentDrawer, setCurrentDrawer] = useState<
    'shipper_type_help' | undefined
  >();

  const formik = useFormikContext<VerificationCompanyInformationDTO>();

  return (
    <Stack space="small">
      <ShipperTypeHelpDrawer
        open={currentDrawer === 'shipper_type_help'}
        onClose={() => setCurrentDrawer(undefined)}
      />
      <FormikTextField
        name="company_type"
        label="Shipper Type"
        helperText={
          <Link
            color="primary"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on Learn More',
                utm_content: 'Shipper Type',
              });
              setCurrentDrawer('shipper_type_help');
            }}
          >
            Learn more
          </Link>
        }
        select={true}
        fullWidth={true}
      >
        {SHIPPER_TYPES_MAP.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FormikTextField>

      <Divider />

      <FormikTextField
        name="legal_company_name"
        label="Legal Company Name"
        fullWidth={true}
      />

      <FormikTextField
        name="dba_company_name"
        label="Doing Business As (Optional)"
        fullWidth={true}
        onChange={(event) => {
          if (!event.target.value) {
            void formik.setFieldValue('dba_name_displayed', false);
          }
        }}
      />

      {isDBANameEnabled && (
        <FormikCheckboxField
          name="dba_name_displayed"
          label="Use DBA everywhere on the platform, including invoices"
          disabled={!formik.values.dba_company_name}
        />
      )}

      <Divider />

      <Stack>
        <FormikTextField
          name="street"
          label="Company Address:"
          placeholder="Street"
          fullWidth={true}
        />

        <Columns space="xsmall" collapseBelow="tablet">
          <Column>
            <FormikTextField name="city" placeholder="City" fullWidth={true} />
          </Column>
          <Column>
            <FormikTextField
              name="state"
              placeholder="State"
              fullWidth={true}
            />
          </Column>
          <Column>
            <FormikTextField
              name="zip"
              placeholder="ZIP Code"
              fullWidth={true}
            />
          </Column>
        </Columns>
      </Stack>
    </Stack>
  );
}
