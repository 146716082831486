import { formatPaymentMethod, formatPaymentTerm } from '@superdispatch/sdk';
import { Inline } from '@superdispatch/ui';
import { SuperPayIcon } from 'shared/icons/SuperPayIcon';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';

interface Props {
  terms?: string | null;
  method?: string | null;
}

export function PostingPaymentTerm({ method, terms }: Props) {
  if (method === 'superpay') {
    return (
      <Inline noWrap={false} verticalAlign="center" space="xxsmall">
        <SuperPayIcon />
        {terms && formatPaymentTerm(terms, { fallback: terms })}
      </Inline>
    );
  }

  return (
    <InlineBulletItems>
      {method && formatPaymentMethod(method, { fallback: method })}
      {terms && formatPaymentTerm(terms, { fallback: terms, short: true })}
    </InlineBulletItems>
  );
}
