import { Link, Typography } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { Alert, Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { FeatureToggle, useFeatureToggle } from 'shared/data/FeatureToggle';
import { useBillingStatus } from 'shared/errors/paywall/data/PaywallAPI';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { LayoutBanner } from 'shared/layout/LayoutBanner';
import styled from 'styled-components';

const AlertRoot = styled.div`
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.2);
`;

const AlertTitle = styled((props) => <Typography variant="body2" {...props} />)`
  font-weight: 600;
`;

export function SubscriptionPaymentFailedAlert() {
  const { showCustomerDialog } = useCustomerSupport();
  const navigate = useNavigate();
  const isClosed = useIsAlertClosed();
  const isPastDuePaymentEnabled = useFeatureToggle(
    'subscription-past-due-payment.enabled',
  );
  const { user } = useUserState();
  const isSelfServe = user?.shipper.is_self_serve;
  const isPaymentFailed = useIsPaymentFailed();

  if (
    !isSelfServe ||
    !isPastDuePaymentEnabled ||
    isClosed ||
    !isPaymentFailed
  ) {
    return null;
  }

  function handleClose() {
    writeIsSubscriptionPaymentFailedAlertClosed(true);
  }

  return (
    <FeatureToggle name="subscription-past-due-payment.enabled">
      <LayoutBanner>
        <AlertRoot>
          <Alert severity="critical" onClose={handleClose}>
            <Stack>
              <div>
                <AlertTitle>Payment Required: Action Needed</AlertTitle>
                <Typography variant="body2">
                  Your recent payment attempt was unsuccessful. Please update
                  your payment method to continue to access the Super Loadboard.
                </Typography>
              </div>

              <Inline space="small">
                <Button
                  size="small"
                  variant="neutral"
                  onClick={() => {
                    navigate('/subscription');
                  }}
                >
                  Open Subscription Details
                </Button>
                <Link onClick={() => showCustomerDialog()} component="button">
                  Contact Support
                </Link>
              </Inline>
            </Stack>
          </Alert>
        </AlertRoot>
      </LayoutBanner>
    </FeatureToggle>
  );
}

function useIsPaymentFailed() {
  const { data: billingStatus } = useBillingStatus();
  return (
    billingStatus?.status === 'NOT_PAID' ||
    billingStatus?.status === 'PAYMENT_DUE'
  );
}

const BANNER_CLOSED_STORAGE_KEY = 'is_subscription_payment_failed_alert_closed';

function useIsAlertClosed() {
  return useStorageValue(BANNER_CLOSED_STORAGE_KEY) === 'true';
}

export function writeIsSubscriptionPaymentFailedAlertClosed(value: boolean) {
  writeStorageItem(BANNER_CLOSED_STORAGE_KEY, String(value));
}
