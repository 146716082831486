import { useDefaultTimeZone } from '@superdispatch/dates';
import { ForgotPasswordPage } from 'auth/ForgotPasswordPage';
import { ResetPasswordPage } from 'auth/ResetPasswordPage';
import { VerifyAccountPage } from 'auth/VerifyAccountPage';
import { customersRoute } from 'customers/CustomersPage';
import { manageCarriersRoute } from 'manage-carriers/ManageCarriersContainer';
import { DashboardOrdersPage } from 'orders/dashboard/DashboardOrdersPage';
import { lazy, useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { ReportsPage } from 'reports/ReportsPage';
import { settingsRoute } from 'settings/SettingsPage';
import { useUserState } from 'shared/data/AppUserState';
import { isInternalUser } from 'shared/data/AppUtils';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { Paywall } from 'shared/errors/paywall/Paywall';
import { useHasPaywall } from 'shared/errors/paywall/PaywallEvents';
import { browserFingerprint } from 'shared/helpers/BrowserFingerprint';
import { identifyErrorTrackerUser } from 'shared/helpers/MonitoringService';
import { ShipperProfilePage } from 'shipper-profile/ShipperProfilePage';
import { verificationApplicationRoute } from 'shipper-profile/verification-application/VerificationApplicationContainer';
import { SubscriptionDetailsPage } from 'subscription-details/SubscriptionDetailsPage';
import { SubscriptionDetailsPageLegacy } from 'subscription-details/SubscriptionDetailsPageLegacy';
import { superPayRoute } from 'superpay/SuperPayContainer';
import { terminalsRoute } from 'terminals/TerminalsPage';
import { createAuthenticatedRouter } from './AppRouter';
import { LoginPage } from './auth/LoginPage';
import { LoadingContainer } from './LoadingContainer';
import { ordersRoute } from './orders/OrdersContainer';
import { useNotificationHandler } from './shared/data/notifications/NotificationHelpers';
import {
  identifyUser,
  trackEventLegacy,
} from './shared/helpers/AnalyticsHelpers';
import { useCustomerSupport } from './shared/helpers/customer-support/CustomerSupport';
import { updateIntercom } from './shared/helpers/customer-support/intercom/Intercom';
import { stringifySearchQuery, useQuery } from './shared/helpers/RouteHelpers';

const DashboardPageLazy = lazy(() => import('./dashboard/DashboardPage'));

const unauthenticatedRouter = createBrowserRouter([
  {
    path: '/signin/verify',
    element: <VerifyAccountPage />,
  },
  {
    path: '/signin',
    element: <LoginPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '*?',
    Component: () => {
      const location = useLocation();

      return (
        <Navigate
          to={{
            pathname: '/signin',
            search: stringifySearchQuery({
              redirect_url: `${location.pathname}${decodeURIComponent(
                location.search,
              )}`,
            }),
          }}
        />
      );
    },
  },
]);

const paywallRouter = createAuthenticatedRouter([
  {
    path: '*?',
    handle: { layout: 'blank' },
    Component: Paywall,
  },
]);

const authenticatedRouter = createAuthenticatedRouter([
  ordersRoute,
  customersRoute,
  terminalsRoute,
  manageCarriersRoute,
  verificationApplicationRoute,
  superPayRoute,
  settingsRoute,
  {
    path: 'dashboard',
    handle: { isProtected: true },
    Component: DashboardPageLazy,
  },
  {
    path: 'overview/orders/:type',
    Component: DashboardOrdersPage,
  },
  {
    path: 'reports/:id?',
    handle: { isProtected: true },
    Component: ReportsPage,
  },
  {
    path: 'profile/*',
    Component: ShipperProfilePage,
  },
  {
    path: 'notification-settings',
    element: <Navigate to="/settings/notifications" replace={true} />,
  },
  {
    path: 'users/*',
    element: <Navigate to="/settings/users" replace={true} />,
  },
  {
    path: 'manage-fields/*',
    element: <Navigate to="/settings/manage-fields" replace={true} />,
  },
  {
    path: 'general-settings',
    element: <Navigate to="/settings" replace={true} />,
  },
  {
    path: 'super-loadboard-settings',
    element: <Navigate to="/settings/super-loadboard" replace={true} />,
  },
  {
    path: 'subscription',
    Component: () => {
      const isSubscriptionEnabled = useFeatureToggle(
        'update-credit-card-info.enabled',
      );
      const isSubscriptionDetailsV2Enabled = useFeatureToggle(
        'subscription-details-v2.ui',
      );
      const { user, isAdmin, isSuperUser } = useUserState();
      const showSubscription =
        (isAdmin || isSuperUser) &&
        user?.shipper.is_self_serve &&
        isSubscriptionEnabled;

      if (!showSubscription) {
        return <Navigate to="/orders" replace={true} />;
      }

      if (isSubscriptionDetailsV2Enabled) {
        return <SubscriptionDetailsPage />;
      }

      return <SubscriptionDetailsPageLegacy />;
    },
  },
  {
    path: '*?',
    Component: () => {
      const [{ redirect_url }] = useQuery();

      return (
        <Navigate
          to={typeof redirect_url === 'string' ? redirect_url : '/orders'}
        />
      );
    },
  },
]);

export function App() {
  const { showCustomerButton, hideCustomerButton } = useCustomerSupport();
  const { user, authState } = useUserState();

  const hasPaywall = useHasPaywall();

  useDefaultTimeZone(user?.shipper.zone_offset_minutes ?? 'local');

  useNotificationHandler();

  useEffect(() => {
    if (authState === 'pending') {
      return;
    }

    if (user) {
      identifyUser(user, () => {
        // update intercom surveys, tours etc.
        updateIntercom();
      });
      identifyErrorTrackerUser({
        id: user.guid,
        shipperGuid: user.shipper.guid,
        shipperName: user.shipper.name,
        browserFingerprint,
      });

      if (user.shipper.shipper_type === 'CUSTOMER' || isInternalUser(user)) {
        hideCustomerButton();
      } else {
        showCustomerButton();
      }
    } else {
      identifyUser();
    }

    trackEventLegacy('Started Session');
  }, [authState, hideCustomerButton, showCustomerButton, user]);

  if (authState === 'pending') {
    return <LoadingContainer />;
  }

  if (authState === 'unauthorized') {
    return <RouterProvider router={unauthenticatedRouter} />;
  }

  return (
    <RouterProvider
      key={hasPaywall ? 'paywall' : 'authenticated'}
      router={hasPaywall ? paywallRouter : authenticatedRouter}
    />
  );
}
