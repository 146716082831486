import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { List } from 'shared/ui/List';
import styled from 'styled-components';
import limitedExperience from '../../assets/limited_experience.png';

const Illustration = styled.img`
  width: 100%;
`;

const CustomDialogTitle = styled(DialogTitle)`
  padding: 16px;
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 0 16px;
`;

const CenteredDialogActions = styled(DialogActions)`
  justify-content: center;
  padding: 16px 16px 24px;
`;

interface LimitedExperienceDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function LimitedExperienceDialog({
  isOpen,
  onCancel,
  onConfirm,
}: LimitedExperienceDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth={true}>
      <CustomDialogTitle>
        <Stack space="small">
          <Illustration
            src={limitedExperience}
            alt="Limited expirience carrier warning"
          />
          <Typography variant="h3">Warning</Typography>
        </Stack>
      </CustomDialogTitle>
      <CustomDialogContent>
        <Stack space="small">
          <Typography>
            You are dispatching a load offer to a verified carrier with limited
            experience moving cars and/or limited experience on Super Dispatch.
            This carrier has one or more of the following characteristics:
          </Typography>
          <List>
            <li>
              <Typography variant="body1" color="textPrimary">
                Less than 90 days FMCSA registration
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                Less than 3 ratings on Super Dispatch
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                Less than 5 delivered loads on Super Dispatch
              </Typography>
            </li>
          </List>
          <Typography>
            These characteristics indicate higher risk and could impact
            reliability. By continuing to send the load offer to this carrier,
            you are accepting these risk factors and the danger therein.
          </Typography>
        </Stack>
      </CustomDialogContent>

      <CenteredDialogActions>
        <Inline space="small" horizontalAlign="center">
          <Button variant="critical" onClick={onConfirm}>
            I Understand the Risks
          </Button>
          <Button variant="neutral" onClick={onCancel}>
            Cancel
          </Button>
        </Inline>
      </CenteredDialogActions>
    </Dialog>
  );
}
