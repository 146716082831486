import { MouseEvent, useMemo } from 'react';
import Order from 'shared/types/order';
import { isSuperpayPayment } from '../../../../core/PaymentUtils';
import { useBulkOrderActionAPI } from '../../../data/OrderActionAPI';
import { OrderActionSource } from '../OrderActionsAnalytics';
import { trackScheduleEvent } from './scheduleAnanlytics';
import { SuperPayNowGroupButtons } from './SuperPayNowButton';

interface SuperPayBulkNowButtonProps {
  canScheduleSuperPay: boolean;
  orders: Order[];
  onSuperPayNow: (event: MouseEvent) => void;
  source: 'orders_list' | 'order_view_page' | OrderActionSource;
}
export function SuperPayBulkNowButton({
  orders,
  canScheduleSuperPay,
  onSuperPayNow,
  source,
}: SuperPayBulkNowButtonProps) {
  const { bulkScheduleSuperPayNow } = useBulkOrderActionAPI();
  const isScheduleDialogDisabled = useMemo(
    () =>
      orders.every(
        (order) =>
          ['accepted', 'delivered'].includes(order.status) ||
          isSuperpayPayment(order.payment),
      ),
    [orders],
  );

  const price = useMemo(
    () => orders.reduce((acc, order) => acc + (order.price ?? 0), 0),
    [orders],
  );

  const onScheduleSuperPayNow = (expectedDate: string) => {
    const ids = orders.map((order) => order.id);
    return bulkScheduleSuperPayNow(ids, expectedDate);
  };

  return (
    <SuperPayNowGroupButtons
      canScheduleSuperPay={canScheduleSuperPay}
      totalCharge={price}
      ordersCount={orders.length}
      onSuperPayNow={onSuperPayNow}
      onScheduleSuperPayNow={onScheduleSuperPayNow}
      buttonType="neutral"
      buttonGroupType="outlined"
      canScheduleConfirmDialog={!isScheduleDialogDisabled}
      onScheduleSuccess={() => {
        trackScheduleEvent('Shipper Scheduled SuperPay', orders, source);
      }}
      onOpenScheduleCalendar={() => {
        trackScheduleEvent('Shipper Clicked Schedule SuperPay', orders, source);
      }}
    />
  );
}
