import { Color } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const ForwardArrowCircledIcon = createSvgIcon(
  'ForwardArrowCircledIcon',
  <>
    <circle cx="16" cy="16" r="15.5" fill="white" stroke={Color.Silver500} />,
    <path
      d="M14.41 10L13 11.41L17.58 16L13 20.59L14.41 22L20.41 16L14.41 10Z"
      fill={Color.Dark100}
    />
    ,
  </>,
  { viewBox: '0 0 32 32' },
);
