import { OnboardingPopup, PopupOnboardingAction } from './onboardingMap';

export function getNudgingDialogProps({
  popupData,
  handlePopupAction,
  isAsyncActionLoading,
}: {
  popupData: NonNullable<OnboardingPopup['data']>;
  handlePopupAction: (action: PopupOnboardingAction) => void;
  isAsyncActionLoading: boolean;
}) {
  return {
    overline: popupData.overline,
    title: popupData.title,
    subtitle: popupData.subtitle,
    image: (
      <img
        src={popupData.image.src}
        alt={popupData.image.alt}
        width={popupData.image.width}
      />
    ),
    primaryActionText: popupData.primaryActionText,
    primaryActionLoading: isAsyncActionLoading,
    closeOnPrimaryAction:
      popupData.primaryAction.type !== 'POST_TO_SUPER_LOADBOARD',
    onPrimaryAction: () => handlePopupAction(popupData.primaryAction),
    secondaryActionText: popupData.secondaryActionText,
    onSecondaryAction: popupData.secondaryAction
      ? () =>
          handlePopupAction(popupData.secondaryAction as PopupOnboardingAction)
      : undefined,
  };
}
