import { useEffect } from 'react';
import './zendesk.css';

declare global {
  interface Window {
    zE: (scope: string, action: string, options?: string[]) => void;
  }
}

const API_KEY = '5e126156-0153-445a-bc39-9b442fa5c8ab'; //superdispatchhelp.zendesk.com
const SCRIPT_ID = 'ze-snippet';
const ZENDESK_URL = 'https://static.zdassets.com/ekr/snippet.js';

function executeZendeskCommand(scope: string, action: string) {
  if (!('zE' in window)) return;

  window.zE('messenger:set', 'conversationTags', ['STMS']);
  window.zE(scope, action);
}

export const zendeskCommands = {
  hideZendeskButton: () => {
    executeZendeskCommand('messenger', 'hide');
  },
  showZendeskButton: () => {
    executeZendeskCommand('messenger', 'show');
  },
  showZendeskDialog: () => {
    executeZendeskCommand('messenger', 'open');
  },
};

export function useZendesk(isEnabled = true) {
  useEffect(() => {
    const existingScript = document.getElementById(SCRIPT_ID);

    if (!existingScript && isEnabled) {
      const script = document.createElement('script');
      script.src = `${ZENDESK_URL}?key=${API_KEY}`;
      script.id = SCRIPT_ID;
      script.type = 'text/javascript';
      script.defer = true;
      document.body.appendChild(script);
    }

    if (existingScript && !isEnabled) {
      zendeskCommands.hideZendeskButton();
    }
  }, [isEnabled]);

  return zendeskCommands;
}
