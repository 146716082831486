import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { parseDate, stringifyDate } from '@superdispatch/dates';
import { Inline, Stack } from '@superdispatch/ui';
import { AnchorButton, Button } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { ExitTransitionPlaceholder } from 'shared/ui/ExitTransitionPlaceholder';
import styled from 'styled-components';
import authorityRevocation from '../assets/authority-revocation.png';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 480px;
  }

  & .MuiDialogActions-root {
    justify-content: center;
  }
`;

const MainIllustration = styled.img`
  width: 150px;
`;

interface AuthorityRevocationDialogContentProps {
  onClose?: () => void;
}

function AuthorityRevocationDialogContent({
  onClose,
}: AuthorityRevocationDialogContentProps) {
  return (
    <>
      <DialogContent>
        <Stack align="center" space="large">
          <MainIllustration
            src={authorityRevocation}
            alt="authority revocation"
          />
          <Stack>
            <Typography align="center" variant="h2">
              Verified Shipper status revoked due to inactive Operating
              Authority
            </Typography>
            <Typography align="center">
              You can&apos;t post orders on the Super Loadboard until authority
              is restored. Unbooked orders have been unposted. You can repost
              orders from the &apos;New&apos; tab once your verified status has
              been restored.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Inline space="small">
          <AnchorButton
            href="https://support.superdispatch.com/en/articles/9806978-reinstating-broker-authority"
            target="_blank"
            variant="neutral"
          >
            Learn More
          </AnchorButton>
          <Button onClick={onClose}>I understand</Button>
        </Inline>
      </DialogActions>
    </>
  );
}

interface AuthorityRevocationDialogProps {
  open?: boolean;
  onClose?: () => void;
  isGlobalDialog?: boolean;
  action?: string;
  actionType?: string;
}

export function AuthorityRevocationDialog({
  open,
  onClose,
  isGlobalDialog,
  action,
  actionType,
}: AuthorityRevocationDialogProps) {
  useEffect(() => {
    if (open) {
      trackVerificationApplicationEvent({
        name: 'Shipper Viewed Authority Revocation Dialog',
        utm_content: isGlobalDialog ? 'Automatic' : 'From Order Actions',
        action,
        action_type: actionType,
      });
    }
  }, [open, isGlobalDialog]);

  return (
    <CustomDialog open={!!open} onClose={onClose} fullWidth={true}>
      <ExitTransitionPlaceholder in={!!open}>
        {open && <AuthorityRevocationDialogContent onClose={onClose} />}
      </ExitTransitionPlaceholder>
    </CustomDialog>
  );
}

const AUTHORITY_REVOCATION_GLOBAL_STORAGE_KEY = 'authority-revocation-showed';

export function AuthorityRevocationGlobalDialog() {
  const authorityRevocationShowed = useStorageValue(
    AUTHORITY_REVOCATION_GLOBAL_STORAGE_KEY,
  );

  const { data: verification } = useVerificationApplication();

  const isOpen = useMemo(() => {
    const revocationDateFromStorage = parseDate(authorityRevocationShowed);
    const revocationDate = parseDate(verification?.revocation_date);
    //revocationDateFromStorage is invalid if dialog was never shown
    const revocationTimeIsValid =
      !revocationDateFromStorage.isValid ||
      revocationDate > revocationDateFromStorage;

    return verification?.status === 'REVOKED' && revocationTimeIsValid;
  }, [verification, authorityRevocationShowed]);

  const handleClose = () => {
    writeStorageItem(
      AUTHORITY_REVOCATION_GLOBAL_STORAGE_KEY,
      verification?.revocation_date ||
        stringifyDate(DateTime.local(), { format: 'DateISO' }) ||
        '',
    );
  };

  return (
    <AuthorityRevocationDialog
      open={isOpen}
      onClose={handleClose}
      isGlobalDialog={true}
    />
  );
}
