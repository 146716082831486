import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { unset } from 'lodash-es';
import { normalizeContact } from 'orders/core/form/helpers/orderFormHelpers';
import {
  useActiveOrder,
  useOrderAPI,
  useRelatedLoads,
} from 'orders/data/OrderAPI';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { Load } from 'shared/types/load';
import { Order } from 'shared/types/order';
import { LoadVenue } from 'shared/types/step';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { ProgressStep, ProgressStepper } from 'shared/ui/ProgressStepper';
import { yupArray, yupObject, yupString } from 'shared/utils/YupUtils';
import styled from 'styled-components';
import { usePaymentDefault } from '../../../../core/PaymentUtils';
import { generateLoad } from './BuildLoadsDrawerUtils';
import { CarrierPaymentStep } from './CarrierPaymentStep';
import { ChooseRouteStep } from './ChooseRouteStep';

interface BuildLoadsDrawerProps {
  onClose: () => void;
  orderGUID?: string;
  onSubmitSuccess: (order: Order) => void;
}

export interface BuildLoadsFormValues {
  loads: Load[];
}

const StyledActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledScrollableContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

const StyledDrawerContent = styled(DrawerContent)`
  height: calc(100vh - 330px);
`;

const validationSchema = yupObject({
  loads: yupArray(
    yupObject({
      number: yupString().required('Required').defined(),
    }),
  ),
});

const normalizeVenue = (venue?: LoadVenue): LoadVenue => {
  return {
    name: venue?.name || null,
    business_type: venue?.business_type,
    address: venue?.address || null,
    city: venue?.city || null,
    state: venue?.state || null,
    zip: venue?.zip || null,
    save_as_new_contact: venue?.save_as_new_contact,
    ...normalizeContact(venue?.contact),
  };
};

const prepareLoads = (loads: Load[]) => {
  return loads.map((load) => {
    const modifiedLoad = {
      ...load,
      pickup: {
        ...load.pickup,
        venue: {
          ...normalizeVenue(load.pickup.venue),
        },
      },
      delivery: {
        ...load.delivery,
        venue: {
          ...normalizeVenue(load.delivery.venue),
        },
      },
    };

    unset(modifiedLoad, 'delivery.venue.contact');
    unset(modifiedLoad, 'pickup.venue.contact');

    return modifiedLoad;
  });
};

export function BuildLoadsDrawer({
  orderGUID,
  onClose,
}: BuildLoadsDrawerProps) {
  const uid = useUID();
  const iconID = `${uid}-icon`;
  const labelID = `${uid}-label`;

  const { data: order } = useActiveOrder(orderGUID);
  const { paymentDefaultMethod, paymentDefaultTerm } = usePaymentDefault();
  const { addSnackbar } = useSnackbarStack();
  const { createLoads } = useOrderAPI();
  const drawerContentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [isStep1Complete, setIsStep1Complete] = useState(false);

  const {
    isLoading: isRelatedLoadsLoading,
    data: { objects: relatedLoads = [] } = {},
  } = useRelatedLoads(order?.id, !!order?.id);
  const loadNumbers = useMemo(() => {
    return relatedLoads.map(({ number }) => number);
  }, [relatedLoads]);

  useEffect(() => {
    if (drawerContentRef.current) {
      drawerContentRef.current.scrollIntoView();
    }
  }, [currentStep]);

  const initialValues = useMemo(() => {
    if (isRelatedLoadsLoading) {
      return { loads: [] };
    }

    return {
      loads: [
        generateLoad({
          order,
          paymentDefaultTerm,
          paymentDefaultMethod,
          pickupVenue: order?.has_legs ? null : order?.pickup?.venue,
          loadNumbers,
        }),
      ],
    };
  }, [
    order,
    paymentDefaultTerm,
    paymentDefaultMethod,
    loadNumbers,
    isRelatedLoadsLoading,
  ]);

  const formik = useFormikEnhanced<BuildLoadsFormValues, Response>({
    key: !!order,
    validationSchema,
    initialValues,
    onSubmit: (values) => createLoads(prepareLoads(values.loads), [order?.id]),
    onSubmitSuccess: (_, { loads }) => {
      trackEventLegacy('Clicked Build Loads', {
        numberOfLoads: loads.length,
      });
      formik.resetForm();
      onClose();
      setIsStep1Complete(false);
      setShowConfirmation(false);
      setCurrentStep(1);
      navigate('/orders/new');
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    validateOnMount: true,
  });

  const complete1Step = () => {
    setIsStep1Complete(true);
    setCurrentStep(2);
  };

  const onCloseDrawer = () => {
    formik.resetForm();
    onClose();
    setIsStep1Complete(false);
    setShowConfirmation(false);
    setCurrentStep(1);
  };

  const handleClose = () => {
    if (formik.dirty) {
      setShowConfirmation(true);
    } else {
      onCloseDrawer();
    }
  };

  return (
    <FormikDrawer
      open={!!order}
      onClose={handleClose}
      confirmClose={true}
      PaperProps={{
        style: {
          minWidth: '50%',
          overflow: 'initial',
        },
      }}
    >
      <FormikProvider value={formik}>
        <DrawerTitle
          style={{
            borderBottomColor: Color.Silver300,
          }}
          title="Build Load"
          titleTypographyProps={{ id: labelID }}
          endAction={
            <IconButton
              edge="end"
              onClick={handleClose}
              disabled={formik.isSubmitting}
              aria-labelledby={`${iconID} ${labelID}`}
            >
              <Close id={iconID} aria-label="close" />
            </IconButton>
          }
        />

        <Form aria-labelledby={labelID}>
          <ProgressStepper>
            <ProgressStep
              isActive={currentStep === 1}
              isCompleted={isStep1Complete}
              subtitle="Choose Route"
              title="STEP 1"
            />
            <ProgressStep
              isActive={currentStep === 2}
              isCompleted={false}
              subtitle="Carrier Payment"
              title="STEP 2"
            />
          </ProgressStepper>
          <StyledScrollableContainer>
            <StyledDrawerContent ref={drawerContentRef}>
              {currentStep === 1 ? (
                isRelatedLoadsLoading ? (
                  <LoadingContainer />
                ) : (
                  <ChooseRouteStep
                    order={order}
                    paymentDefaultMethod={paymentDefaultMethod}
                    paymentDefaultTerm={paymentDefaultTerm}
                  />
                )
              ) : (
                <CarrierPaymentStep order={order} />
              )}
            </StyledDrawerContent>
          </StyledScrollableContainer>

          <DrawerActions>
            {currentStep === 1 ? (
              <StyledActions>
                <Button disabled={!formik.isValid} onClick={complete1Step}>
                  Next
                </Button>
              </StyledActions>
            ) : (
              <Columns>
                <Column width="fluid">
                  <Button
                    variant="neutral"
                    onClick={() => {
                      setCurrentStep(1);
                      setIsStep1Complete(false);
                    }}
                  >
                    Back
                  </Button>
                </Column>
                <Column width="content">
                  <Button
                    type="submit"
                    disabled={
                      !formik.dirty || !formik.isValid || formik.isSubmitting
                    }
                  >
                    Save
                  </Button>
                </Column>
              </Columns>
            )}
          </DrawerActions>
        </Form>
      </FormikProvider>

      <ConfirmDialog
        open={showConfirmation}
        onClose={() => {
          setShowConfirmation(false);
        }}
        title="Cancel Without Saving?"
        cancelButtonProps={{ children: 'No' }}
        confirmButtonProps={{
          onClick: () => {
            onCloseDrawer();
          },
          children: 'Yes, Cancel',
          color: 'primary',
        }}
      >
        Changes have not been saved. Leaving will result in unsaved changes
        being lost.
      </ConfirmDialog>
    </FormikDrawer>
  );
}
