import { Dialog, Typography } from '@material-ui/core';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { ReactElement } from 'react';
import styled from 'styled-components';
import createOrderBackground from './assets/create-order-bg.svg';

const OnboardingBox = styled(Box)({
  backgroundImage: `url(${createOrderBackground})`,
  backgroundRepeat: 'no-repeat',
});

export interface OnboardingNudgingDialogProps {
  image: ReactElement;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
  primaryActionText?: string;
  primaryActionLoading?: boolean;
  onPrimaryAction?: () => void;
  open: boolean;
  onClose?: () => void;
  closeOnPrimaryAction?: boolean;
  overline?: string;
  title: string;
  subtitle?: string;
}

export function OnboardingNudgingDialog({
  image,
  secondaryActionText,
  onSecondaryAction,
  primaryActionText,
  primaryActionLoading = false,
  onPrimaryAction,
  open,
  onClose,
  closeOnPrimaryAction = true,
  overline,
  title,
  subtitle,
}: OnboardingNudgingDialogProps) {
  const platform = useResponsiveValue('mobile', 'other');

  return (
    <Dialog open={open} onClose={onClose} fullScreen={platform === 'mobile'}>
      <OnboardingBox
        padding="large"
        paddingLeft="xlarge"
        paddingRight="xlarge"
        maxWidth="500px"
      >
        <Stack space="large" align="center">
          {image}
          <Stack space="small" align="center">
            {overline && (
              <Typography
                variant="h4"
                align="center"
                color="textSecondary"
                noWrap={false}
              >
                {overline}
              </Typography>
            )}

            <Typography variant="h2" align="center">
              {title}
            </Typography>

            {subtitle && (
              <Typography align="center" color="textSecondary">
                {subtitle}
              </Typography>
            )}

            <Inline space="small">
              {secondaryActionText && (
                <Button
                  size="large"
                  variant="neutral"
                  onClick={() => {
                    onClose?.();
                    onSecondaryAction?.();
                  }}
                >
                  {secondaryActionText}
                </Button>
              )}
              <Button
                size="large"
                onClick={() => {
                  onPrimaryAction?.();

                  if (closeOnPrimaryAction) {
                    onClose?.();
                  }
                }}
                pending={primaryActionLoading}
              >
                {primaryActionText}
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </OnboardingBox>
    </Dialog>
  );
}
