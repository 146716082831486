import { CircularProgress } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { RatingBanner } from 'core/ratings/RatingBanner';
import { isOwnDemoOrder } from 'orders/core/DemoOrderUtils';
import { OrderRequestsDrawer } from 'orders/core/order-requests/OrderRequestsDrawer';
import { useEffect } from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { useSourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';
import { getCurrentTab } from 'shared/layout/AppNavbar';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import Order from 'shared/types/order';
import { SourceManager } from '../../shared/helpers/SourceManager';
import {
  OrderActionCompletePayload,
  OrderActionsProvider,
  useOrderActionsContext,
} from '../core/actions/OrderActionsContext';
import {
  useOrder,
  useOrderActivities,
  useOrderAPI,
  useOrderCache,
  useOrdersCache,
  useRelatedLoads,
} from '../data/OrderAPI';
import { OrderViewActivities } from './activities/OrderViewActivities';
import { OrderViewAttachments } from './OrderViewAttachments';
import OrderViewCarrierInfo from './OrderViewCarrierInfo';
import { OrderViewCustomerInfo } from './OrderViewCustomerInfo';
import { OrderViewDates } from './OrderViewDates';
import { OrderViewGrouppedDates } from './OrderViewGrouppedDates';
import { OrderViewHeader } from './OrderViewHeader';
import { OrderViewInfo } from './OrderViewInfo';
import { OrderViewInstructions } from './OrderViewInstructions';
import { OrderViewInternalNotes } from './OrderViewInternalNotes';
import { OrderViewNotesFromShipper } from './OrderViewNotesFromShipper';
import { OrderViewPayment } from './OrderViewPayment';
import { OrderViewRelatedLoads } from './OrderViewRelatedLoads';
import { OrderViewStep } from './OrderViewStep';
import { OrderViewVehicles } from './OrderViewVehicles';
import { OrderViewRevisions } from './revisions/OrderViewRevisions';
import { Template } from './template/Template';

function OrderActionObserver({
  order,
  isValidating,
}: {
  order: Order;
  isValidating: boolean;
}) {
  const { onAction } = useOrderActionsContext();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  useEffect(() => {
    if (state?.action && !isValidating) {
      onAction(state.action.type, order, undefined, state.action.args);
      // clear state
      navigate(pathname, { replace: true });
    }
  }, [state, order, onAction, isValidating, navigate, pathname]);

  return null;
}

interface OrderViewPageProps {
  isActive: boolean;
  path: string;
}

export function OrderViewPage({ isActive, path }: OrderViewPageProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { guid: orderGUID = '' } = useParams<{ guid: string }>();
  const { tryBack } = useTryBack();
  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;
  const isBroker = shipperType === 'BROKER';
  const rootURL = `${path}/${orderGUID}`;
  const drawerMatch = useMatch(`${rootURL}/load_requests`);

  const { removeRelations } = useOrderAPI();
  const { invalidateOrders } = useOrdersCache();

  const {
    data: order,
    error: errorOrder,
    isFetching,
    refetch: revalidateOrder,
  } = useOrder(orderGUID, isActive);

  const { replaceOrder } = useOrderCache();

  const hasRelatedLoads = !!(order?.has_loads || order?.is_load);

  const {
    data: relatedLoadsData,
    isLoading: isRelatedLoadsLoading,
    refetch: revalidateRelatedLoads,
  } = useRelatedLoads(order?.id, !!(order?.id && hasRelatedLoads));
  const { objects: relatedLoads = [] } = relatedLoadsData || {};
  const { data: orderActivities, refetch: refetchOrderActivities } =
    useOrderActivities(order?.id);

  function handleActionComplete({ type, payload }: OrderActionCompletePayload) {
    if (type === 'restore_order') {
      navigate('/orders/new');
    } else if (type === 'delete_order') {
      navigate(`/orders/inactive/view/${payload.order.guid}`);
    } else if (type === 'duplicate' && payload.updatedOrder) {
      navigate(`/orders/view/${payload.updatedOrder.guid}`, {
        state: { action: { type: 'instant_dispatch_opener' } },
      });
    } else {
      if (payload.updatedOrder) {
        replaceOrder(payload.updatedOrder, isActive);
      } else {
        void revalidateOrder();
      }
      void refetchOrderActivities();
    }

    void invalidateOrders();
  }

  useDocumentTitle(order?.number);

  useEffect(() => {
    if (errorOrder) {
      navigate('/');
    }
  }, [errorOrder, navigate]);

  useEffect(() => {
    if (orderGUID) {
      trackEvent('Shipper Viewed Order Details', { tab: getCurrentTab() });
    }
  }, [orderGUID, shipperType]);

  useSourceManagerLegacy(
    'Order View',
    order?.status ? `${order.status}_order_status` : undefined,
  );

  if (!order) {
    return (
      <Flex flex={1} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Flex>
    );
  }

  const hasInstructions =
    !!(
      user?.order_form_settings.is_instructions_visible || order.instructions
    ) ||
    !!(
      user?.order_form_settings.is_loadboard_instructions_visible ||
      order.loadboard_instructions
    );
  const hasCustomer =
    user?.order_form_settings.is_customer_block_visible ||
    !!order.customer?.address ||
    !!order.customer?.city ||
    !!order.customer?.state ||
    !!order.customer?.zip ||
    !!order.customer?.name ||
    !!order.customer?.notes ||
    !!order.customer?.contact_title ||
    !!order.customer?.contact_mobile_phone ||
    !!order.customer?.contact_email ||
    !!order.customer?.contact_name ||
    !!order.customer?.contact_phone;

  const handleRemoveRelations = (id: number) => {
    void removeRelations(id).then(() => {
      void revalidateRelatedLoads();
      void revalidateOrder();
    });
  };

  return (
    <SourceManager primarySource="Order View">
      <OrderActionsProvider
        source="Order View"
        onActionComplete={handleActionComplete}
      >
        <OrderActionObserver order={order} isValidating={isFetching} />

        <Template
          isDemo={isOwnDemoOrder(order, user)}
          isLoad={!!order.is_load}
          header={<OrderViewHeader order={order} />}
          info={<OrderViewInfo order={order} />}
          vehicles={
            <OrderViewVehicles
              order={order}
              loads={relatedLoads}
              handleRemoveRelations={handleRemoveRelations}
            />
          }
          pickup={<OrderViewStep variant="pickup" order={order} />}
          delivery={<OrderViewStep variant="delivery" order={order} />}
          payment={<OrderViewPayment order={order} />}
          dates={
            isBroker ? (
              <OrderViewGrouppedDates order={order} />
            ) : (
              <OrderViewDates order={order} />
            )
          }
          carrier={<OrderViewCarrierInfo order={order} />}
          customer={<OrderViewCustomerInfo order={order} />}
          instructions={<OrderViewInstructions order={order} />}
          hasInstructions={hasInstructions}
          hasCustomer={hasCustomer}
          hasRelatedLoads={hasRelatedLoads}
          numberOfRelatedLoads={relatedLoads.length}
          internalNotes={<OrderViewInternalNotes order={order} />}
          attachments={<OrderViewAttachments order={order} />}
          activities={
            <OrderViewActivities
              order={order}
              activities={orderActivities?.objects}
            />
          }
          revisions={<OrderViewRevisions orderGUID={order.guid} />}
          notesFromShipper={<OrderViewNotesFromShipper order={order} />}
          ratingBanner={<RatingBanner order={order} />}
          relatedLoads={
            <OrderViewRelatedLoads
              loads={relatedLoads}
              isLoading={isRelatedLoadsLoading}
              refetchLoads={revalidateRelatedLoads}
            />
          }
          tab={location.hash.substring(1) || undefined}
        />

        <OrderRequestsDrawer
          order={drawerMatch ? order : undefined}
          onClose={() => tryBack({ pathname: rootURL })}
          onSubmitSuccess={(updatedOrder) => {
            replaceOrder(updatedOrder, isActive);
          }}
        />
      </OrderActionsProvider>
    </SourceManager>
  );
}
