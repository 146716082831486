import { Typography } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { isAPIError } from 'shared/errors/APIError';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import styled from 'styled-components';

interface ErrorStateProps {
  error: Error;
  onRetry?: () => void;
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
`;

export function ErrorState({ error, onRetry }: ErrorStateProps) {
  const { showCustomerDialog } = useCustomerSupport();
  const { title, message } = getErrorDetails(error);

  return (
    <StyledRoot>
      <Stack space="xxsmall">
        <Typography variant="h6" color="error">
          {title}
        </Typography>
        <Typography color="textSecondary">{message}</Typography>
      </Stack>

      <Inline horizontalAlign="right">
        {onRetry ? (
          <Button onClick={onRetry} size="small">
            Retry
          </Button>
        ) : (
          <Button size="small" onClick={() => window.location.reload()}>
            Reload Page
          </Button>
        )}

        <Button
          size="small"
          variant="neutral"
          onClick={() => showCustomerDialog()}
        >
          Contact Support
        </Button>
      </Inline>
    </StyledRoot>
  );
}

function getErrorDetails(error: Error) {
  if (isAPIError(error) && error.responseError?.status === 500) {
    return {
      title: 'Server Error',
      message:
        'Our servers are experiencing issues. Please reload the page or try again later. If the problem persists, please contact support.',
    };
  }

  return {
    title: 'An error occurred',
    message:
      'Please reload the page or try again later. If the problem persists, please contact support.',
  };
}
