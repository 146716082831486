import { IconButton, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  FormikCheckboxField,
  FormikCurrencyField,
  FormikNumberField,
  FormikTextField,
} from '@superdispatch/forms';
import { useValueObserver } from '@superdispatch/hooks';
import { formatVehicleType, listVehicleTypes } from '@superdispatch/sdk';
import { Color } from '@superdispatch/ui';
import { isSuperPayStatusAvailable } from 'core/PaymentUtils';
import { Field, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { isValidVin } from 'shared/utils/ValidatorUtils';
import styled from 'styled-components';
import { SuperPayGoEditTooltip } from 'superpay/edit/SuperPayEdit';
import { OrderFormVehicleDTO } from '../data/OrderFormVehicleDTO';
import { useDecodeVinMutation } from '../data/OrderVehicleAPI';
import { calculateVehiclesSum } from '../helpers/orderFormHelpers';
import { OrderFormValues } from '../OrderForm';
import { VehicleMakeAutocomplete } from './VehicleMakeAutocomplete';
import { VehicleModelAutocomplete } from './VehicleModelAutocomplete';
import { VehicleSamplePhotoField } from './VehicleSamplePhotoField';
import { VehicleYearAutocomplete } from './VehicleYearAutocomplete';

const StyledVinWrapper = styled.div`
  height: 50px;
  padding-top: 10px;
`;

const StyledWarning = styled(Typography)`
  color: ${Color.Yellow500};
  white-space: nowrap;
`;

export interface VehicleItemProps {
  name: string;
  index: number;
  vehicle: OrderFormVehicleDTO;
  hasColorValue?: boolean;
  hasLotValue?: boolean;
  autoFocus: boolean;
  onRemove: () => void;
  onVinDecode: (vehicle: OrderFormVehicleDTO) => void;
  onVinChange: (value: string) => void;
}

export function VehicleItem({
  name,
  index,
  vehicle,
  hasColorValue,
  hasLotValue,
  autoFocus,
  onRemove,
  onVinDecode,
  onVinChange,
}: VehicleItemProps) {
  const { user } = useUserState();
  const [isVinValid, setIsVinValid] = useState(true);
  const { setFieldValue, values } = useFormikContext<OrderFormValues>();
  const { price, tariff } = vehicle;

  useValueObserver(price, () => {
    setFieldValue('price', calculateVehiclesSum(values.vehicles, 'price'));
  });

  useValueObserver(tariff, () => {
    setFieldValue(
      'customer_payment.tariff',
      calculateVehiclesSum(values.vehicles, 'tariff'),
    );
  });

  const { mutate: decodeVin, isLoading: isDecoding } = useDecodeVinMutation(
    (newVehicle) => {
      if (!newVehicle) return;

      const nextVehicle = OrderFormVehicleDTO.mergeDecodedVehicle(
        vehicle,
        newVehicle,
      );

      onVinDecode(nextVehicle);
    },
  );

  const validateVin = useCallback(
    (vin: string) => {
      setIsVinValid(vin.length === 0 || isValidVin(vin));
    },
    [setIsVinValid],
  );

  const { isAdvancedTier } = useProductTiers();

  return (
    <tr>
      <td>{index + 1}</td>

      <td>
        <VehicleSamplePhotoField name={`${name}.photos`} />
      </td>

      <td style={{ minWidth: 190 }}>
        <StyledVinWrapper>
          <FormikTextField
            name={`${name}.vin`}
            inputProps={{
              maxLength: '50',
              'aria-label': 'vehicle vin',
            }}
            autoFocus={autoFocus}
            onBlur={(event) => {
              validateVin(event.target.value);
            }}
            helperText={
              !isVinValid && (
                <StyledWarning aria-label="vehicle vin hint">
                  To decode, enter a valid VIN.
                </StyledWarning>
              )
            }
            onChange={({ target: { value } }) => {
              onVinChange(value);
              if (isValidVin(value)) {
                decodeVin(value);
              }
            }}
          />
        </StyledVinWrapper>
      </td>

      <td aria-label="vehicle year">
        <VehicleYearAutocomplete name={`${name}.year`} disabled={isDecoding} />
      </td>

      <td aria-label="vehicle make">
        <VehicleMakeAutocomplete
          name={`${name}.make`}
          vehicle={vehicle}
          disabled={isDecoding}
          isVinValid={isVinValid}
        />
      </td>

      <td aria-label="vehicle model">
        <VehicleModelAutocomplete
          name={name}
          vehicle={vehicle}
          disabled={isDecoding}
          isVinValid={isVinValid}
        />
      </td>

      <td style={{ maxWidth: 150 }}>
        <FormikTextField
          select={true}
          name={`${name}.type`}
          disabled={isDecoding}
          fullWidth={true}
          inputProps={{ 'aria-label': 'vehicle type', 'max-length': 100 }}
        >
          {listVehicleTypes().map((value) => (
            <MenuItem key={value} value={value}>
              {formatVehicleType(value)}
            </MenuItem>
          ))}
        </FormikTextField>
      </td>

      <td style={{ minWidth: 110 }}>
        <FormikNumberField
          name={`${name}.curb_weight`}
          disabled={isDecoding}
          inputProps={{
            'max-length': 20,
            'aria-label': 'vehicle curb weight',
            suffix: ` ${vehicle.curb_weight_unit || 'lbs'}`,
          }}
        />

        <Field
          type="hidden"
          name={`${name}.curb_weight_unit`}
          value={vehicle.curb_weight_unit || 'lbs'}
        />
        <Field
          type="hidden"
          name={`${name}.is_modified`}
          value={!!vehicle.is_modified}
        />
      </td>

      {((user?.order_form_settings.vehicle &&
        user.order_form_settings.vehicle.is_color_visible) ||
        hasColorValue ||
        vehicle.color) && (
        <td style={{ minWidth: 110 }}>
          <FormikTextField
            name={`${name}.color`}
            disabled={isDecoding}
            inputProps={{ 'aria-label': 'vehicle color', maxLength: '100' }}
          />
        </td>
      )}

      {((user?.order_form_settings.vehicle &&
        user.order_form_settings.vehicle.is_lot_number_visible) ||
        hasLotValue ||
        vehicle.lot_number) && (
        <td style={{ minWidth: 110 }}>
          <FormikTextField
            disabled={isDecoding}
            name={`${name}.lot_number`}
            inputProps={{
              'aria-label': 'vehicle lot number',
              maxLength: '100',
            }}
          />
        </td>
      )}

      {user?.shipper.shipper_type === 'BROKER' && isAdvancedTier && (
        <td style={{ minWidth: 110 }}>
          <FormikCurrencyField
            name={`${name}.tariff`}
            disabled={isDecoding}
            inputProps={{
              'aria-label': 'vehicle tariff',
              decimalScale: 2,
              fixedDecimalScale: true,
              maxLength: 16, // For example 1,234,567,890.00
            }}
          />
        </td>
      )}

      {user?.shipper.shipper_type === 'BROKER' && (
        <td style={{ minWidth: 110 }}>
          <SuperPayGoEditTooltip order={values} source="vehicle_price">
            <FormikCurrencyField
              name={`${name}.price`}
              disabled={isDecoding || isSuperPayStatusAvailable(values)}
              inputProps={{
                'aria-label': 'vehicle price',
                decimalScale: 2,
                fixedDecimalScale: true,
                maxLength: 16,
              }}
            />
          </SuperPayGoEditTooltip>
        </td>
      )}

      <td>
        <FormikCheckboxField
          name={`${name}.is_inoperable`}
          disabled={isDecoding}
          label=""
        />
      </td>

      <td>
        {!values.is_brokers_order && (
          <SuperPayGoEditTooltip order={values} source="remove_vehicle">
            <IconButton
              size="small"
              onClick={onRemove}
              disabled={isSuperPayStatusAvailable(values)}
              aria-label="remove vehicle"
            >
              <DeleteIcon color="action" />
            </IconButton>
          </SuperPayGoEditTooltip>
        )}
      </td>
    </tr>
  );
}
