import { Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';
import superLogo from '../assets/super-dispatch-verification-logo.svg';

const VerificationSidebarContainer = styled.div<{ fullMobileHeight: boolean }>`
  flex: 0 0 auto;
  width: 480px;
  background-color: ${Color.Dark500};
  background-image: url(${superLogo});
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  color: ${Color.White};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: ${({ fullMobileHeight }) =>
      fullMobileHeight ? '100%' : 'fit-content'};
  }
`;

const getGradientColorsByStatus = (status: string) => {
  if (status === 'warning') {
    return `radial-gradient(55.69% 55.63% at 46.72% 44.46%, ${Color.Yellow300} 0%, ${Color.Yellow300} 30.5%, #384799 100%)`;
  }
  if (status === 'error') {
    return `radial-gradient(55.69% 55.63% at 46.72% 44.46%, ${Color.Red500} 30.5%, #384799 100%)`;
  }

  return 'radial-gradient(55.69% 55.63% at 46.72% 44.46%, #8C2842 30.5%, #384799 100%)';
};

const Gradient = styled.div<{ background: string }>`
  position: absolute;
  left: 0;
  bottom: -350px;
  width: 664px;
  height: 593px;
  background: ${({ background }) => background};
  filter: blur(150px);
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 80px 40px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 40px 24px;
  }
`;

const CenteredContent = styled.div`
  display: flex;
  height: 100%;
  overflow-x: auto;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 0 40px;
  }
`;

export interface VerificationSidebarProps {
  bottomText?: string;
  actions?: ReactNode;
  children: ReactNode;
  status?: 'default' | 'warning' | 'error';
  fullMobileHeight?: boolean;
}

export function VerificationSidebar({
  children,
  bottomText,
  status = 'default',
  actions,
  fullMobileHeight = false,
}: VerificationSidebarProps) {
  return (
    <VerificationSidebarContainer fullMobileHeight={fullMobileHeight}>
      <Gradient background={getGradientColorsByStatus(status)} />
      <Content>
        <Typography>&nbsp;</Typography>
        <CenteredContent>{children}</CenteredContent>
        <Stack space="medium">
          {actions}
          <Typography>{bottomText}</Typography>
        </Stack>
      </Content>
    </VerificationSidebarContainer>
  );
}
