import { useAuthAPI } from 'auth/data/LoginAPI';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import normalizeRoles from 'shared/data/normalizeRoles';
import { resetAnalytics } from 'shared/helpers/AnalyticsHelpers';
import { shutdownIntercom } from 'shared/helpers/customer-support/intercom/Intercom';
import { getFCMCurrentToken } from 'shared/helpers/FirebaseHelpers';
import {
  clearRecentSearchFilters,
  clearRecentSelections,
} from 'shared/helpers/RecentSelectionsHelpers';
import {
  AuthContext,
  AuthEmitter,
  Context,
  deleteAppToken,
  deleteShipperIdForAdmin,
  LogoutOptions,
  useAppToken,
  useShipperIdForAdmin,
} from './AppUserState';
import { useUser } from './AuthAPI';
import { useNotificationAPI } from './notifications/NotificationAPI';

export function AuthProvider({ children }: { children: ReactNode }) {
  const token = useAppToken();
  const shipperId = useShipperIdForAdmin();
  const queryClient = useQueryClient();

  const { unsubscribeFromPushNotification } = useNotificationAPI();
  const { data, error, refetch, isLoading } = useUser(token, shipperId);
  const authAPI = useAuthAPI();

  const logout = useCallback(
    ({ skipLogoutEndpointCall = false }: LogoutOptions = {}) => {
      const fcmToken = getFCMCurrentToken();

      if (fcmToken && token) {
        void unsubscribeFromPushNotification(fcmToken);
      }

      if (!skipLogoutEndpointCall) {
        void authAPI.logout();
      }

      resetAnalytics();
      shutdownIntercom();
      deleteAppToken();
      deleteShipperIdForAdmin();
      clearRecentSelections();
      clearRecentSearchFilters();
      queryClient.clear();
    },
    [token, unsubscribeFromPushNotification, queryClient],
  );

  useEffect(() => {
    function handleLogout() {
      logout({ skipLogoutEndpointCall: true });
    }

    AuthEmitter.on('logout', handleLogout);

    return () => AuthEmitter.off('logout', handleLogout);
  }, [logout]);

  const context = useMemo<AuthContext>(() => {
    function getAuthState() {
      if (isLoading) {
        return 'pending';
      }

      if (!token || error || !data) {
        return 'unauthorized';
      }

      return 'authorized';
    }

    return {
      logout,
      user: data,
      isAdmin: !!data?.user_roles.find(({ role }) => role.name === 'Admin'),
      isSuperUser: !!data?.is_superuser,
      refetch,
      permissions: data?.user_roles
        ? normalizeRoles(data.user_roles)
        : undefined,
      authState: getAuthState(),
    };
  }, [logout, data, refetch, token, error, isLoading]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
