import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Stack, useUID } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { Timer } from 'shared/ui/Timer';
import { useCentralDispatchMfaCodeFormik } from './useCentralDispatchMfaCodeFormik';
import { useResendMfaCode } from './useResendMfaCode';

export function CentralDispatchMfaDialog({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const formId = useUID();
  const formik = useCentralDispatchMfaCodeFormik({ onSuccess });

  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={onClose} maxWidth="xs">
        <DialogTitle>Enter CD Verification Code</DialogTitle>
        <DialogContent>
          <Stack space="small">
            <TextBox as="p">
              Enter the code sent to your Central Dispatch email to complete the
              integration.
            </TextBox>

            <Form id={formId}>
              <FormikTextField
                name="verification_code"
                fullWidth={true}
                label="CD Verification Code"
              />
            </Form>

            <Stack space="none">
              <TextBox as="p">
                Didn’t receive a code? Check your spam folder.
              </TextBox>
              <ResendCode />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!formik.isValid || !formik.dirty}
            pending={formik.isSubmitting}
            type="submit"
            // @ts-expect-error - should support form prop
            form={formId}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
}

function ResendCode() {
  const [canResend, setCanResend] = useState(false);

  if (canResend) {
    return (
      <TextBox as="p">
        If you can’t find it, you can{' '}
        <ResendCodeButton onSuccess={() => setCanResend(false)} />
      </TextBox>
    );
  }

  return (
    <TextBox as="p">
      Resend code in{' '}
      <TextBox as="span" variant="body-semibold">
        <Timer duration={60} onComplete={() => setCanResend(true)} />
      </TextBox>
    </TextBox>
  );
}

function ResendCodeButton({ onSuccess }: { onSuccess: () => void }) {
  const { resend, isLoading } = useResendMfaCode({ onSuccess });

  return (
    <Link
      onClick={resend}
      disabled={isLoading}
      underline="none"
      color="primary"
      component="button"
    >
      {isLoading ? 'Resending...' : 'Resend Code'}
    </Link>
  );
}
