import { round } from 'lodash-es';

const ONE_MB = 1024 * 1024;
const MILE_TO_KM_MULTIPLIER = 1.609344;

function convert(
  value: number,
  multiplier: number,
  precision?: number,
): number {
  const converted = value * multiplier;

  return precision == null ? converted : round(converted, precision);
}

export function mileToMeter(value: number, precision?: number): number {
  return convert(value, MILE_TO_KM_MULTIPLIER * 1_000, precision);
}

export function mileToKm(value: number, precision?: number): number {
  return convert(value, MILE_TO_KM_MULTIPLIER, precision);
}

export function kmToMile(value: number, precision?: number): number {
  return convert(value, 1 / MILE_TO_KM_MULTIPLIER, precision);
}

export function meterToMile(value: number, precision?: number): number {
  return convert(value / 1_000, 1 / MILE_TO_KM_MULTIPLIER, precision);
}

export function pricePerKmToPricePerMile(
  value: number,
  precision?: number,
): number {
  const converted = value / (1 / MILE_TO_KM_MULTIPLIER);

  return precision == null ? converted : round(converted, precision);
}

export function convertByteToMB(bytes: number) {
  return bytes / ONE_MB;
}

export function formatMiles(value: number, precision?: number): string {
  return `${round(value, precision)} mi`;
}
