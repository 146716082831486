import { Box, CircularProgress, Link, Paper } from '@material-ui/core';
import { FormattedDate, NullableDateRangeInput } from '@superdispatch/dates';
import {
  DateType,
  formatDateType,
  formatPaymentMethod,
  formatPaymentTerm,
  formatVehicleType,
  PaymentMethod,
  PaymentTerm,
  VehicleType,
} from '@superdispatch/sdk';
import { useResponsiveValue, useSnackbarStack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { startCase } from 'lodash-es';
import { Fragment, useEffect, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import ShowMoreText from 'react-show-more-text';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { DetailedFormattedDateRange } from 'shared/ui/DetailedFormattedDateRange';
import {
  TimelineItem,
  TimelineItemPadded,
  TimelineVirtualized,
} from 'shared/ui/TimelineVirtualized';
import { OrderViewTable } from '../OrderViewTable';
import { useOrderRevisions } from './data/OrderRevisionAPI';
import { normalizeRevisions } from './NormalizeRevisionsUtils';
import { RevisionNormalizedChangeValueType } from './types/OrderRevisionNormalized';

interface OneLineTextProps {
  text: string;
}

function OneLineText({ text }: OneLineTextProps) {
  return (
    <ShowMoreText lines={1} more="Read more" less="">
      {text.split('\n').map((item, idx) => (
        <Fragment key={idx}>
          {idx > 0 && <br />}
          {item}
        </Fragment>
      ))}
    </ShowMoreText>
  );
}

const renderValue = (
  value?: string | string[],
  valueType?: RevisionNormalizedChangeValueType,
) => {
  if (value === undefined || value === '') {
    return null;
  }

  if (Array.isArray(value)) {
    if (valueType === 'date') {
      return (
        <DetailedFormattedDateRange range={value as NullableDateRangeInput} />
      );
    }
  } else {
    if (valueType === 'text') {
      return <OneLineText text={value} />;
    }

    if (valueType === 'currency') {
      return <Price value={value} />;
    }

    if (valueType === 'number') {
      return (
        <NumericFormat
          displayType="text"
          value={value}
          thousandSeparator={true}
        />
      );
    }

    if (valueType === 'inch') {
      return (
        <NumericFormat
          displayType="text"
          value={value}
          suffix="″"
          decimalScale={0}
          thousandSeparator={true}
        />
      );
    }

    if (valueType === 'date') {
      return <DetailedFormattedDate date={value} />;
    }
  }

  if (valueType === 'dateType') {
    return formatDateType(value as DateType);
  }

  if (valueType === 'email') {
    return <Link href={`mailto:${String(value)}`}>{value}</Link>;
  }

  if (valueType === 'phone') {
    return <Link href={`tel:${String(value)}`}>{value}</Link>;
  }

  if (valueType === 'paymentTerms') {
    return formatPaymentTerm(value as PaymentTerm);
  }

  if (valueType === 'paymentMethod' && typeof value === 'string') {
    return formatPaymentMethod(value as PaymentMethod, {
      fallback: startCase(value),
    });
  }

  if (valueType === 'vehicleType') {
    return formatVehicleType(value as VehicleType);
  }

  if (valueType === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (valueType === 'permission') {
    return value ? 'Allowed' : 'Disabled';
  }

  return value;
};

export interface OrderViewRevisionsProps {
  orderGUID: string;
}

export function OrderViewRevisions({ orderGUID }: OrderViewRevisionsProps) {
  const { addSnackbar } = useSnackbarStack();

  const { data, error } = useOrderRevisions(orderGUID);

  const isMobile = useResponsiveValue(true, false);

  const normalizedRevisions = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return normalizeRevisions(data.revisions, data.users).filter(
      (revision) =>
        revision.type !== 'modified' ||
        revision.changes.find((change) => change.isExists),
    );
  }, [data]);

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [addSnackbar, error]);

  return !normalizedRevisions ? (
    <div style={{ textAlign: 'center', margin: '2rem' }}>
      <CircularProgress variant="indeterminate" color="primary" size={50} />
    </div>
  ) : (
    <TimelineVirtualized>
      {normalizedRevisions.map(
        ({ id, title, username, date, type, changes, action }) => (
          <TimelineItem
            key={id}
            title={title}
            subtitle={
              <TimelineItemPadded>
                {!!action && <TextBox color="inherit">{action}</TextBox>}
                <InlineBulletItems space="xxsmall">
                  {username && (
                    <Box maxWidth={isMobile ? '150px' : '100%'}>
                      <TextBox
                        wrapOverflow={true}
                        noWrap={true}
                        color="inherit"
                      >
                        {username}
                      </TextBox>
                    </Box>
                  )}
                  <FormattedDate
                    date={date}
                    format="JodaISO"
                    variant="DateTime"
                  />
                </InlineBulletItems>
              </TimelineItemPadded>
            }
          >
            <Box pt={2}>
              <TimelineItemPadded>
                <Paper>
                  <OrderViewTable
                    noContent="None"
                    columns={
                      type === 'deleted'
                        ? [
                            {
                              width: '20%',
                              header: 'Field',
                              field: 'title',
                              color: 'textSecondary',
                            },
                            {
                              width: '80%',
                              header: 'Old Value',
                              field: 'prevValue',
                            },
                          ]
                        : [
                            {
                              width: '20%',
                              header: 'Field',
                              field: 'title',
                              color: 'textSecondary',
                            },
                            {
                              width: '40%',
                              header: 'Old Value',
                              field: 'prevValue',
                            },
                            {
                              width: '40%',
                              header: 'New Value',
                              field: 'currValue',
                            },
                          ]
                    }
                    rows={changes
                      .filter((change) => change.isExists)
                      .map(
                        ({
                          title: changeTitle,
                          prevValue,
                          currValue,
                          valueType,
                        }) => ({
                          title: changeTitle,
                          prevValue: renderValue(prevValue, valueType),
                          currValue: renderValue(currValue, valueType),
                        }),
                      )}
                  />
                </Paper>
              </TimelineItemPadded>
            </Box>
          </TimelineItem>
        ),
      )}
    </TimelineVirtualized>
  );
}
