import { TimerOutlined, WarningOutlined } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import styled from 'styled-components';

const VerificationTag = styled.div<{ background: string }>`
  display: flex;
  width: fit-content;
  padding: 4px 16px 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${(props) => props.background};
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
`;

interface VerificationStatusTagProps {
  status: 'Pending' | 'Action Required';
}

export function VerificationStatusTag({ status }: VerificationStatusTagProps) {
  return (
    <VerificationTag
      background={status === 'Action Required' ? Color.Red500 : Color.Yellow300}
    >
      {status === 'Action Required' ? <WarningOutlined /> : <TimerOutlined />}
      {status}
    </VerificationTag>
  );
}
