import { Check, Lock, Timer, Warning } from '@material-ui/icons';
import { Inline, Tag, TagProps, useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton, AnchorButtonProps, Button } from '@superdispatch/ui-lab';
import { useCurrentOnboardingStep } from 'onboarding/OnboardingAPI';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import {
  DialogType,
  VerificationDialogs,
} from 'shipper-profile/core/VerificationDialogs';
import { useVerificationApplication } from 'shipper-profile/data/VerificationApplicationAPI';
import { useFeatureToggle } from '../data/FeatureToggle';
import { trackEvent } from '../helpers/AnalyticsHelpers';
import { useStorageValue, writeStorageItem } from '../helpers/LocalStorage';
import { IconBox } from '../icons/IconBox';
import { HeaderBanner } from '../ui/HeaderBanner';
import { OnboardingBannerManager } from './OnboardingBannerManager';

interface GenericStatusBannerProps {
  tagIcon: ReactNode;
  tagText: string;
  tagProps: Omit<TagProps, 'variant'> & { variant?: TagProps['variant'] };
  buttonProps: AnchorButtonProps;
  buttonText: string;
}

function GenericStatusBanner({
  tagIcon,
  tagText,
  tagProps,
  buttonProps,
  buttonText,
}: GenericStatusBannerProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <HeaderBanner
      text="Your verification status:"
      tag={
        <Tag variant="bold" {...tagProps}>
          <Inline space="none" verticalAlign="center">
            <IconBox>{tagIcon}</IconBox>
            {tagText}
          </Inline>
        </Tag>
      }
      actions={
        <AnchorButton
          size="small"
          variant="neutral"
          fullWidth={isMobile}
          {...buttonProps}
        >
          {buttonText}
        </AnchorButton>
      }
    />
  );
}

const VERIFICATION_BANNER_DISMISSED_LS_KEY = 'verification_banner_dismissed';

export function dismissVerificationBanner() {
  writeStorageItem(VERIFICATION_BANNER_DISMISSED_LS_KEY, 'true');
}

function VerifiedBanner() {
  const navigate = useNavigate();

  return (
    <GenericStatusBanner
      tagProps={{ color: 'green' }}
      tagText="Verified"
      tagIcon={<Check fontSize="small" />}
      buttonText="Get Started!"
      buttonProps={{
        href: '#',
        onClick: () => {
          trackEvent('Shipper Clicked on "Dismiss" Link in Verified Banner');
          navigate('/orders/create');
        },
      }}
    />
  );
}

function RevokedBanner() {
  return (
    <GenericStatusBanner
      tagProps={{ color: 'red' }}
      tagText="Revoked"
      tagIcon={<Warning fontSize="small" />}
      buttonText="Learn More"
      buttonProps={{
        href: 'https://support.superdispatch.com/en/articles/9806978-reinstating-broker-authority',
        target: '_blank',
        onClick: () => {
          trackEvent(
            'Shipper Clicked on "Learn More" Link in Operating Authority Inactive Banner',
          );
        },
      }}
    />
  );
}

interface PendingBannerProps {
  onClick: () => void;
}

function PendingBanner({ onClick }: PendingBannerProps) {
  return (
    <GenericStatusBanner
      tagProps={{ color: 'yellow' }}
      tagText="Pending"
      tagIcon={<Timer fontSize="small" />}
      buttonText="Learn More"
      buttonProps={{
        href: '#',
        onClick: () => {
          onClick();
          trackEvent(
            'Shipper Clicked on "Learn More" Link in Pending Verification Status Banner',
          );
        },
      }}
    />
  );
}

interface WaitingForCustomerBannerProps {
  onClick: () => void;
}

function WaitingForCustomerBanner({ onClick }: WaitingForCustomerBannerProps) {
  return (
    <GenericStatusBanner
      tagProps={{ color: 'red' }}
      tagText="Action Required"
      tagIcon={<Warning fontSize="small" />}
      buttonText="View Details"
      buttonProps={{
        href: '#',
        onClick: () => {
          onClick();
          trackEvent(
            'Shipper Clicked on "View Details" Link in Waiting For Customer Verification Status Banner',
          );
        },
      }}
    />
  );
}

interface NotSubmittedBannerProps {
  onClick: () => void;
}

function NotSubmittedBanner({ onClick }: NotSubmittedBannerProps) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <HeaderBanner
      icon={<Lock fontSize="inherit" />}
      text="Get verified to unlock Super Dispatch"
      actions={
        <Button size="small" fullWidth={isMobile} onClick={onClick}>
          Get Verified Now
        </Button>
      }
    />
  );
}

function VerificationBannerManager() {
  const isMobile = useResponsiveValue(true, false);
  const [currentDialog, setCurrentDialog] = useState<DialogType | null>(null);

  function handleOpenDialog(dialogType: DialogType) {
    if (dialogType === 'get-verified' && isMobile) {
      setCurrentDialog('get-verified-promo');
      return;
    }

    setCurrentDialog(dialogType);
  }

  const isVerificationBannerDismissed = useStorageValue(
    VERIFICATION_BANNER_DISMISSED_LS_KEY,
  );

  const { data: verificationInfo } = useVerificationApplication();

  if (!verificationInfo || isVerificationBannerDismissed) {
    return null;
  }

  return (
    <>
      {verificationInfo.status === 'REVOKED' && <RevokedBanner />}

      {verificationInfo.status === 'NOT_SUBMITTED' && (
        <NotSubmittedBanner onClick={() => handleOpenDialog('get-verified')} />
      )}

      {verificationInfo.status === 'PENDING' && (
        <PendingBanner onClick={() => handleOpenDialog('pending-dialog')} />
      )}

      {verificationInfo.status === 'WAITING_FOR_CUSTOMER' && (
        <WaitingForCustomerBanner
          onClick={() => handleOpenDialog('waiting-for-customer')}
        />
      )}

      {verificationInfo.status === 'VERIFIED' && <VerifiedBanner />}

      <VerificationDialogs
        currentDialog={currentDialog}
        onDialogChange={setCurrentDialog}
        onClose={() => setCurrentDialog(null)}
      />
    </>
  );
}

export function HeaderBannersStack() {
  const { step } = useCurrentOnboardingStep();
  const isProductOnboardingV1Enabled = useFeatureToggle(
    'stms.product-onboarding-v1.enabled',
  );
  const isVerificationOptimizationEnabled = useFeatureToggle(
    'verification-optimization.enabled.ui',
  );
  const { user } = useUserState();
  const isVerifiedShipper = user?.shipper.verification_status === 'VERIFIED';

  useEffect(() => {
    if (step === null || step?.step !== 'USER_ONBOARDING_INITIATED') {
      dismissVerificationBanner();
    }
  }, [step]);

  return (
    <>
      {isProductOnboardingV1Enabled && isVerifiedShipper && (
        <OnboardingBannerManager />
      )}
      {isVerificationOptimizationEnabled && <VerificationBannerManager />}
    </>
  );
}
