import { Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { Circle1Icon } from 'shared/icons/circle-1';
import { Circle2Icon } from 'shared/icons/circle-2';
import { ForwardArrowCircledIcon } from 'shared/icons/ForwardArrowCircledIcon';
import styled from 'styled-components';
import actionComplianceImg from '../assets/action-compliance-img.svg';
import actionPhoneVerificationImg from '../assets/action-phone-verification-img.svg';
import {
  OrderActionsAnalyticsProperties,
  trackVerificationApplicationEvent,
} from '../data/VerificationAnalyticsEvents';
import { VerificationOverlay } from './VerificationOverlay';
import { VerificationSidebar } from './VerificationSidebar';
import { VerificationStatusTag } from './VerificationStatusTag';

const VerificationActionRequiredBox = styled.div`
  display: flex;
  padding: 32px 32px 64px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const VerificationActionRequiredSteps = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    gap: 16px;
  }
`;

const TextBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  max-width: 218px;
  max-height: 94px;
  flex: 1 0 0;
`;

const WhatsNextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const RecommendationBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const BoxesGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const RecommendationStepBox = styled.div`
  cursor: pointer;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${Color.Silver400};
`;

const RecommendationStepText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

const BlockWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const Divider = styled.div`
  border-left: 1px solid ${Color.Silver400};
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    border-left: none;
    border-bottom: 1px solid ${Color.Silver400};
    width: 100%;
  }
`;

const EmailText = styled.div`
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`;

function VerificationApplicationDialogContent() {
  const { showCustomerDialog } = useCustomerSupport();
  const { user } = useUserState();
  const shipperEmail = user?.email;

  return (
    <VerificationActionRequiredBox>
      <WhatsNextBox>
        <Typography variant="h3">What happens now?</Typography>
        <VerificationActionRequiredSteps>
          <BlockWrapper>
            <Circle1Icon />
            <TextBoxGroup>
              <Typography variant="body1">
                Documentation Check Failed
              </Typography>
              <Typography>
                We were unable to verify one or more of your documents.
              </Typography>
            </TextBoxGroup>
            <img
              src={actionComplianceImg}
              alt="compliance"
              width="65px"
              height="64px"
            />
          </BlockWrapper>

          <Divider />

          <BlockWrapper>
            <Circle2Icon />
            <TextBoxGroup>
              <Typography variant="body1">Check Your Email</Typography>
              <Typography>
                We sent instructions to: <EmailText>{shipperEmail}</EmailText>
              </Typography>
            </TextBoxGroup>
            <img
              src={actionPhoneVerificationImg}
              alt="phone verification"
              width="65px"
              height="64px"
            />
          </BlockWrapper>
        </VerificationActionRequiredSteps>
      </WhatsNextBox>

      <RecommendationBox>
        <Typography variant="h3">Can we help?</Typography>
        <BoxesGroup>
          <RecommendationStepBox
            onClick={() => {
              showCustomerDialog();
            }}
          >
            <RecommendationStepText>
              <TextBox variant="heading-4">Get in touch</TextBox>
              <TextBox variant="caption" color="secondary">
                Contact our Compliance Team
              </TextBox>
            </RecommendationStepText>
            <ForwardArrowCircledIcon fontSize="large" />
          </RecommendationStepBox>
        </BoxesGroup>
      </RecommendationBox>
    </VerificationActionRequiredBox>
  );
}

interface VerificationActionRequiredDialogProps
  extends OrderActionsAnalyticsProperties {
  open: boolean;
  onClose: () => void;
}

export function VerificationActionRequiredDialog({
  open,
  onClose,
  action,
  actionType,
}: VerificationActionRequiredDialogProps) {
  useEffect(() => {
    if (open) {
      trackVerificationApplicationEvent({
        name: 'Shipper Opened Verification Action Required Dialog',
      });
    }
  }, [open, action, actionType]);

  return (
    <VerificationOverlay
      open={open}
      onClose={onClose}
      sidebar={
        <VerificationSidebar status="error">
          <Stack space="small">
            <Typography variant="h2">Your verification status:</Typography>
            <VerificationStatusTag status="Action Required" />
          </Stack>
        </VerificationSidebar>
      }
    >
      <VerificationApplicationDialogContent />
    </VerificationOverlay>
  );
}
