import { Card, CardContent, Link, Typography } from '@material-ui/core';
import { Help, Settings } from '@material-ui/icons';
import { FormikRadioGroupField } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  InfoCard,
  Inline,
  RadioField,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import {
  Alert,
  Box,
  Button,
  DescriptionItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { usePermission } from 'shared/data/UserPermissions';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import styled from 'styled-components';
import {
  SuperPaySettingsDTO,
  SuperPayUpdateSettingsDTO,
} from '../data/SuperPayDTO';

const InfoCardStyled = styled(InfoCard)<{
  checked: boolean;
}>`
  border-color: ${({ checked }) => (checked ? Color.Blue300 : Color.Silver400)};
`;

function LinkInstructionFlowWorks() {
  return (
    <Box>
      <Inline
        verticalAlign="center"
        space="xxsmall"
        horizontalAlign={['left', 'right']}
        noWrap={true}
      >
        <Help fontSize="small" color="action" />
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://support.superdispatch.com/en/articles/8451720-manual-vs-automatic-superpay-flow"
        >
          How Automated & Manual flows work
        </Link>
      </Inline>
    </Box>
  );
}

function WorkflowSettingsDescriptionItem({
  title,
  description,
  linkTitle,
  linkHref,
  disabled,
}: {
  title: string;
  description: string;
  linkHref?: string;
  linkTitle?: string;
  disabled?: boolean;
}) {
  return (
    <Box marginLeft={['none', 'xxsmall']}>
      <DescriptionItem wrap={true} inset={true}>
        <Stack space="none">
          <TextBox
            variant="heading-5"
            color={disabled ? 'secondary' : 'primary'}
          >
            {title}
          </TextBox>
          <TextBox variant="caption" color={disabled ? 'secondary' : 'primary'}>
            {description}
          </TextBox>
          {linkTitle && (
            <Link
              variant="caption"
              href={linkHref}
              color={disabled ? 'textSecondary' : 'primary'}
              target="_blank"
              rel="noreferrer"
            >
              {linkTitle}
            </Link>
          )}
        </Stack>
      </DescriptionItem>
    </Box>
  );
}

function AutomaticWarning() {
  return (
    <Alert severity="caution">
      <Typography>
        Automated workflow cannot be chosen until the number of orders within
        the{' '}
        <Link
          href="/orders/superpay_delivery_verified"
          target="_blank"
          rel="noreferrer"
        >
          “Delivery Verified”
        </Link>{' '}
        status is reduced to zero.
      </Typography>
    </Alert>
  );
}

function ManualInformation() {
  const { showCustomerDialog } = useCustomerSupport();

  return (
    <Alert severity="info">
      <Inline verticalAlign="center">
        <Typography>To enable Manual Flow</Typography>
        <Button
          variant="neutral"
          size="small"
          onClick={() => showCustomerDialog()}
        >
          Contact Support
        </Button>
      </Inline>
    </Alert>
  );
}

function PermissionInformation() {
  return (
    <Alert severity="info" icon={<Settings />}>
      <Typography>
        Admin or Accounting roles can change workflows, contact your manager for
        assistance.
      </Typography>
    </Alert>
  );
}

export function WorkflowSettings({
  superPaySettings,
}: {
  superPaySettings?: SuperPaySettingsDTO;
}) {
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  const isMobile = useResponsiveValue(true, false);
  const { values } = useFormikContext<SuperPayUpdateSettingsDTO>();
  const isAutomatic = values.superpay_flow === 'AUTOMATIC';
  const hasAutomaticWarning =
    !superPaySettings?.can_move_to_automatic_flow &&
    superPaySettings?.superpay_flow === 'MANUAL' &&
    isAutomatic;
  const disableManual =
    !superPaySettings?.can_move_to_manual_flow && isAutomatic;

  return (
    <Card square={isMobile}>
      <CardContent>
        <FormikRadioGroupField name="superpay_flow">
          <Stack space="small">
            <Columns
              align={['top', 'center']}
              space={['xsmall', 'none']}
              collapseBelow="tablet"
            >
              <Column width="content">
                <Typography variant="h3">SuperPay Workflow</Typography>
              </Column>
              <Column>
                <LinkInstructionFlowWorks />
              </Column>
            </Columns>
            {!canManageSuperPay && <PermissionInformation />}
            <Stack space="small">
              <InfoCardStyled checked={isAutomatic && canManageSuperPay}>
                <Stack space="small">
                  <Stack space="xsmall">
                    <RadioField
                      disabled={!canManageSuperPay}
                      label={<Typography variant="h4">Automated</Typography>}
                      value="AUTOMATIC"
                    />
                    {hasAutomaticWarning && <AutomaticWarning />}
                  </Stack>
                  <WorkflowSettingsDescriptionItem
                    disabled={!canManageSuperPay}
                    title="Automatically Charged"
                    description="SuperPay will automatically charge after delivery based on payment terms of the order."
                  />
                  <WorkflowSettingsDescriptionItem
                    disabled={!canManageSuperPay}
                    title="Funds released after successful Digital Delivery Verification"
                    description="Funds will be automatically released to the carrier upon successful Digital Delivery Verification."
                    linkTitle="What is Digital Delivery Verification?"
                    linkHref="https://support.superdispatch.com/en/articles/6888937-shipper-tms-all-about-digital-delivery-verification"
                  />
                  <WorkflowSettingsDescriptionItem
                    disabled={!canManageSuperPay}
                    title="Payment put on hold when issues are detected"
                    description="Payment will be automatically put on hold when potential issues are detected."
                    linkTitle="What issues would put payments automatically on hold?"
                    linkHref="https://support.superdispatch.com/en/articles/6888937-shipper-tms-all-about-digital-delivery-verification#h_b916626b86"
                  />
                </Stack>
              </InfoCardStyled>
              <InfoCardStyled checked={!isAutomatic && canManageSuperPay}>
                <Stack space="small">
                  <RadioField
                    disabled={disableManual || !canManageSuperPay}
                    label={<Typography variant="h4">Manual</Typography>}
                    value="MANUAL"
                  />
                  {disableManual && canManageSuperPay && <ManualInformation />}
                  <WorkflowSettingsDescriptionItem
                    disabled={disableManual || !canManageSuperPay}
                    title="Charge triggered by Shipper"
                    description="All charges from the shipper’s account and payments to carriers are manually triggered by the shipper."
                  />
                  <WorkflowSettingsDescriptionItem
                    disabled={disableManual || !canManageSuperPay}
                    title="Mark as Delivery Verified for payment to be initiated"
                    description="Dispatchers have to mark orders as ‘Delivery Verified’ in order to allow for payments to be initiated."
                  />
                  <WorkflowSettingsDescriptionItem
                    disabled={disableManual || !canManageSuperPay}
                    title="Manually release payments"
                    description="Digital Delivery Verification does NOT release funds to carriers."
                  />
                </Stack>
              </InfoCardStyled>
            </Stack>
          </Stack>
        </FormikRadioGroupField>
      </CardContent>
    </Card>
  );
}
