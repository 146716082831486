import { InputAdornment, Link, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { ChangeEvent } from 'react';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import microDepositExample from '.././assets/micro_deposit_example.png';
import { InformationErrorBanner } from '../helpers/InformationErrorBanner';

const currencyFieldAdornment = (
  <InputAdornment
    disableTypography={true}
    position="start"
    style={{ marginRight: 0 }}
  >
    <Typography variant="body1" color="textSecondary">
      $ 0.
    </Typography>
  </InputAdornment>
);

function VerifyBankAccountField({
  label,
  name,
  isDisabled,
}: {
  label: string;
  name: string;
  isDisabled: boolean;
}) {
  const [{ value: currentValue }, { error, touched }, { setValue }] =
    useField<string>(name);
  const hasError = touched && !!error;

  function parseValue(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    const value = event.target.value.trim();
    const numberValue = Number(value);
    if (Number.isNaN(numberValue)) {
      return currentValue;
    }
    return value.length < 2 ? value : value.slice(-2);
  }

  function onFocus(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const value = event.target.value.trim();
    if (!value) {
      setValue('00');
    }
  }

  function onBlur(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const value = event.target.value.trim();
    if (value.length === 1) {
      // Moov is waiting format - 0.05 = 5, 0.5 = 50
      // if user set '5' - then we should send '50'
      setValue(`${value}0`);
    }
  }

  return (
    <FormikTextField
      label={label}
      name={name}
      error={hasError}
      fullWidth={true}
      placeholder="00"
      disabled={isDisabled}
      InputProps={{
        startAdornment: currencyFieldAdornment,
      }}
      parse={parseValue}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}

interface VerificationErrorBannerProps {
  isDisabled: boolean;
  isErrorMoov: boolean;
  isErrorTotalSum: boolean;
  isErrorLastValues: boolean;
}

function VerificationErrorBanner({
  isErrorMoov,
  isDisabled,
  isErrorTotalSum,
  isErrorLastValues,
}: VerificationErrorBannerProps) {
  const { showCustomerDialog } = useCustomerSupport();

  if (isDisabled) {
    return (
      <InformationErrorBanner
        text={
          <Stack space="small">
            <Typography>
              Too many failed attempts. Contact support for assistance.
            </Typography>
            <Button
              variant="neutral"
              data-intercom-target="SuperPay Bank Verification Status Failed"
              onClick={() => showCustomerDialog()}
            >
              Contact Support
            </Button>
          </Stack>
        }
      />
    );
  }

  return isErrorTotalSum ? (
    <InformationErrorBanner
      text={
        <Stack space="xsmall">
          <Typography>
            The amounts are incorrect. One entry should be the sum of the other
            two.
          </Typography>
          <Typography>
            Please look for transactions from{' '}
            <b>Super Dispatch mentioning AcctVerify.</b>
          </Typography>
        </Stack>
      }
    />
  ) : isErrorLastValues ? (
    <InformationErrorBanner
      text={
        <Stack space="xsmall">
          <Typography>This trio was already marked as incorrect.</Typography>
          <Typography>
            Please look for transactions from{' '}
            <b>Super Dispatch mentioning AcctVerify.</b>
          </Typography>
        </Stack>
      }
    />
  ) : isErrorMoov ? (
    <InformationErrorBanner
      text={
        <Stack space="xsmall">
          <Typography>
            Wrong deposit amounts. Only <b>ONE</b> attempt left.
          </Typography>
          <Typography>
            Please look for transactions from{' '}
            <b>Super Dispatch mentioning AcctVerify.</b>
          </Typography>
        </Stack>
      }
    />
  ) : null;
}

interface BankVerificationFormProps {
  isDisabled: boolean;
  isErrorMoov: boolean;
  isErrorTotalSum: boolean;
  isErrorLastValues: boolean;
}

export function BankVerificationFormContent({
  isErrorMoov,
  isDisabled,
  isErrorTotalSum,
  isErrorLastValues,
}: BankVerificationFormProps) {
  return (
    <Stack space={['small', 'large']}>
      <Stack space="small">
        <Box maxWidth="400px" margin="auto">
          <img
            src={microDepositExample}
            width="100%"
            alt="Micro deposit example"
          />
        </Box>
        <Columns space="xsmall">
          <Column width="content">
            <Warning fontSize="small" htmlColor={Color.Yellow300} />
          </Column>
          <Column>
            <Stack space="xsmall">
              <Typography>
                Please enter 3 transactions that appeared in your bank account
                from Super Dispatch mentioning <b>AcctVerify</b>. Submit in any
                order.
              </Typography>
              <Link
                target="_blank"
                rel="noreferrer"
                variant="caption"
                color="primary"
                href="https://support.superdispatch.com/en/articles/6726293-shipper-tms-how-do-i-get-started-with-superpay#h_880a0525fb"
              >
                Learn how to verify micro-deposits.
              </Link>
            </Stack>
          </Column>
        </Columns>
      </Stack>
      <Stack space="small">
        <VerificationErrorBanner
          isErrorMoov={isErrorMoov}
          isErrorTotalSum={isErrorTotalSum}
          isErrorLastValues={isErrorLastValues}
          isDisabled={isDisabled}
        />

        <Typography variant="body1">Submit in any order</Typography>

        <VerifyBankAccountField
          label="First Value"
          name="firstDeposit"
          isDisabled={isDisabled}
        />

        <VerifyBankAccountField
          label="Second Value"
          name="secondDeposit"
          isDisabled={isDisabled}
        />
        <VerifyBankAccountField
          label="Third Value"
          name="thirdDeposit"
          isDisabled={isDisabled}
        />
      </Stack>
    </Stack>
  );
}
