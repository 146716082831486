import { AuthenticatedRouteObject } from 'AppRouter';
import { Outlet } from 'react-router-dom';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { BankAccountPage } from './bank-account/BankAccountPage';
import { BusinessDetailsPage } from './business-details/BusinessDetailsPage';
import { OwnersListPage } from './owners/OwnersListPage';
import { SuperPayPromoPage } from './promo/SuperPayPromoPage';
import { ROUTES_SUPERPAY, SuperPayRoute } from './SuperPayRoute';
import { SuperPaySettingsPage } from './SuperPaySettingsPage';

export const superPayRoute: AuthenticatedRouteObject = {
  path: 'superpay',
  Component: SuperPayContainer,
  children: [
    {
      path: 'business-details',
      element: (
        // The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6.
        <SuperPayRoute path={ROUTES_SUPERPAY.businessDetails}>
          <BusinessDetailsPage />
        </SuperPayRoute>
      ),
    },
    {
      path: 'owners',
      element: (
        <SuperPayRoute path={ROUTES_SUPERPAY.owners}>
          <OwnersListPage />
        </SuperPayRoute>
      ),
    },
    {
      path: 'bank-details/*',
      element: (
        <SuperPayRoute path={ROUTES_SUPERPAY.bankDetails}>
          <SuperPaySettingsPage />
        </SuperPayRoute>
      ),
    },
    {
      path: 'bank-account',
      element: (
        <SuperPayRoute path={ROUTES_SUPERPAY.bankAccount}>
          <BankAccountPage />
        </SuperPayRoute>
      ),
    },
    {
      index: true,
      element: (
        <SuperPayRoute path={ROUTES_SUPERPAY.main}>
          <SuperPayPromoPage />
        </SuperPayRoute>
      ),
    },
  ],
};

function SuperPayContainer() {
  useDocumentTitle('SuperPay');
  return <Outlet />;
}
