import {
  DateType,
  isValidSuperPayTerm,
  listCustomerTypes,
  listDateTypes,
  PaymentMethod,
  PaymentTerm,
} from '@superdispatch/sdk';
import { plainToClass } from 'class-transformer';
import { round } from 'lodash-es';
import { OrderActionType } from 'orders/core/actions/OrderActionsContext';
import { OrderStepVenueDTO } from 'shared/dto/Order/OrderStepDTO';
import Order, { InspectionType, OrderPayment } from 'shared/types/order';
import { OrderContact, Step, Venue } from 'shared/types/step';
import { User } from 'shared/types/user';
import { DecodedOrderVehicleDTO } from '../data/DecodedOrderVehicleDTO';
import { OrderFormVehicleDTO } from '../data/OrderFormVehicleDTO';
import { OrderFormStep } from '../OrderForm';

/**
 * @description This function is used to calculate the sum of all vehicles.
 * @param {OrderFormVehicleDTO[] | DecodedOrderVehicleDTO[]} vehicles - List of vehicles.
 * @param {'price' | 'tariff'} field - Field to calculate the sum.
 * @returns {number} - Sum of all vehicles.
 */
export const calculateVehiclesSum = (
  vehicles: OrderFormVehicleDTO[] | DecodedOrderVehicleDTO[] = [],
  field: 'price' | 'tariff',
): number => {
  const sum = vehicles
    .map((vehicle) => vehicle[field])
    .reduce<number>((acc, price) => (price ? acc + Number(price) : acc), 0);

  return round(sum, 2);
};

export const userFullName = (user: User): string =>
  `${user.first_name} ${user.last_name}`;

// Has a bug. If there are two users with the same name, it will return the first one.
export const userNameToId = (
  users: User[],
  dispatcher_name: string,
): number | undefined =>
  users.find((user) => userFullName(user) === dispatcher_name)?.id;

/**
 * @description This function is used to create a contact object for OrderForm component.
 * @param {Venue | Order['customer']} value - Venue or customer object.
 * @returns {OrderContact} - Contact object for OrderForm component.
 */
export function createContact(value: Venue | Order['customer']): OrderContact {
  return {
    title: value?.contact_title || null,
    email: value?.contact_email || null,
    name: value?.contact_name || null,
    phone: value?.contact_phone || null,
    mobile_phone: value?.contact_mobile_phone || null,
  };
}

/**
 * @description This function is used to normalize contact object for OrderForm component.
 * @param {OrderContact} contact - Contact object for OrderForm component.
 * @returns {OrderContact} - Normalized contact object for OrderForm component.
 */
export function normalizeContact(contact?: OrderContact) {
  return {
    contact_title: contact?.title,
    contact_email: contact?.email,
    contact_name: contact?.name,
    contact_phone: contact?.phone,
    contact_mobile_phone: contact?.mobile_phone,
  };
}

/**
 * @description This function is used to get initial value for OrderForm component.
 * @param {Step} step - OrderForm step. If step is undefined, then it is a new order.
 * @param {DateType} recent_date_type - Recent date type.
 * @param {boolean} isOrderCreate - If true, then it is a new order.
 * @param {boolean} businessTypeVisible - If true, then business type is visible.
 * @returns {OrderFormStep} - Initial value for OrderForm component.
 */
export function getStepInitialValue(
  step?: Step,
  recent_date_type?: DateType,
  isOrderCreate?: boolean,
  businessTypeVisible?: boolean,
): OrderFormStep {
  if (step) {
    const plainVenue = plainToClass(OrderStepVenueDTO, step.venue);

    return isOrderCreate
      ? {
          ...step,
          date_type: recent_date_type || listDateTypes()[0] || 'estimated',
          venue: {
            ...plainVenue,
            contact: createContact(plainVenue),
          },
        }
      : {
          ...step,
          venue: {
            ...plainVenue,
            contact: createContact(plainVenue),
          },
        };
  }

  return {
    date_type:
      (isOrderCreate && recent_date_type) || listDateTypes()[0] || 'estimated',
    venue: {
      business_type:
        isOrderCreate && !businessTypeVisible
          ? undefined
          : listCustomerTypes()[0],
      name: null,
      address: null,
      city: null,
      state: null,
      zip: null,
    },
    scheduled_at_by_carrier: null,
    scheduled_at: null,
    scheduled_ends_at: null,
    scheduled_at_by_customer: null,
    scheduled_ends_at_by_customer: null,
    latitude: null,
    longitude: null,
    completed_at: null,
    adjusted_date: null,
    notes: null,
  };
}

/**
 * @description This function is used to get initial value for inspection type.
 * @param {string | null} defaultInspectionType - Default inspection type.
 * @param {PaymentMethod | null} defaultPaymentMethod - Default payment method.
 * @param {InspectionType} recentInspectionType - Recent inspection type.
 * @returns {InspectionType} - Initial value for inspection type.
 */
export function getInspectionTypeInitialValue(
  defaultInspectionType?: InspectionType | null,
  defaultPaymentMethod?: PaymentMethod | null,
): InspectionType {
  if (defaultPaymentMethod === 'superpay') {
    return 'advanced';
  }
  return defaultInspectionType || 'standard';
}

/**
 * @description This function is used to get initial value for payment.
 * @param {AllPaymentTerm | null} defaultPaymentTerms - Default payment terms.
 * @param {PaymentMethod | null} defaultPaymentMethod - Default payment method.
 * @returns {OrderPayment} - Initial value for payment.
 */
export function getPaymentInitialValue(
  defaultPaymentTerms?: PaymentTerm | null,
  defaultPaymentMethod?: PaymentMethod | null,
): OrderPayment {
  let method = defaultPaymentMethod ?? undefined;
  let terms = defaultPaymentTerms ?? 'other';

  if (method === 'superpay' && !isValidSuperPayTerm(terms)) {
    terms = '1_3_days';
  }
  return { terms, method, super_pay: null };
}

/**
 * @description This function is used to clean up order object before tracking.
 * @param {Order} order - Order object.
 * @returns {Order} - Initial value for order.
 */
export const modifyOrderToTrack = (
  updatedOrder: Order | Partial<Order>,
): Order | Partial<Order> => {
  let orderToTrack: Order | Partial<Order> = { ...updatedOrder };

  delete orderToTrack.customer?.save_as_recent;
  delete orderToTrack.pickup?.venue?.save_as_recent;
  delete orderToTrack.delivery?.venue?.save_as_recent;

  return orderToTrack;
};

export type OrderFormType =
  | 'save'
  | 'save_and_submit'
  | 'save_and_send_offer'
  | 'save_and_post_to_all'
  | 'save_and_post_to_sdlb'
  | 'save_and_post_to_cd'
  | 'save_and_post_to_private_loadboard';

/**
 * @description This function is used to get order action type.
 * @param {OrderFormType} type - Order form type.
 * @returns {OrderActionType | null} - Order action type.
 */
export function getOrderActionType(
  type: OrderFormType | undefined,
): OrderActionType | null {
  switch (type) {
    case 'save_and_submit':
      return 'submit_to_broker';
    case 'save_and_send_offer':
      return 'send_offer';
    case 'save_and_post_to_all':
      return 'post_to_all';
    case 'save_and_post_to_sdlb':
      return 'post_to_sdlb';
    case 'save_and_post_to_cd':
      return 'post_to_cd';
    case 'save_and_post_to_private_loadboard':
      return 'post_to_private_loadboard';
    default:
      return null;
  }
}

export function getOrderAction(
  action: { type: OrderFormType; args?: Record<string, unknown> } | null,
) {
  const type = getOrderActionType(action?.type);

  if (!type) {
    return undefined;
  }

  return {
    type,
    args: action?.args,
  };
}
