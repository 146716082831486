import { debounce } from '@material-ui/core';
import { Dehaze, ViewAgendaOutlined } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { OrderListActions } from 'orders/core/actions/OrderListActions';
import {
  OrderListCardSortBar,
  OrdersListCardView,
} from 'orders/core/list/OrdersListCardView';
import { OrderRequestsDrawer } from 'orders/core/order-requests/OrderRequestsDrawer';
import { OrderRequestsParamsDTO } from 'orders/data/dto/OrderRequestsParamsDTO';
import { useOrdersCache } from 'orders/data/OrderAPI';
import { OrderListFilter } from 'orders/list/OrderListFilter';
import { ORDER_VIEW_KEY } from 'orders/list/OrdersListPage';
import { OrdersEmptyState } from 'orders/OrdersEmptyState';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { useQueryParams } from 'shared/helpers/RouteHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { useUserState } from '../../shared/data/AppUserState';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { SuperPayRequestedBanner } from '../../superpay/request-to-use/SuperPayRequestedBanner';
import { OrderTable, RequestedLoadsButton } from '../core';
import { OrderActions } from '../core/actions/OrderActions';
import {
  OrderActionCompletePayload,
  OrderActionsProvider,
} from '../core/actions/OrderActionsContext';
import { OrdersListFooter } from '../core/list/OrdersListFooter';
import { OrdersListHeader } from '../core/list/OrdersListHeader';
import { useRequestedOrders } from '../data/OrderRequestsAPI';
import { OrderRequestNumber } from './OrderRequestNumber';

const Wrap = styled.div<{ collapse: boolean; height: string }>`
  min-height: ${({ height }) => height};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  transition: all 0.3s ease;
  opacity: 1;
  background: ${Color.White};

  ${({ collapse }) =>
    collapse && 'height: 0; min-height: 0; opacity: 0; padding: 0;'};
`;

const StyledToggleButton = styled(ToggleButton)`
  color: ${Color.Dark100};
  &.Mui-selected {
    background: transparent;
    color: ${Color.Dark500};
  }
`;

export const Content = styled.div`
  overflow: auto;
  flex-grow: 1;
  positioon: relative;
`;

const StyledBox = styled(Box)`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

export function OrderRequestsPage() {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const { user } = useUserState();
  const navigate = useNavigate();

  const [params, setParams] = useQueryParams(OrderRequestsParamsDTO);

  const { sort, size, page, order_guid, ...filters } = params;

  const { data, refetch } = useRequestedOrders({
    ...params,
    page: Number(params.page || 1) - 1,
  });
  const { data: counts } = useOrderCounts();
  const { invalidateOrders } = useOrdersCache();
  const orderViewKey = useStorageValue(ORDER_VIEW_KEY) || 'card';
  const [orderView, setOrderView] = useState<string>(orderViewKey);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [scrolDirection, setScrollDirection] = useState('up');

  const sortArr = params.sort?.split(',');
  const orders = useMemo(() => data?.objects.map((x) => x.order), [data]);
  const activeOrder = useMemo(
    () => orders?.find((item) => item.guid === params.order_guid),
    [orders, params],
  );

  useDocumentTitle('Requests');

  function handleActionComplete({ type, payload }: OrderActionCompletePayload) {
    if (type === 'duplicate' && payload.updatedOrder) {
      navigate(`/orders/view/${payload.updatedOrder.guid}`);
    } else {
      void refetch();
    }

    void invalidateOrders();
  }

  const handleSetOrderView = (
    event: React.MouseEvent<HTMLElement>,
    newOrderView: string | null,
  ) => {
    if (event.target && newOrderView !== null) {
      writeStorageItem(ORDER_VIEW_KEY, newOrderView);
      setOrderView(newOrderView);
      trackEventLegacy('Clicked on change orders view', {
        selectedView: newOrderView,
      });
    }
  };

  const handleScroll = debounce((event) => {
    const { scrollTop } = event.target;
    const scrollMargin = Math.abs(scrollTop - lastScrollPosition);

    if (scrollTop >= lastScrollPosition && scrollMargin > 200) {
      setScrollDirection('down');
    }
    if (scrollTop < lastScrollPosition && scrollMargin > 200) {
      setScrollDirection('up');
    }

    if (scrollMargin > 200) {
      setLastScrollPosition(scrollTop);
    }
  }, 20);

  if (data?.pagination.total_objects === 0 && counts?.total === 0) {
    return <OrdersEmptyState title="Requests" />;
  }

  return (
    <Box display="flex" minHeight="100%">
      <PageLayout
        header={
          <>
            <OrdersListHeader title="Requests" />
            <Wrap
              height={
                orderView === 'card' && platform === 'mobile' ? '80px' : 'unset'
              }
              collapse={
                platform === 'mobile' &&
                orderView === 'card' &&
                scrolDirection === 'down'
              }
            >
              <StyledBox>
                <Columns align="center">
                  <Column width="fluid">
                    <Columns align="center" space="xsmall">
                      <Column width="content">
                        <TextBox color="secondary">
                          {data?.pagination.total_objects} orders
                        </TextBox>
                      </Column>
                      <Column>
                        <OrderListFilter
                          status="requests"
                          filters={filters}
                          onChange={(key, value) => {
                            setParams({ [key]: value, page: 1 });
                          }}
                          onRemove={(key) => {
                            setParams({ [key]: undefined, page: 1 });
                          }}
                        />
                      </Column>
                    </Columns>
                  </Column>
                  <Column width="content">
                    {platform !== 'mobile' &&
                      user?.shipper.subscription_plan === 'ADVANCED' && (
                        <ToggleButtonGroup
                          exclusive={true}
                          value={orderView}
                          onChange={handleSetOrderView}
                          aria-label="order view toggle"
                        >
                          <StyledToggleButton
                            value="card"
                            aria-label="order card"
                          >
                            <ViewAgendaOutlined fontSize="small" />
                          </StyledToggleButton>
                          <StyledToggleButton
                            value="table"
                            aria-label="order table"
                          >
                            <Dehaze fontSize="small" />
                          </StyledToggleButton>
                        </ToggleButtonGroup>
                      )}
                  </Column>
                </Columns>
              </StyledBox>
            </Wrap>
          </>
        }
        footer={
          platform !== 'mobile' && (
            <OrdersListFooter
              page={(params.page || 1) - 1}
              pageSize={params.size}
              totalPages={data?.pagination.total_pages}
              handlePageChange={({ selected }) => {
                setParams({ page: selected + 1 });
              }}
              handlePageSizeChange={({ value }) => {
                setParams({ page: 1, size: value });
              }}
            />
          )
        }
      >
        {orderView === 'card' && (
          <OrderListCardSortBar
            orders={orders || []}
            isLoading={!data}
            selectedOrders={[]}
            onSelectAllOrders={() => {}}
            onUnselectAllOrders={() => {}}
            onSort={(sortKey, sortOrder) => {
              setParams({
                page: 1,
                sort: `${sortKey},${sortOrder}`,
              });
            }}
            disableSelectable={true}
            sortKey={sortArr?.[0]}
            sortOrder={sortArr?.[1]}
          />
        )}

        <Stack space="none">
          {isCarrierRequestsSuperPay &&
            user?.shipper.super_pay_settings
              ?.superpay_requested_banner_available && (
              <Box padding="small">
                <SuperPayRequestedBanner source="Requests" />
              </Box>
            )}
          <OrderActionsProvider
            source="Order Requests"
            onActionComplete={handleActionComplete}
          >
            <Content onScroll={handleScroll}>
              {orderView === 'card' ? (
                <OrdersListCardView
                  orders={orders || []}
                  isLoading={!data}
                  selectedOrders={[]}
                  toggleSelectOrder={() => {}}
                  disableSelectable={true}
                  renderNumber={(order) => (
                    <RequestedLoadsButton
                      order={order}
                      revalidate={false}
                      onClick={() => {
                        setParams({ order_guid: order.guid });
                      }}
                    />
                  )}
                  renderActions={(order) => (
                    <OrderActions order={order} source="Order List" />
                  )}
                />
              ) : (
                <OrderTable
                  isLoading={!data}
                  orders={orders || []}
                  selectedOrders={[]}
                  onUnselectAllOrders={() => {}}
                  onSelectAllOrders={() => {}}
                  toggleSelectOrder={() => {}}
                  showRequestsCheckbox={false}
                  renderNumber={(order) => (
                    <OrderRequestNumber
                      order={order}
                      onClick={() => {
                        setParams({ order_guid: order.guid });
                      }}
                    />
                  )}
                  renderActions={(order) => <OrderListActions order={order} />}
                  sortKey={sortArr?.[0]}
                  sortOrder={sortArr?.[1]}
                  onSort={(sortKey, sortOrder) => {
                    setParams({ page: 1, sort: `${sortKey},${sortOrder}` });
                  }}
                />
              )}

              {platform === 'mobile' && (
                <OrdersListFooter
                  page={params.page}
                  pageSize={params.size}
                  totalPages={data?.pagination.total_pages}
                  handlePageChange={({ selected }) => {
                    setParams({ page: selected + 1 });
                  }}
                  handlePageSizeChange={({ value }) => {
                    setParams({ page: 1, size: value });
                  }}
                />
              )}
            </Content>
          </OrderActionsProvider>
        </Stack>
      </PageLayout>

      <OrderRequestsDrawer
        order={activeOrder}
        onClose={() => setParams({ order_guid: undefined })}
        onSubmitSuccess={() => {
          void refetch();
        }}
      />
    </Box>
  );
}
