import { Color } from '@superdispatch/ui';
import { AnchorButton, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { AccountVerifiedIcon } from 'shared/icons/AccountVerifiedIcon';
import { InfoBanner } from 'shared/ui/InfoBanner';
import styled from 'styled-components';
import revokedImage from '../assets/revoked.png';
import verificationReviewImage from '../assets/verification-underway.png';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { VerificationStatusDrawer } from './VerificationStatusDrawer';

const VerifiedIcon = styled(AccountVerifiedIcon)`
  background-color: ${Color.White};
  border-radius: 50%;
  color: ${Color.Green300};
  font-size: 40px;
`;

interface VerificationStatusBannerProps {
  status: ShipperProfileDTO['status'];
}

export function VerificationStatusBanner({
  status,
}: VerificationStatusBannerProps) {
  const { showCustomerDialog } = useCustomerSupport();
  const navigate = useNavigate();

  const [currentModal, setCurrentModal] = useState<'verification-status'>();

  if (status === 'VERIFIED' || status === 'INTERNATIONAL_SHIPPER') {
    return null;
  }

  if (status === 'NOT_SUBMITTED') {
    return (
      <InfoBanner
        illustration={<VerifiedIcon />}
        title="Become a Verified Shipper"
        description="Verify your shipper account to start moving vehicles with Super Dispatch."
        action={
          <Button
            onClick={() => {
              navigate(
                '/verification-application/?utm_medium=Profile&utm_content=Become a Verified Shipper',
              );
            }}
          >
            Get Verified Now
          </Button>
        }
      />
    );
  }

  if (status === 'REVOKED') {
    return (
      <InfoBanner
        illustration={
          <img width="60px" src={revokedImage} alt="revoked illustration" />
        }
        variant="warning"
        title="Your Verified Status Was Revoked"
        description="Contact the FMCSA directly to restore your authority. Until it is restored, you can no longer post orders on the Super Loadboard."
        action={
          <AnchorButton
            target="_blank"
            href="https://support.superdispatch.com/en/articles/9806978-reinstating-broker-authority"
          >
            Learn More
          </AnchorButton>
        }
      />
    );
  }

  if (
    status === 'REJECTED' ||
    status === 'REVOKED' ||
    status === 'WAITING_FOR_CUSTOMER'
  ) {
    return (
      <InfoBanner
        illustration={<VerifiedIcon />}
        title="Become a Verified Shipper"
        description="Verify your shipper account to start moving vehicles with Super Dispatch."
        action={
          <Button
            variant="neutral"
            onClick={() => {
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on Contact Compliance',
                utm_content: 'Become a Verified Shipper',
                verification_status: status,
              });
              showCustomerDialog();
            }}
          >
            Contact compliance
          </Button>
        }
      />
    );
  }

  return (
    <>
      <VerificationStatusDrawer
        open={currentModal === 'verification-status'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
      />
      <InfoBanner
        illustration={
          <img src={verificationReviewImage} alt="Verification under review" />
        }
        title="Verification under review"
        description="Our compliance team will get back to you within 24 to 48 hours"
        action={
          <Button
            variant="neutral"
            onClick={() => {
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on Learn More',
                utm_content: 'Verification Under Review',
              });
              setCurrentModal('verification-status');
            }}
          >
            Learn More
          </Button>
        }
      />
    </>
  );
}
