import { Tooltip, Typography } from '@material-ui/core';
import {
  formatPaymentTerm,
  PaymentMethod,
  PaymentTerm,
} from '@superdispatch/sdk';
import { Color } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { NumericFormat } from 'react-number-format';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import { InlineBulletItems } from 'shared/ui/BulletTextItem';

export interface PaymentProps {
  amount?: number;
  terms?: PaymentTerm;
  tooltip?: string;
  method?: PaymentMethod;
  highlightPrice?: boolean;
}

export function OrderListPayment({
  amount,
  terms,
  tooltip,
  method,
  highlightPrice,
}: PaymentProps) {
  return (
    <TextBox color="secondary" noWrap={true}>
      <InlineBulletItems
        verticalAlign="center"
        horizontalAlign="left"
        noWrap={true}
      >
        {amount && (
          <Tooltip title={tooltip ?? ''} placement="top">
            <NumericFormat
              renderText={(value) => (
                <Typography
                  color="textPrimary"
                  style={{
                    color: highlightPrice ? Color.Yellow500 : undefined,
                  }}
                >
                  {value}
                </Typography>
              )}
              displayType="text"
              value={amount}
              thousandSeparator={true}
              prefix="$"
            />
          </Tooltip>
        )}
        {method === 'superpay' && <SuperPayLogo />}

        {terms && formatPaymentTerm(terms)}
      </InlineBulletItems>
    </TextBox>
  );
}
