import {
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AccessTime,
  Delete,
  Edit,
  ThumbDown,
  ThumbUp,
} from '@material-ui/icons';
import { formatDate } from '@superdispatch/dates';
import {
  AvatarButton,
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import {
  CanExpeditePaymentTag,
  CanInstantlyBookTag,
  InsuranceExpiredTag,
  PrivateNetworkTag,
  UnverifiedTag,
  VerifiedTag,
} from 'core/CarrierTags';
import { isCarrierInsuranceExpired } from 'core/insurance/ExpirationDates';
import { ReactElement, ReactNode } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useProductTiers } from 'shared/data/TiersUtils';
import { useCanExecute } from 'shared/data/UserPermissions';
import { ApprovedIcon } from 'shared/icons/ApprovedIcon';
import { CertificateIcon } from 'shared/icons/CertificateIcon';
import { ErrorInfoIcon } from 'shared/icons/ErrorInfoIcon';
import { IconBox } from 'shared/icons/IconBox';
import { CarrierFullInfo } from 'shared/types/carrier';
import { getInitials } from 'shared/utils/TextUtils';
import { useVerificationApplication } from 'shipper-profile/data/VerificationApplicationAPI';
import styled from 'styled-components';
import { CarrierPerformanceIndicators } from './CarrierPerformanceIndicators';

const IconWrapper = styled.div`
  padding: 10px;
  border-radius: 50%;

  &[data-variant='success'] {
    color: ${Color.Green300};
    background-color: ${Color.Green50};
  }

  &[data-variant='error'] {
    color: ${Color.Red300};
    background-color: ${Color.Red50};
  }
`;

const LinkInternalRecords = ({
  status,
  children,
  onClick,
}: {
  status: ReactNode;
  children: ReactElement;
  onClick: () => void;
}) => {
  const canUserUpdateRecords = useCanExecute(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
  );

  if (!canUserUpdateRecords) {
    return children;
  }

  return (
    <Tooltip
      interactive={true}
      title={
        <>
          <Typography color="inherit">{status}</Typography>
          <Link component="button" color="inherit" onClick={onClick}>
            Edit in Internal Records
          </Link>
        </>
      }
    >
      {children}
    </Tooltip>
  );
};

interface RateCarrierSectionProps {
  carrier: CarrierFullInfo;
  onRate: () => void;
  onRatingDelete: () => void;
}

function RateCarrierSection({
  carrier,
  onRate,
  onRatingDelete,
}: RateCarrierSectionProps) {
  const { broker_records } = carrier;
  const isRestrictRating = useFeatureToggle(
    'compliance.restrict-ratings-for-unverified-shippers.enabled',
  );
  const { data: verificationInfo } = useVerificationApplication();
  const showRateButton = !isRestrictRating
    ? carrier.broker_records.accepted_loads_count > 0
    : carrier.broker_records.accepted_loads_count > 0 &&
      (verificationInfo?.status === 'VERIFIED' ||
        verificationInfo?.status === 'INTERNATIONAL_SHIPPER');

  if (broker_records.carrier_rating?.rating == null && showRateButton) {
    return (
      <Button variant="primary" onClick={onRate}>
        Rate Carrier
      </Button>
    );
  }

  if (broker_records.carrier_rating?.rating == null && !showRateButton) {
    return null;
  }

  return (
    <Inline horizontalAlign="left" verticalAlign="center" space="xxsmall">
      <TextBox color="secondary">Your rating</TextBox>
      <Box width="fit-content">
        {broker_records.carrier_rating?.rating === 'POSITIVE' ? (
          <IconWrapper data-variant="success">
            <ThumbUp color="inherit" style={{ fontSize: '16px' }} />
          </IconWrapper>
        ) : (
          <IconWrapper data-variant="error">
            <ThumbDown color="inherit" style={{ fontSize: '16px' }} />
          </IconWrapper>
        )}
      </Box>
      <IconButton onClick={onRate} style={{ padding: '4px' }}>
        <Edit style={{ fontSize: '16px' }} color="action" />
      </IconButton>
      {broker_records.carrier_rating?.rating === 'NEGATIVE' && (
        <IconButton
          onClick={() => {
            onRatingDelete();
          }}
          style={{ padding: '4px' }}
        >
          <Delete style={{ fontSize: '16px' }} color="action" />
        </IconButton>
      )}
    </Inline>
  );
}

interface CarrierProfileHeaderProps {
  carrier: CarrierFullInfo;
  onRate: () => void;
  onEditRecords: () => void;
  onTabChange: (tab: string) => void;
  onRatingDelete: () => void;
}

export function CarrierProfileHeader({
  carrier,
  onRate,
  onTabChange,
  onEditRecords,
  onRatingDelete,
}: CarrierProfileHeaderProps) {
  const { broker_records } = carrier;
  const platform = useResponsiveValue('mobile', 'desktop');

  const isInsuranceExpired = isCarrierInsuranceExpired(
    broker_records.insurance_expires_at,
  );

  const isCarrierEligibility = useFeatureToggle(
    'payments.expedited.pay.carrier.eligibility.access.ui',
  );
  const isCanExpeditePaymentTag =
    isCarrierEligibility && broker_records.can_expedite_payment;

  const carrierJoinedDate =
    carrier.joined_since && new Date(carrier.joined_since);

  const { isAdvancedTier } = useProductTiers();

  return (
    <Stack space="small">
      <Columns collapseBelow="tablet" space="xsmall">
        <Column width="content">
          <AvatarButton src={carrier.logo || undefined} size="large">
            {getInitials(carrier.name)}
          </AvatarButton>
        </Column>

        <Column>
          <Stack>
            <Stack space="xsmall">
              <Inline verticalAlign="center">
                <Typography variant="h1" display="inline">
                  {carrier.name}
                </Typography>

                {carrier.is_verified ? <VerifiedTag /> : <UnverifiedTag />}
              </Inline>

              <Inline verticalAlign="center" space="xxsmall">
                {carrierJoinedDate && (
                  <>
                    <AccessTime
                      fontSize="small"
                      style={{ color: Color.Grey100 }}
                    />
                    <Typography color="textSecondary">
                      Joined Super in {carrierJoinedDate.getFullYear()}
                    </Typography>
                  </>
                )}

                {!!carrier.in_business_since && (
                  <Typography color="textSecondary">
                    {carrierJoinedDate && ' · '} In business since{' '}
                    {carrier.in_business_since}
                  </Typography>
                )}
              </Inline>
            </Stack>

            {broker_records.in_blacklist && (
              <Inline>
                <LinkInternalRecords
                  onClick={onEditRecords}
                  status={
                    broker_records.in_blacklist_since &&
                    `Blocklisted on ${formatDate(
                      broker_records.in_blacklist_since,
                      { variant: 'Date' },
                    )}`
                  }
                >
                  <Tag variant="subtle" color="grey">
                    <IconBox>
                      <ErrorInfoIcon />
                    </IconBox>
                    Blocklisted
                  </Tag>
                </LinkInternalRecords>
              </Inline>
            )}

            {isAdvancedTier && (
              <Inline>
                {broker_records.approved && (
                  <LinkInternalRecords
                    onClick={onEditRecords}
                    status={
                      broker_records.approved_since &&
                      `Approved on ${formatDate(broker_records.approved_since, {
                        variant: 'Date',
                      })}`
                    }
                  >
                    <Tag variant="subtle" color="grey">
                      <IconBox>
                        <ApprovedIcon />
                      </IconBox>
                      Approved
                    </Tag>
                  </LinkInternalRecords>
                )}

                {broker_records.preferred && <CanInstantlyBookTag />}

                {isCanExpeditePaymentTag && <CanExpeditePaymentTag />}

                {broker_records.is_in_private_network &&
                  broker_records.private_network_groups && (
                    <PrivateNetworkTag
                      groups={broker_records.private_network_groups}
                    />
                  )}

                {broker_records.insurance_certificate_holder && (
                  <LinkInternalRecords
                    onClick={onEditRecords}
                    status={
                      broker_records.insurance_certificate_holder_since &&
                      `Marked as Certificate Holder on ${formatDate(
                        broker_records.insurance_certificate_holder_since,
                        { variant: 'Date' },
                      )}`
                    }
                  >
                    <Tag variant="subtle" color="grey">
                      <IconBox>
                        <CertificateIcon />
                      </IconBox>
                      Certificate Holder
                    </Tag>
                  </LinkInternalRecords>
                )}
                {isInsuranceExpired && <InsuranceExpiredTag />}
              </Inline>
            )}
          </Stack>
        </Column>

        {carrier.carrier_type === 'CTMS' &&
          (platform === 'mobile' ? (
            <Column>
              <Columns collapseBelow="tablet" space="xsmall" align="center">
                {carrier.rating_stats?.total_rating_count != null &&
                  carrier.rating_stats.overall_rating != null && (
                    <Column>
                      <Inline
                        space="xxsmall"
                        verticalAlign="center"
                        aria-label="rating-stats"
                      >
                        <ThumbUp color="action" fontSize="small" />

                        <Link
                          href="#"
                          variant="h3"
                          onClick={() => {
                            onTabChange('ratings');
                          }}
                        >
                          {carrier.rating_stats.overall_rating}%
                        </Link>

                        <TextBox color="secondary">
                          ({carrier.rating_stats.total_rating_count})
                        </TextBox>
                      </Inline>
                    </Column>
                  )}
                <RateCarrierSection
                  carrier={carrier}
                  onRate={onRate}
                  onRatingDelete={onRatingDelete}
                />
              </Columns>
            </Column>
          ) : (
            <Column width="content">
              <Stack space="xsmall">
                {carrier.rating_stats?.total_rating_count != null &&
                  carrier.rating_stats.overall_rating != null && (
                    <Inline
                      space="xxsmall"
                      verticalAlign="center"
                      horizontalAlign="right"
                      aria-label="rating-stats"
                    >
                      <ThumbUp color="action" fontSize="small" />

                      <Link
                        href="#"
                        variant="h3"
                        onClick={() => {
                          onTabChange('ratings');
                        }}
                      >
                        {carrier.rating_stats.overall_rating}%
                      </Link>

                      <TextBox color="secondary">
                        ({carrier.rating_stats.total_rating_count})
                      </TextBox>
                    </Inline>
                  )}

                <RateCarrierSection
                  carrier={carrier}
                  onRate={onRate}
                  onRatingDelete={onRatingDelete}
                />
              </Stack>
            </Column>
          ))}
      </Columns>

      <Divider />

      <CarrierPerformanceIndicators
        carrierGuid={carrier.guid}
        isVerified={carrier.is_verified}
      />
    </Stack>
  );
}
