import { isObject } from 'lodash-es';
import {
  yupBoolean,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type OrderPaymentFlagDTO = InferType<typeof orderPaymentFlagSchema>;

export const orderPaymentFlagSchema = yupObject({
  order_id: yupNumber().nullable(false),
  can_superpay_now: yupBoolean().nullable(false),
  can_be_resumed: yupBoolean().nullable(false),
  can_be_initiated_now: yupBoolean().nullable(false),
  can_revise_price: yupBoolean().nullable(false),
  can_mark_as_delivery_verified: yupBoolean().nullable(false),
  can_unmark_as_delivery_verified: yupBoolean().nullable(false),
  can_hold_payment: yupBoolean().nullable(false),
  order_guid: yupString(),
  can_schedule: yupBoolean().nullable(false),
  can_edit: yupBoolean().nullable(false),
  can_cancel: yupBoolean().nullable(false),
  can_increase_price: yupBoolean().nullable(false),
});

export function isOrderPaymentFlags(
  paymentFlags: unknown,
): paymentFlags is OrderPaymentFlagDTO {
  return (
    !!paymentFlags &&
    isObject(paymentFlags) &&
    'order_id' in paymentFlags &&
    'order_guid' in paymentFlags &&
    'can_be_resumed' in paymentFlags &&
    'can_be_initiated_now' in paymentFlags &&
    'can_revise_price' in paymentFlags &&
    'can_mark_as_delivery_verified' in paymentFlags &&
    'can_unmark_as_delivery_verified' in paymentFlags &&
    'can_hold_payment' in paymentFlags &&
    'can_schedule' in paymentFlags &&
    'can_edit' in paymentFlags &&
    'can_cancel' in paymentFlags
  );
}
