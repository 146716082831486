import { Card, CardContent, Link, Typography } from '@material-ui/core';
import { ErrorOutlined, Mail } from '@material-ui/icons';
import { FormikCheckboxField } from '@superdispatch/forms';
import {
  Button,
  Color,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { StyledAlert } from 'notification-settings/NotificationSettingsPage';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { NotificationSettingsDTO } from '../data/NotificationSettingsDTO';

export function PersonalNotifications({
  notificationSettings,
  subscribeEmail,
}: {
  notificationSettings?: NotificationSettingsDTO;
  subscribeEmail: (email: string) => void;
}) {
  const { showCustomerDialog } = useCustomerSupport();

  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Typography variant="h3">Personal Notifications</Typography>
          <Stack space="large">
            {notificationSettings?.personal_email && (
              <Stack>
                <Typography color="textSecondary">Updates sent to:</Typography>

                <Columns space="xsmall" collapseBelow="tablet">
                  <Column width="content">
                    <DescriptionItem icon={<Mail />}>
                      <Typography component="span" color="textPrimary">
                        {notificationSettings.personal_email || 'No email'}
                      </Typography>
                    </DescriptionItem>
                  </Column>
                  <Column>
                    <Inline>
                      <Typography color="textSecondary">
                        To change, contact
                        <Typography component="span" color="textPrimary">
                          {' '}
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              showCustomerDialog();
                            }}
                          >
                            support
                          </Link>
                          .
                        </Typography>
                      </Typography>
                    </Inline>
                  </Column>
                </Columns>
              </Stack>
            )}

            <Stack space="xxsmall" aria-label="order updates">
              <Inline verticalAlign="center">
                <Typography variant="h4">
                  Updates on orders assigned to me{' '}
                </Typography>
                <Link
                  href="https://support.superdispatch.com/en/articles/10087754-how-to-use-the-assign-dispatcher-and-assign-salesperson-features-in-shipper-tms"
                  target="_blank"
                  rel="noreferrer"
                >
                  How to Assign Orders
                </Link>
              </Inline>

              <Columns space="large" collapseBelow="desktop">
                <Column width="fluid">
                  <Typography
                    color="textSecondary"
                    style={{ maxWidth: '423px' }}
                  >
                    Load offers, carrier requests, offer cancellation, bookings,
                    pickup/delivery updates and modifications, as well as
                    customer invoices and BOLs.
                  </Typography>
                </Column>
                <Column width={['fluid', '1/4']}>
                  <Columns space="small" collapseBelow="desktop">
                    <Column>
                      <FormikCheckboxField
                        label="Email"
                        name="is_personal_email_notifications_enabled"
                        disabled={
                          !notificationSettings?.personal_email ||
                          notificationSettings.is_personal_email_unsubscribed
                        }
                      />
                    </Column>
                    <Column>
                      <FormikCheckboxField
                        label="Push"
                        name="is_personal_push_notifications_enabled"
                      />
                    </Column>
                  </Columns>
                </Column>
              </Columns>
            </Stack>
            {notificationSettings?.is_personal_email_unsubscribed &&
              notificationSettings.personal_email && (
                <StyledAlert severity="error" icon={<ErrorOutlined />}>
                  <Inline verticalAlign="center" space="small">
                    <Typography>Email has been unsubscribed.</Typography>
                    <Button
                      size="small"
                      style={{ background: Color.White }}
                      onClick={() => {
                        subscribeEmail(notificationSettings.personal_email);
                      }}
                    >
                      Subscribe
                    </Button>
                  </Inline>
                </StyledAlert>
              )}

            {!notificationSettings?.personal_email && (
              <StyledAlert severity="error" icon={<ErrorOutlined />}>
                <Inline verticalAlign="center" space="small">
                  <Typography>Email has not been set.</Typography>
                  <Button
                    size="small"
                    style={{ background: Color.White }}
                    onClick={() => {
                      showCustomerDialog();
                    }}
                  >
                    Contact Support
                  </Button>
                </Inline>
              </StyledAlert>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
