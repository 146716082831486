import { plainToClass } from 'class-transformer';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { useAPIQuery } from 'shared/api/APIQuery';
import { useOrderCountsCache } from 'shared/api/OrderCountAPI';
import { Offer } from 'shared/types/offer';
import Order, { OrderRequestCount, RequestedOrder } from 'shared/types/order';
import { OrderRequestDTO } from './dto/OrderRequestDTO';
import { OrderRequestsParamsDTO } from './dto/OrderRequestsParamsDTO';
import { useOrderCache } from './OrderAPI';

type OrderRequestCountUpdater =
  | OrderRequestCount
  | ((prev: OrderRequestCount | undefined) => OrderRequestCount);

export function useOrderRequestCountCache() {
  const client = useQueryClient();
  return useMemo(() => {
    const setOrderRequestCountData = (
      guid: string,
      data: OrderRequestCountUpdater,
    ) => {
      client.setQueryData<OrderRequestCount>(
        ['orders', 'requests/count', { guid }],
        data,
      );
    };

    return { setOrderRequestCountData };
  }, [client]);
}

export function useRequestedOrdersAPI({
  sort,
  size,
  page = 0,
}: OrderRequestsParamsDTO) {
  const { requestPage } = useAPI();
  const { setOrderRequestCountData } = useOrderRequestCountCache();

  return useAPIPageQuery<RequestedOrder>(
    ['orders', 'requests/count_by_order', { sort, page, size }],
    () =>
      requestPage(
        '/internal/orders/requests/count_by_order{?sort,page,size}',
        (responseData) => responseData as RequestedOrder,
        { sort, page, size },
      ),
    {
      onSuccess: ({ objects = [] }) => {
        objects.forEach(
          ({ order, load_request_count, has_new_load_request }) => {
            setOrderRequestCountData(order.guid, {
              load_request_count,
              has_new_load_request,
            });
          },
        );
      },
    },
  );
}

function useSearchRequestedOrdersAPI({
  sort,
  size,
  page = 0,
  enabled,
  ...filters
}: OrderRequestsParamsDTO & { enabled: boolean }) {
  const { requestPage } = useAPI();
  const { setOrderRequestCountData } = useOrderRequestCountCache();

  return useAPIPageQuery<RequestedOrder>(
    ['orders', 'requests/count_by_order', { sort, page, size, filters }],
    () =>
      requestPage(
        'POST /internal/orders/requests/count_by_order/search{?sort,page,size}',
        (responseData) => responseData as RequestedOrder,
        {
          sort,
          page,
          size,
          json: filters,
          enabled,
        },
      ),
    {
      onSuccess: ({ objects = [] }) => {
        objects.forEach(
          ({ order, load_request_count, has_new_load_request }) => {
            setOrderRequestCountData(order.guid, {
              load_request_count,
              has_new_load_request,
            });
          },
        );
      },
    },
  );
}

export function useRequestedOrders({
  sort,
  size,
  page = 0,
  order_guid,
  ...filters
}: OrderRequestsParamsDTO) {
  const isSearch = Object.values(filters).filter(Boolean).length > 0;

  const { data: requestedOrders, refetch: refetchRequestedOrders } =
    useRequestedOrdersAPI({ sort, size, page });
  const { data: requestedSearchOrders, refetch: refetchSearchOrders } =
    useSearchRequestedOrdersAPI({
      sort,
      size,
      page,
      ...filters,
      enabled: isSearch,
    });

  return isSearch
    ? {
        data: requestedSearchOrders,
        refetch: refetchSearchOrders,
      }
    : {
        data: requestedOrders,
        refetch: refetchRequestedOrders,
      };
}

export function useOrderRequestCount(
  guid: string | undefined,
  revalidate = true,
) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['orders', 'requests/count', { guid }],
    () =>
      requestResource(
        '/internal/orders/{guid}/requests/count',
        (data) => data as OrderRequestCount,
        { guid },
      ),
    {
      enabled: !!guid,
      refetchOnMount: revalidate,
      refetchOnWindowFocus: revalidate,
    },
  );
}

export function useOrderRequests(guid?: string) {
  const { requestPage } = useAPI();

  return useAPIPageQuery(
    ['orders', 'requests', { guid }],
    () =>
      requestPage(
        '/internal/orders/{guid}/requests',
        (data) => plainToClass(OrderRequestDTO, data),
        { guid },
      ),
    { enabled: !!guid },
  );
}

export function useOrderRequestsAPI() {
  const { request, requestResource } = useAPI();
  const { setOrderRequestCountData } = useOrderRequestCountCache();
  const { invalidateOrderCounts } = useOrderCountsCache();
  const { invalidateOrder } = useOrderCache();

  return useMemo(
    () => ({
      acceptRequest: (
        guid: string,
        requestGuid: string,
        hasSuperPayTerms?: boolean,
      ) =>
        requestResource(
          'PUT /internal/orders/{guid}/requests/{requestGuid}/accept{?is_superpay_terms}',
          (data) => data as Order,
          { guid, requestGuid, is_superpay_terms: hasSuperPayTerms },
        ),
      sendCounterOffer: (guid: string, requestGuid: string, price: number) =>
        requestResource(
          'PUT /internal/orders/{guid}/requests/{requestGuid}/counter_offer',
          (data) => data as Order,
          { guid, requestGuid, json: { price } },
        ),
      cancelRequestCounterOffer: (orderGuid: string, requestGuid: string) =>
        requestResource(
          'PUT /internal/orders/{orderGuid}/requests/{requestGuid}/counter_offer/cancel',
          (data) => data as Offer,
          { orderGuid, requestGuid },
        ).then((offer) => {
          void invalidateOrder(orderGuid);
          return offer;
        }),
      cancelOffer: (orderId: number, offerId: number) =>
        requestResource(
          'PUT /internal/orders/{orderId}/offers/{offerId}/cancel',
          (data) => data as Offer,
          { orderId, offerId },
        ),
      declineRequest: (
        guid: string,
        requestGuid: string,
        declineReason: string | undefined,
      ) =>
        requestResource(
          'PUT /internal/orders/{guid}/requests/{requestGuid}/decline',
          (data) => data as Order,
          {
            guid,
            requestGuid,
            json: declineReason ? { decline_reason: declineReason } : undefined,
          },
        ),
      markRequestAsRead: (guid: string) =>
        request('PUT /internal/orders/{guid}/requests/mark_as_read', {
          guid,
        }).then(() => {
          setOrderRequestCountData(
            guid,
            (prev: OrderRequestCount | undefined) => ({
              has_new_load_request: false,
              load_request_count: prev?.load_request_count ?? 0,
            }),
          );

          void invalidateOrderCounts();
        }),
    }),
    [
      request,
      requestResource,
      setOrderRequestCountData,
      invalidateOrderCounts,
      invalidateOrder,
    ],
  );
}
