import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { NumberField, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { PricePerMile } from 'core/PricePerMile';
import { Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { OrderRequestDTO } from '../../data/dto/OrderRequestDTO';
import { useOrderActivityCache } from '../../data/OrderAPI';
import { useOrderRequestsAPI } from '../../data/OrderRequestsAPI';
import { usePriceNegotiation } from '../../data/PriceNegotiationAPI';
import { useAccessRestrictedState } from '../actions/AccessRestrictedDialog';
import { trackOderActionEvent } from '../actions/OrderActionsAnalytics';
import { PricingRecommendationCounterOffer } from '../pricing-insights/PricingRecommendationCounterOffer';
import { LimitedExperienceDialog } from './LimitedExperienceDialog';
import { useCountOrderRequests } from './OrderRequestsCount';

type ActionType = 'accept' | 'decline' | 'cancel' | 'counter_offer';

export interface OrderRequestCardActionsProps {
  order: Order;
  request: OrderRequestDTO;
  hasPrevCounterOffer: boolean;
  isSuperPay: boolean;
  counterOfferPrice?: number;
  setCounterOfferPrice: (price: number) => void;
  onSubmitSuccess: (order: Order) => void;
}

export function OrderRequestActions({
  order,
  request,
  onSubmitSuccess,
  hasPrevCounterOffer,
  isSuperPay: isSuperPayProp,
  counterOfferPrice,
  setCounterOfferPrice,
}: OrderRequestCardActionsProps) {
  const { user } = useUserState();
  const { data: priceNegotiation } = usePriceNegotiation(order);
  const { withAccessRestriction } = useAccessRestrictedState();
  const { invalidateOrderActivity } = useOrderActivityCache();
  const countRequests = useCountOrderRequests(order.guid);
  const { addSnackbar } = useSnackbarStack();
  const {
    acceptRequest,
    declineRequest,
    cancelRequestCounterOffer,
    cancelOffer,
    sendCounterOffer,
  } = useOrderRequestsAPI();
  const [action, setAction] = useState<ActionType>();
  const [isSuperPay, setSuperPay] = useState<boolean>(isSuperPayProp);
  const [limitedExperienceAction, setLimitedExperienceAction] =
    useState<ActionType>();
  const shouldShowLimitedExperienceDialog =
    request.has_limited_experience && !request.broker_records?.approved;

  const form = useFormikEnhanced<{ declineReason: string }, Order>({
    key: action,
    initialValues: { declineReason: '' },
    onSubmit(values) {
      switch (action) {
        case 'accept':
          return acceptRequest(order.guid, request.guid, isSuperPay);
        case 'decline':
          return declineRequest(order.guid, request.guid, values.declineReason);
        case 'counter_offer':
          if (typeof counterOfferPrice !== 'number') {
            throw new Error('Price should be set properly');
          }
          return sendCounterOffer(order.guid, request.guid, counterOfferPrice);
        case 'cancel':
          if (request.status === 'COUNTER_OFFER_SENT') {
            return cancelRequestCounterOffer(order.guid, request.guid).then(
              () => order,
            );
          }

          if (order.active_offer) {
            return cancelOffer(order.id, order.active_offer.id).then(
              (offer) => ({
                ...order,
                status: 'canceled',
                active_offer: offer,
                vehicles: order.vehicles?.map((x) => ({
                  ...x,
                  status: 'canceled',
                })),
              }),
            );
          }

          return Promise.reject(new Error('Active offer not found'));
      }

      return Promise.reject(new Error('Unknown action'));
    },
    onSubmitSuccess(response) {
      void invalidateOrderActivity(order.id);
      if (isSuperPay) {
        trackOderActionEvent({
          name: 'Method Changed to SuperPay',
          source: 'Order Requests',
        });
      }

      trackOderActionEvent({
        name:
          action === 'accept'
            ? '[STMS] Sent Load Offer'
            : action === 'counter_offer'
            ? '[STMS] Counter Offer Sent'
            : action === 'cancel'
            ? '[STMS] Canceled Load Offer'
            : 'Shipper Declined Request',
        order,
        user,
        request,
        priceNegotiation,
        offer: response.active_offer,
        source: 'Order Requests',
        carrierSource: 'carrier_request',
        carrier: {
          guid: request.carrier_guid,
          rating_stats: request.carrier_rating_stats,
        },
        ...countRequests(),
      });
      if (action === 'accept') {
        addSnackbar('Offer sent to Carrier for review.', {
          variant: 'success',
        });
      } else if (action === 'counter_offer') {
        addSnackbar('Counter Offer sent to Carrier for review.', {
          variant: 'success',
        });
      } else if (action !== 'cancel') {
        addSnackbar('Request declined.', {
          variant: 'success',
        });
      }

      setAction(undefined);
      onSubmitSuccess(response);
    },
    onSubmitFailure(error) {
      setAction(undefined);
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const { values, handleSubmit } = form;

  useEffect(() => {
    if (
      action === 'accept' ||
      action === 'cancel' ||
      action === 'counter_offer'
    ) {
      handleSubmit();
    }
  }, [action, handleSubmit]);

  function getButtonProps(type: ActionType) {
    return {
      onClick: () => {
        if (type === 'accept') {
          withAccessRestriction(() => {
            if (shouldShowLimitedExperienceDialog) {
              setLimitedExperienceAction(type);
              return;
            }
            setAction(type);
          });
          return;
        }

        if (type === 'counter_offer' && shouldShowLimitedExperienceDialog) {
          setLimitedExperienceAction(type);
          return;
        }

        setAction(type);
      },
      disabled: form.isSubmitting && type !== action,
      isLoading: form.isSubmitting && type === action,
    };
  }

  function handleClose() {
    setAction(undefined);
  }

  const requestPrice = request.price;
  const changedAmount = Number(requestPrice) - Number(order.price);
  const isRequestPending = request.status === 'REQUEST_PENDING';

  return (
    <Stack space="small">
      {isRequestPending ? (
        <Stack space="small">
          <>
            {order.price !== request.price && (
              <NumberField
                value={counterOfferPrice}
                fullWidth={true}
                onChange={(event) => {
                  setCounterOfferPrice(Number(event.target.value));
                  setSuperPay(false);
                }}
                inputProps={{
                  decimalScale: 2,
                }}
                data-intercom-target="counter offer field"
                placeholder="Counter offer"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {counterOfferPrice ? (
                        <PricePerMile price={counterOfferPrice} order={order} />
                      ) : (
                        '$0/mi'
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {counterOfferPrice != null && (
              <PricingRecommendationCounterOffer
                order={order}
                onPriceClick={setCounterOfferPrice}
                counterOfferPrice={counterOfferPrice}
                source="Order Requests Drawer"
              />
            )}
          </>

          <Stack space="xxsmall">
            {counterOfferPrice != null ? (
              <Button
                variant="primary"
                fullWidth={true}
                data-intercom-target="make counter offer"
                {...getButtonProps('counter_offer')}
              >
                Make Counter Offer
              </Button>
            ) : changedAmount ? (
              <Button
                variant="primary"
                fullWidth={true}
                {...getButtonProps('accept')}
              >
                Accept Bid
              </Button>
            ) : (
              <Button
                variant="primary"
                fullWidth={true}
                {...getButtonProps('accept')}
              >
                Accept Request
              </Button>
            )}

            <Button
              fullWidth={true}
              variant="neutral"
              {...getButtonProps('decline')}
            >
              Decline
            </Button>

            {hasPrevCounterOffer && counterOfferPrice != null && (
              <TextBox align="right" color="secondary" variant="caption">
                Previous counter offer will be canceled
              </TextBox>
            )}
          </Stack>
        </Stack>
      ) : request.status === 'OFFER_SENT' ||
        request.status === 'COUNTER_OFFER_SENT' ? (
        <Button fullWidth={true} {...getButtonProps('cancel')}>
          {request.status === 'COUNTER_OFFER_SENT'
            ? 'Cancel Counter Offer'
            : 'Cancel Offer'}
        </Button>
      ) : null}

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={action === 'decline' && !form.isSubmitting}
        onClose={form.isSubmitting ? undefined : handleClose}
      >
        <FormikProvider value={form}>
          <Form>
            <DialogTitle>Decline Reason</DialogTitle>
            <DialogContent>
              <FormikTextField
                multiline={true}
                fullWidth={true}
                name="declineReason"
                placeholder='Giving a reason to decline can help the carrier to improve the counter offer. &#10;To skip without a reason click "Send".'
                inputProps={{ style: { resize: 'none' }, maxLength: 120 }}
                InputProps={{ rows: 4, rowsMax: 8 }}
                label={
                  <TextBox color="secondary" align="right">
                    {`${values.declineReason.length || 0} of 120`}
                  </TextBox>
                }
              />
            </DialogContent>

            <DialogActions>
              <Button
                variant="neutral"
                onClick={handleClose}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={form.isSubmitting}
              >
                Send
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>

      <LimitedExperienceDialog
        isOpen={!!limitedExperienceAction}
        onConfirm={() => {
          setLimitedExperienceAction(undefined);
          setAction(limitedExperienceAction);
        }}
        onCancel={() => {
          setLimitedExperienceAction(undefined);
        }}
      />
    </Stack>
  );
}
