import { Link, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { formatDate } from '@superdispatch/dates';
import { Inline, Stack } from '@superdispatch/ui';
import { Alert } from '@superdispatch/ui-lab';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import {
  useBillingStatus,
  useSubscriptionDetails,
} from 'shared/errors/paywall/data/PaywallAPI';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';

export function SubscriptionAlerts() {
  return (
    <>
      <InTrialCancelledAlert />
      <NonTrialCancelledAlert />
      <SubscriptionPaymentFailedAlert />
    </>
  );
}

function InTrialCancelledAlert() {
  const { data: subscriptionStatus } = useSubscriptionDetails();

  const status = subscriptionStatus?.chargebee.status;

  const isStatusInTrial = status === 'IN_TRIAL';
  const isStatusCancelled = status === 'CANCELED';

  const cancelledAt = subscriptionStatus?.chargebee.cancelled_at;

  const trialEnd = subscriptionStatus?.chargebee.trial_end;

  if (!cancelledAt || (!isStatusInTrial && !(isStatusCancelled && !trialEnd))) {
    return null;
  }

  const formattedCanceledDate = formatDate(cancelledAt, {
    variant: 'DateTime',
  });

  return (
    <SubscriptionAlert title="Your trial is canceled">
      {isStatusInTrial
        ? `Your trial will be canceled on ${formattedCanceledDate}. You will have access to all features until this date.`
        : `The cancellation took effect on ${formattedCanceledDate}.`}
    </SubscriptionAlert>
  );
}

function NonTrialCancelledAlert() {
  const { data: subscriptionStatus } = useSubscriptionDetails();

  const status = subscriptionStatus?.chargebee.status;

  const isStatusNonRenewing = status === 'NON_RENEWING';
  const isStatusCancelled = status === 'CANCELED';

  const activatedAt = !!subscriptionStatus?.chargebee.activated_at;

  if (!activatedAt || (!isStatusNonRenewing && !isStatusCancelled)) {
    return null;
  }

  function getContent() {
    const canceledAt = subscriptionStatus?.chargebee.cancelled_at;
    const formattedCanceledDate = formatDate(canceledAt, {
      variant: 'DateTime',
    });

    if (isStatusNonRenewing) {
      return `Your subscription will be canceled on ${formattedCanceledDate}. To regain access to premium
      features, you'll need to reactivate your subscription.`;
    }

    return `The cancellation took effect on ${formattedCanceledDate}.`;
  }

  return (
    <SubscriptionAlert title="Your Subscription is Inactive">
      {getContent()}
    </SubscriptionAlert>
  );
}

export function SubscriptionPaymentFailedAlert() {
  const { data: billingStatus } = useBillingStatus();
  const isPaymentDueEnabled = useFeatureToggle(
    'subscription-past-due-payment.enabled',
  );

  if (
    !isPaymentDueEnabled ||
    (billingStatus?.status !== 'PAYMENT_DUE' &&
      billingStatus?.status !== 'NOT_PAID')
  ) {
    return null;
  }

  return (
    <SubscriptionAlert title="Payment Required: Action Needed">
      Your recent payment attempt was unsuccessful. Please update your payment
      method to continue to access the Super Loadboard.
    </SubscriptionAlert>
  );
}

function SubscriptionAlert({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  const { showCustomerDialog } = useCustomerSupport();

  return (
    <Alert icon={<Info />} severity="critical">
      <Stack>
        <Typography variant="body1">{title}</Typography>
        <Typography>{children}</Typography>
        <Inline space="small">
          <Link
            onClick={(e) => {
              e.preventDefault();
              showCustomerDialog();
            }}
            component="button"
          >
            Contact Support
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://superdispatch.com/terms-of-service/"
          >
            Terms & Conditions
          </Link>
        </Inline>
      </Stack>
    </Alert>
  );
}
