import { ALL_PAYMENT_METHODS } from '@superdispatch/sdk';
import { Exclude, Expose, Transform } from 'class-transformer';
import { transformNil, TransformString } from 'shared/utils/TransformUtils';

function TransformPaymentMethod() {
  return Transform((value, _, transformationType) => {
    if (ALL_PAYMENT_METHODS.some((x) => x === value)) {
      return value;
    }
    return transformNil(transformationType);
  });
}

@Exclude()
export class MarkAsPaidPayloadDTO {
  @Expose()
  @TransformPaymentMethod()
  method?: string;

  @Expose() terms?: string;
  @Expose() amount?: number;
  @Expose() notes?: string;
  @Expose() sent_date?: string;
  @Expose() reference_number?: string;
}

@Exclude()
export class MarkAsPaidFormDTO extends MarkAsPaidPayloadDTO {
  @Expose()
  @TransformString()
  method = '';
}

@Exclude()
export class MarkAsCustomerPaidPayloadDTO {
  @Expose()
  @TransformPaymentMethod()
  method?: string;

  @Expose() amount?: number;
  @Expose() deposit?: number;
  @Expose() tariff?: number;
  @Expose() terms?: string;
  @Expose() notes?: string;
  @Expose() received_date?: string;
  @Expose() reference_number?: string;
}

@Exclude()
export class MarkAsCustomerPaidFormDTO extends MarkAsCustomerPaidPayloadDTO {
  @Expose()
  @TransformString()
  method = '';
}
