import { Check, Notifications, Send } from '@material-ui/icons';
import { ComponentType } from 'react';
import { DispatchToCarrierIcon } from 'shared/icons/DispatchToCarrierIcon';
import { InvoiceSendIcon } from 'shared/icons/InvoiceSendIcon';
import { OnboardingStepKey } from './OnboardingAPI';

export const bannerIconMap = {
  USER_CREATED_FIRST_ORDER: Send,
  USER_RECEIVED_FIRST_LOAD_REQUEST: Notifications,
  CARRIER_ACCEPTED_USERS_FIRST_LOAD_OFFER: Check,
  CARRIER_PICKED_UP_USERS_FIRST_LOAD: DispatchToCarrierIcon,
  CARRIER_DELIVERED_USERS_FIRST_LOAD: DispatchToCarrierIcon,
  CARRIER_INVOICED_USERS_FIRST_LOAD: InvoiceSendIcon,
} as unknown as Record<
  OnboardingStepKey,
  ComponentType<{ fontSize: 'inherit' }>
>;

export function getBannerIcon(stepKey: OnboardingStepKey) {
  return bannerIconMap[stepKey];
}
