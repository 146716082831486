import { ComponentType } from 'react';
import { CreateOrderDialog } from './CreateOrderDialog';
import { OnboardingStepKey } from './OnboardingAPI';
import { getBannerIcon } from './onboardingBannerIconMap';
import { getPopupImage } from './onboardingPopupImageMap';

export type OnboardingAction =
  | {
      type: 'NAVIGATE';
      to: string;
      shouldCompleteStep?: true;
    }
  | {
      type: 'POST_TO_SUPER_LOADBOARD';
      shouldCompleteStep?: true;
    }
  | {
      type: 'GET_HELP_CALENDLY';
      shouldCompleteStep?: true;
    };

export type PopupOnboardingAction =
  | OnboardingAction
  | {
      type: 'CLOSE_POPUP';
      shouldCompleteStep?: true;
    };

export type BannerOnboardingAction =
  | OnboardingAction
  | {
      type: 'TRIGGER_POPUP';
    }
  | {
      type: 'DISMISS_BANNER';
    };

export interface OnboardingBanner {
  IconComponent?: ComponentType<{ fontSize: 'inherit' }>;
  text: string;
  primaryActionText?: string;
  primaryAction?: BannerOnboardingAction;
  secondaryActionText?: string;
  secondaryAction?: BannerOnboardingAction;
}

export interface OnboardingPopupData {
  image: {
    src: string;
    width: string;
    alt: string;
  };
  overline: string;
  title: string;
  subtitle?: string;
  primaryActionText: string;
  primaryAction: PopupOnboardingAction;
  secondaryActionText?: string;
  secondaryAction?: PopupOnboardingAction;
}

export interface OnboardingPopup {
  /** Whether the popup is shown immediately when the current step matches */
  isImmediate?: boolean;
  Component?: OnboardingPopupComponent;
  data?: OnboardingPopupData;
}

export interface OnboardingPopupComponentProps {
  open: boolean;
  onClose: () => void;
  onAction?: (action: PopupOnboardingAction) => void;
}

export type OnboardingPopupComponent = (
  props: OnboardingPopupComponentProps,
) => JSX.Element;

export const onboardingMap: Record<
  OnboardingStepKey,
  {
    banner?: OnboardingBanner;
    popup?: OnboardingPopup;
  }
> = {
  USER_ONBOARDING_INITIATED: {
    popup: {
      isImmediate: true,
      Component: CreateOrderDialog,
    },
  },
  USER_CREATED_FIRST_ORDER: {
    banner: {
      IconComponent: getBannerIcon('USER_CREATED_FIRST_ORDER'),
      text: 'Your order is ready! Post now to start getting carrier requests.',
      primaryActionText: 'Post to Super Loadboard!',
      primaryAction: {
        type: 'POST_TO_SUPER_LOADBOARD',
        shouldCompleteStep: true,
      },
      secondaryActionText: 'Learn More',
      secondaryAction: {
        type: 'TRIGGER_POPUP',
      },
    },
    popup: {
      isImmediate: true,
      data: {
        image: getPopupImage('USER_CREATED_FIRST_ORDER'),
        overline: 'Almost There!',
        title: 'Post to Super Loadboard',
        subtitle:
          'Your order is ready! Post now to start getting carrier requests.',
        primaryActionText: 'Post Now!',
        primaryAction: {
          type: 'POST_TO_SUPER_LOADBOARD',
          shouldCompleteStep: true,
        },
        secondaryActionText: 'Do it later',
        secondaryAction: {
          type: 'CLOSE_POPUP',
        },
      },
    },
  },
  USER_SENT_FIRST_DIRECT_OFFER: {
    popup: {
      isImmediate: true,
      data: {
        image: getPopupImage('USER_SENT_FIRST_DIRECT_OFFER'),
        overline: 'Your first load offer has been sent.',
        title: 'Try Super Loadboard Next Time!',
        subtitle:
          'Posting to Super Loadboard boosts your chances of moving loads even faster.',
        primaryActionText: 'Got it',
        primaryAction: {
          type: 'CLOSE_POPUP',
          shouldCompleteStep: true,
        },
      },
    },
  },
  USER_RECEIVED_FIRST_LOAD_REQUEST: {
    banner: {
      IconComponent: getBannerIcon('USER_RECEIVED_FIRST_LOAD_REQUEST'),
      text: 'Carriers Want This Load',
      secondaryActionText: 'Learn More',
      secondaryAction: {
        type: 'TRIGGER_POPUP',
      },
      primaryActionText: 'View Requests',
      primaryAction: {
        type: 'NAVIGATE',
        to: '/orders/view/:orderGuid/load_requests',
      },
    },
    popup: {
      data: {
        image: getPopupImage('USER_RECEIVED_FIRST_LOAD_REQUEST'),
        overline: 'Carriers Want This Load!',
        title: 'See Carriers’ Requests',
        subtitle:
          'Review and accept requests quickly to get your load moved faster at the best price.',
        primaryActionText: 'View Requests',
        primaryAction: {
          type: 'NAVIGATE',
          to: '/orders/view/:orderGuid/load_requests',
        },
        secondaryActionText: 'Do It Later',
        secondaryAction: {
          type: 'CLOSE_POPUP',
        },
      },
    },
  },
  CARRIER_ACCEPTED_USERS_FIRST_LOAD_OFFER: {
    banner: {
      IconComponent: getBannerIcon('CARRIER_ACCEPTED_USERS_FIRST_LOAD_OFFER'),
      text: 'A Carrier Has Accepted Your Load',
      primaryActionText: 'Learn More',
      primaryAction: {
        type: 'TRIGGER_POPUP',
      },
      secondaryActionText: 'Dismiss',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
    popup: {
      data: {
        image: getPopupImage('CARRIER_ACCEPTED_USERS_FIRST_LOAD_OFFER'),
        overline: 'A Carrier Has Accepted Your Load',
        title:
          'Congratulations on successfully dispatching your first load with Super Dispatch!',
        subtitle:
          'Discover more trusted carriers by posting another load on Super Loadboard.',
        primaryActionText: 'Create New Order',
        primaryAction: {
          type: 'NAVIGATE',
          to: '/orders/create',
        },
        secondaryActionText: 'View Order',
        secondaryAction: {
          type: 'NAVIGATE',
          to: '/orders/view/:orderGuid',
        },
      },
    },
  },
  CARRIER_PICKED_UP_USERS_FIRST_LOAD: {
    banner: {
      IconComponent: getBannerIcon('CARRIER_PICKED_UP_USERS_FIRST_LOAD'),
      text: 'A Driver Picked Up Your Load',
      primaryActionText: 'Learn More',
      primaryAction: {
        type: 'TRIGGER_POPUP',
      },
      secondaryActionText: 'Dismiss',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
    popup: {
      data: {
        image: getPopupImage('CARRIER_PICKED_UP_USERS_FIRST_LOAD'),
        overline: 'A Driver Picked Up Your Load',
        title: 'Your Load is on the Move!',
        subtitle:
          'Receive pickup updates in your BOL, or create and post a new order on Super Loadboard.',
        primaryActionText: 'Create New Order',
        primaryAction: {
          type: 'NAVIGATE',
          to: '/orders/create',
        },
        secondaryActionText: 'View Order',
        secondaryAction: {
          type: 'NAVIGATE',
          to: '/orders/view/:orderGuid',
        },
      },
    },
  },
  CARRIER_DELIVERED_USERS_FIRST_LOAD: {
    banner: {
      IconComponent: getBannerIcon('CARRIER_DELIVERED_USERS_FIRST_LOAD'),
      text: 'Your First Load is Delivered!',
      primaryActionText: 'Learn More',
      primaryAction: {
        type: 'TRIGGER_POPUP',
      },
      secondaryActionText: 'Dismiss',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
    popup: {
      data: {
        image: getPopupImage('CARRIER_DELIVERED_USERS_FIRST_LOAD'),
        overline: 'Your First Load is Delivered!',
        title: 'Congrats! You’ve Moved Your First Load with Super Dispatch!',
        subtitle:
          'Find all load details in the online BOL, or create and post a new order to Super Loadboard.',
        primaryActionText: 'Create New Order',
        primaryAction: {
          type: 'NAVIGATE',
          to: '/orders/create',
        },
        secondaryActionText: 'View Order',
        secondaryAction: {
          type: 'NAVIGATE',
          to: '/orders/view/:orderGuid',
        },
      },
    },
  },
  CARRIER_INVOICED_USERS_FIRST_LOAD: {
    banner: {
      IconComponent: getBannerIcon('CARRIER_INVOICED_USERS_FIRST_LOAD'),
      text: 'Your First Invoice Arrived',
      primaryActionText: 'Learn More',
      primaryAction: {
        type: 'TRIGGER_POPUP',
      },
      secondaryActionText: 'Dismiss',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
    popup: {
      data: {
        image: getPopupImage('CARRIER_INVOICED_USERS_FIRST_LOAD'),
        overline: 'You Did it!',
        title: 'Your First Invoice Arrived',
        subtitle: `Complete the process by paying the carrier and updating your first load to "Paid" status.`,
        primaryActionText: 'View Order',
        primaryAction: {
          type: 'NAVIGATE',
          to: '/orders/view/:orderGuid',
        },
        secondaryActionText: 'Do It Later',
        secondaryAction: {
          type: 'CLOSE_POPUP',
        },
      },
    },
  },

  USER_DID_NOT_CREATE_OR_POST_ORDER_IN_7_DAYS: {
    banner: {
      text: 'You haven’t posted any loads yet. Need help? Talk to our team of experts.',
      primaryActionText: 'Get Help',
      primaryAction: {
        type: 'GET_HELP_CALENDLY',
      },
      secondaryActionText: 'No Thanks',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
  },

  USER_DID_NOT_ACCEPT_OR_DECLIDE_FIRST_LOAD_REQUEST: {
    banner: {
      text: 'Carrier requests are awaiting your approval. Need help? Talk to our team of experts.',
      primaryActionText: 'Get Help',
      primaryAction: {
        type: 'GET_HELP_CALENDLY',
      },
      secondaryActionText: 'No Thanks',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
  },

  CARRIER_ACCEPTED_OFFER_BUT_PICKUP_DATE_PASSED: {
    banner: {
      text: 'The pickup on your first load is delayed. Talk to our team of experts if you need assistance.',
      primaryActionText: 'Get Help',
      primaryAction: {
        type: 'GET_HELP_CALENDLY',
      },
      secondaryActionText: 'No Thanks',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
  },

  LOAD_WAS_PICKED_UP_BUT_DELIVERY_DATE_PASSED: {
    banner: {
      text: 'The delivery on your first load is delayed. Need help? Talk to our team of experts.',
      primaryActionText: 'Get Help',
      primaryAction: {
        type: 'GET_HELP_CALENDLY',
      },
      secondaryActionText: 'No Thanks',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
  },

  LOAD_WAS_INVOICED_BUT_IS_NOT_MOVED_TO_PAID: {
    banner: {
      text: 'Have you paid your first invoice? Update invoice status, now!',
      primaryActionText: 'Get Help',
      primaryAction: {
        type: 'GET_HELP_CALENDLY',
      },
      secondaryActionText: 'No Thanks',
      secondaryAction: {
        type: 'DISMISS_BANNER',
      },
    },
  },

  USER_POSTED_FIRST_ORDER: {},
  USER_RESPONDED_TO_FIRST_LOAD_REQUEST: {},
  SHIPPER_FIRST_SUPERPAY_PAYMENT_COMPLETED: {},
};
