import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  FormikCurrencyField,
  FormikDateField,
  FormikTextField,
} from '@superdispatch/forms';
import {
  ALL_PAYMENT_METHODS,
  formatPaymentMethod,
  formatPaymentTerm,
  OTHER_PAYMENT_TERMS,
} from '@superdispatch/sdk';
import { Stack, useUID } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { ChangeEvent, memo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { OrderFormValues } from './OrderForm';

// Excluding `superpay` from customer payment until the next iteration of Payments Integration
const CUSTOMER_PAYMENT_TERMS = OTHER_PAYMENT_TERMS.filter(
  (term) => term !== 'superpay',
);
const CUSTOMER_PAYMENT_METHODS = ALL_PAYMENT_METHODS.filter(
  (method) => method !== 'superpay',
);

export interface CustomerPaymentProps {
  customer_payment: OrderFormValues['customer_payment'];
  payment: OrderFormValues['payment'];
  onValueChange: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export const CustomerPayment: React.FC<CustomerPaymentProps> = memo(
  ({ customer_payment, payment, onValueChange }) => {
    const uid = useUID();
    const { user } = useUserState();

    const styleLabelText = (text: string) => (
      <Typography color="textPrimary">{text}</Typography>
    );

    const isDepositVisible =
      user?.order_form_settings.customer_payment.is_deposit_visible ||
      customer_payment?.deposit;

    const onPaymentTermChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      const nextValue = target.value;
      const prevValue = payment?.terms;

      if (!!prevValue && nextValue === 'superpay') {
        onValueChange('customer_payment.method', 'superpay');
      }
    };

    return (
      <Card aria-labelledby={uid}>
        <CardContent>
          <Stack space="small">
            <Typography id={uid} variant="h3">
              Customer Payment
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} sm={12} md={6} lg={isDepositVisible ? 4 : 6}>
                <FormikCurrencyField
                  name="customer_payment.tariff"
                  label={styleLabelText('Total Tariff')}
                  fullWidth={true}
                />
              </Grid>

              {isDepositVisible && (
                <Grid item={true} sm={12} md={6} lg={4}>
                  <FormikCurrencyField
                    name="customer_payment.deposit"
                    label={styleLabelText('Deposit')}
                    fullWidth={true}
                  />
                </Grid>
              )}

              <Grid item={true} sm={12} md={6} lg={isDepositVisible ? 4 : 6}>
                <FormikTextField
                  label={styleLabelText('Terms')}
                  select={true}
                  fullWidth={true}
                  onChange={onPaymentTermChange}
                  name="customer_payment.terms"
                >
                  {CUSTOMER_PAYMENT_TERMS.map((value) => (
                    <MenuItem key={value} value={value}>
                      {formatPaymentTerm(value)}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Grid>

              {(user?.order_form_settings.customer_payment.is_amount_visible ||
                customer_payment?.amount) && (
                <Grid item={true} sm={12} md={6}>
                  <FormikCurrencyField
                    name="customer_payment.amount"
                    label={styleLabelText('Paid Amount')}
                    fullWidth={true}
                  />
                </Grid>
              )}

              {(user?.order_form_settings.customer_payment.is_method_visible ||
                customer_payment?.method) && (
                <Grid item={true} sm={12} md={6}>
                  <FormikTextField
                    select={true}
                    fullWidth={true}
                    label={styleLabelText('Paid Method')}
                    name="customer_payment.method"
                  >
                    {CUSTOMER_PAYMENT_METHODS.map((value) => (
                      <MenuItem key={value} value={value}>
                        {formatPaymentMethod(value)}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Grid>
              )}

              {(user?.order_form_settings.customer_payment
                .is_reference_number_visible ||
                customer_payment?.reference_number) && (
                <Grid item={true} sm={12} md={6}>
                  <FormikTextField
                    name="customer_payment.reference_number"
                    label={styleLabelText('Reference Number')}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
              )}

              {(user?.order_form_settings.customer_payment
                .is_received_date_visible ||
                customer_payment?.received_date) && (
                <Grid item={true} sm={12} md={6}>
                  <FormikDateField
                    name="customer_payment.received_date"
                    fullWidth={true}
                    label={styleLabelText('Received Date')}
                  />
                </Grid>
              )}

              <Box p={1} width={1}>
                <FormikTextField
                  name="customer_payment.notes"
                  label={
                    <Box display="flex" justifyContent="space-between">
                      <Box>{styleLabelText('Notes')}</Box>
                      <TextBox color="secondary">
                        {`${
                          Number(customer_payment?.notes?.length) || 0
                        } of 2000`}
                      </TextBox>
                    </Box>
                  }
                  multiline={true}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 2000,
                  }}
                />
              </Box>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    );
  },
);

CustomerPayment.displayName = 'CustomerPayment';
