import { Typography } from '@material-ui/core';
import { Color, Stack, useUID } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { Circle1Icon } from 'shared/icons/circle-1';
import { Circle2Icon } from 'shared/icons/circle-2';
import { ForwardArrowCircledIcon } from 'shared/icons/ForwardArrowCircledIcon';
import styled from 'styled-components';
import complianceImg from '../assets/compliance-img.svg';
import phoneVerificationImg from '../assets/phone-verification-img.svg';
import {
  OrderActionsAnalyticsProperties,
  trackVerificationApplicationEvent,
} from '../data/VerificationAnalyticsEvents';
import { VerificationOverlay } from './VerificationOverlay';
import { VerificationSidebar } from './VerificationSidebar';
import { VerificationStatusTag } from './VerificationStatusTag';

const VerificationPendingBox = styled.div`
  display: flex;
  padding: 32px 32px 64px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const VerificationPendingSteps = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    gap: 16px;
  }
`;

const TextBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  max-width: 248px;
  max-height: 94px;
  flex: 1 0 0;
`;

const WhatsNextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const RecommendationBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const BoxesGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const RecommendationStepBox = styled.div`
  cursor: pointer;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${Color.Silver400};
`;

const RecommendationStepText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

const BlockWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const Divider = styled.div`
  border-left: 1px solid ${Color.Silver400};
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    border-left: none;
    border-bottom: 1px solid ${Color.Silver400};
    width: 100%;
  }
`;

function VerificationApplicationDialogContent() {
  return (
    <VerificationPendingBox>
      <WhatsNextBox>
        <Typography variant="h3">What happens now?</Typography>
        <VerificationPendingSteps>
          <BlockWrapper>
            <Circle1Icon />
            <TextBoxGroup>
              <Typography variant="body1">Compliance check</Typography>
              <Typography>
                Our team is currently reviewing your documentation.
              </Typography>
            </TextBoxGroup>
            <img
              src={complianceImg}
              alt="compliance"
              width="65px"
              height="64px"
            />
          </BlockWrapper>

          <Divider />

          <BlockWrapper>
            <Circle2Icon />
            <TextBoxGroup>
              <Typography variant="body1">Phone Verification</Typography>
              <Typography>
                A team member will call you at your chosen time.
              </Typography>
            </TextBoxGroup>
            <img
              src={phoneVerificationImg}
              alt="phone verification"
              width="65px"
              height="64px"
            />
          </BlockWrapper>
        </VerificationPendingSteps>
      </WhatsNextBox>

      <RecommendationBox>
        <Typography variant="h3">What to do in the meantime?</Typography>
        <BoxesGroup>
          <RecommendationStepBox
            onClick={() => {
              window.open('/profile', '_blank');
            }}
          >
            <RecommendationStepText>
              <TextBox variant="heading-4">Fill In Your Profile</TextBox>
              <TextBox variant="caption" color="secondary">
                Stand out and move cars faster
              </TextBox>
            </RecommendationStepText>
            <ForwardArrowCircledIcon fontSize="large" />
          </RecommendationStepBox>
          <RecommendationStepBox
            onClick={() => {
              window.open('/super-loadboard-settings', '_blank');
            }}
          >
            <RecommendationStepText>
              <TextBox variant="heading-4">Enable Instant Booking</TextBox>
              <TextBox variant="caption" color="secondary">
                Allow verified carriers to book loads faster
              </TextBox>
            </RecommendationStepText>
            <ForwardArrowCircledIcon fontSize="large" />
          </RecommendationStepBox>
          <RecommendationStepBox
            onClick={() => {
              window.open('/notification-settings', '_blank');
            }}
          >
            <RecommendationStepText>
              <TextBox variant="heading-4">Set Up Your Notifications</TextBox>
              <TextBox variant="caption" color="secondary">
                Never miss an update
              </TextBox>
            </RecommendationStepText>
            <ForwardArrowCircledIcon fontSize="large" />
          </RecommendationStepBox>
        </BoxesGroup>
      </RecommendationBox>
    </VerificationPendingBox>
  );
}

interface VerificationPendingDialogProps
  extends OrderActionsAnalyticsProperties {
  open: boolean;
  onClose: () => void;
}

export function VerificationPendingDialog({
  open,
  onClose,
  action,
  actionType,
}: VerificationPendingDialogProps) {
  const uid = useUID();

  useEffect(() => {
    if (open) {
      trackVerificationApplicationEvent({
        name: 'Shipper Opened Verification Pending Dialog',
      });
    }
  }, [open, action, actionType]);

  return (
    <VerificationOverlay
      aria-labelledby={uid}
      open={open}
      onClose={onClose}
      sidebar={
        <VerificationSidebar
          bottomText="*Verification may take up to two business days to complete."
          status="warning"
        >
          <Stack space="small">
            <Typography variant="h2">Your verification status:</Typography>
            <VerificationStatusTag status="Pending" />
          </Stack>
        </VerificationSidebar>
      }
    >
      <VerificationApplicationDialogContent />
    </VerificationOverlay>
  );
}
