import { useState } from 'react';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { VerificationApplicationCompany } from './VerificationApplicationCompany';
import { VerificationApplicationIdentification } from './VerificationApplicationIdentification';
import { VerificationApplicationInsurance } from './VerificationApplicationInsurance';
import { VerificationApplicationPhoneVerification } from './VerificationApplicationPhoneVerification';

interface VerificationApplicationStepsProps {
  onSubmitSuccess: () => void;
}

export function VerificationApplicationSteps({
  onSubmitSuccess,
}: VerificationApplicationStepsProps) {
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);

  const isPhoneVerificationEnabled = useFeatureToggle(
    'compliance.phone-verification.enabled',
  );

  return (
    <>
      {step === 1 && (
        <VerificationApplicationCompany
          onSubmitSuccess={() => {
            trackVerificationApplicationEvent({
              name: 'Shipper Continued to Identification Step',
            });
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <VerificationApplicationIdentification
          onGoBack={() => {
            setStep(1);
          }}
          onSubmitSuccess={() => {
            trackVerificationApplicationEvent({
              name: isPhoneVerificationEnabled
                ? 'Shipper Continued to Phone Verification Step'
                : 'Shipper Continued to Insurance Step',
            });
            setStep(isPhoneVerificationEnabled ? 3 : 4);
          }}
        />
      )}
      {step === 3 && (
        <VerificationApplicationPhoneVerification
          onGoBack={() => {
            setStep(2);
          }}
          onSubmitSuccess={() => {
            trackVerificationApplicationEvent({
              name: 'Shipper Continued to Insurance Step',
            });
            setStep(4);
          }}
        />
      )}
      {step === 4 && (
        <VerificationApplicationInsurance
          onGoBack={() => {
            setStep(isPhoneVerificationEnabled ? 3 : 2);
          }}
          onSubmitSuccess={() => {
            onSubmitSuccess();
            trackVerificationApplicationEvent({
              name: 'Shipper Submitted Verification Application',
            });
          }}
        />
      )}
    </>
  );
}
