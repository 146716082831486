import {
  Table,
  TableBody,
  TableCell,
  TableContainer as TableContainerMui,
  TableRow,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Color } from '@superdispatch/ui';
import {
  useOrderRequestCount,
  useOrderRequests,
  useOrderRequestsAPI,
} from 'orders/data/OrderRequestsAPI';
import { useEffect, useMemo } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderRequest } from './OrderRequest';
import { OrderRequestsHeader } from './OrderRequestsHeader';

const StyledTableCell = styled(TableCell)`
  padding: 32px 16px;
  border-bottom: 1px solid ${Color.Silver400};

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

const TableContainer = styled(TableContainerMui)`
  overflow-x: hidden;
`;

interface OrderRequestsListProps {
  order: Order;
  onSubmitSuccess: (order: Order) => void;
}

export function OrderRequestsList({
  order,
  onSubmitSuccess,
}: OrderRequestsListProps) {
  const { markRequestAsRead } = useOrderRequestsAPI();
  const { data: requests, refetch: refetchOrderRequests } = useOrderRequests(
    order.guid,
  );
  const { data: requestCount, refetch: refetchOrderRequestCount } =
    useOrderRequestCount(order.guid);

  const { handleSubmit } = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => markRequestAsRead(order.guid),
  });

  const notRespondedRequests = useMemo(() => {
    return requests?.objects.filter(
      (request) => request.status === 'REQUEST_PENDING',
    );
  }, [requests]);

  const respondedRequests = useMemo(() => {
    return requests?.objects.filter(
      (request) => request.status !== 'REQUEST_PENDING',
    );
  }, [requests]);

  const isAllRequestsResponded =
    respondedRequests?.length === requests?.objects.length;

  useEffect(() => {
    if (requestCount?.has_new_load_request) {
      handleSubmit();
    }
  }, [requestCount, handleSubmit]);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {!!notRespondedRequests?.length && (
            <>
              <OrderRequestsHeader
                order={order}
                showOrderDetails={true}
                title={`${notRespondedRequests.length} ${formatPlural(
                  notRespondedRequests.length,
                  'request',
                  'requests',
                )} waiting`}
              />
              {notRespondedRequests.map((request) => (
                <TableRow key={request.guid}>
                  <StyledTableCell aria-label="select carrier">
                    <OrderRequest
                      order={order}
                      request={request}
                      onSubmitSuccess={(requestedOrder) => {
                        void refetchOrderRequestCount();
                        void refetchOrderRequests();
                        onSubmitSuccess(requestedOrder);
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </>
          )}
          {!!respondedRequests?.length && (
            <>
              <OrderRequestsHeader
                order={order}
                showOrderDetails={isAllRequestsResponded}
                title={
                  isAllRequestsResponded
                    ? 'All request responses sent'
                    : `${respondedRequests.length} request ${formatPlural(
                        respondedRequests.length,
                        'response',
                        'responses',
                      )} sent`
                }
              />
              {respondedRequests.map((request) => (
                <TableRow key={request.guid}>
                  <StyledTableCell aria-label="select carrier">
                    <OrderRequest
                      order={order}
                      request={request}
                      onSubmitSuccess={(requestedOrder) => {
                        void refetchOrderRequestCount();
                        void refetchOrderRequests();
                        onSubmitSuccess(requestedOrder);
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
