import { CustomerType, PaymentMethod, PaymentTerm } from '@superdispatch/sdk';
import { ETAInformationDTO } from 'orders/data/dto/ETAInformationDTO';
import { DeliveryVerificationFailureReasonType } from 'shared/dto/Order/DeliveryVerificationDTO';
import { VehicleDTO } from '../dto/Order/VehicleDTO';
import { Attachment } from './attachment';
import { Offer } from './offer';
import { Step } from './step';

export interface CustomerInvoice {
  date?: string;
  number?: string;
  sent_date?: string;
  file_url?: string;
  guid?: string;
  url_token?: string;
}

export type TransportType = 'OPEN' | 'ENCLOSED' | 'DRIVEAWAY';

export type InspectionType = 'standard' | 'advanced' | 'aiag';

export type SuperPayStatus =
  | 'CREATED'
  | 'INITIALIZING'
  | 'CHARGE_INITIATED'
  | 'SUPER_PAY_CHARGED'
  | 'CHARGE_SCHEDULED'
  | 'PAYMENT_INITIATED'
  | 'PAYMENT_SCHEDULED'
  | 'PAID'
  | 'FAILED'
  | 'CHARGE_FAILED'
  | 'PAYMENT_FAILED'
  | 'CANCELED';

export const superPayOrderStatuses = [
  'superpay_delivery_verified',
  'superpay_charge_scheduled',
  'superpay_charged',
  'superpay_on_hold',
  'superpay_initiated',
  'superpay_paid',
  'superpay_failed',
] as const;

export type SuperPayOrderStatus = typeof superPayOrderStatuses[number];

export type OfferCancelReasons = '';

export type DeliveryVerificationStatus =
  | 'SUCCEED'
  | 'FAILED'
  | 'VERIFIED_MANUALLY';

export interface OrderPaymentSuperPay {
  amount: number | null;
  status: SuperPayStatus;
  is_on_hold: boolean;
  expected_at: string | null;
  scheduled_for: string | null;
  paid_at: string;
  on_hold_reasons?: DeliveryVerificationFailureReasonType[];
  custom_on_hold_reason?: string;
  is_manual_hold?: boolean;
  error_details?: string;
  expedited_pay_fee_amount?: number | null;
}

export interface OrderPayment {
  amount?: number;
  method?: PaymentMethod;
  notes?: string;
  reference_number?: string;
  sent_date?: string;
  terms?: PaymentTerm;
  super_pay: OrderPaymentSuperPay | null;
  superpay_canceled?: boolean;
}

export interface Order {
  id: number;
  changed_at: string;
  created_at: string;
  active_offer?: Offer;
  transport_type: TransportType;
  inspection_type: InspectionType;
  broker_fee?: number;
  attachments?: Attachment[];
  carrier_guid: string | null;
  is_brokers_order?: boolean;
  customer?: OrderContactDTO;
  customer_invoice: CustomerInvoice | null;
  customer_invoices?: CustomerInvoice[];
  customer_payment?: {
    amount?: number;
    deposit?: number;
    method?: PaymentMethod;
    notes?: string;
    reference_number?: string;
    received_date?: string;
    tariff?: number;
    terms?: PaymentTerm;
  };
  delivery?: Step;
  dispatcher_name: string;
  dispatcher_user_id: number | null;
  invoice?: {
    is_carrier_requested_superpay?: boolean;
    adjusted_invoice_date?: string;
    date?: string;
    number?: number;
    sent_date?: string;
    url?: string;
  };
  guid: string;
  number: string;
  payment?: OrderPayment;
  pickup?: Step;
  price?: number;
  price_per_km?: number;
  recommended_price?: number;
  sales_representative: string;
  status:
    | 'new'
    | 'canceled'
    | 'declined'
    | 'pending'
    | 'accepted'
    | 'picked_up'
    | 'delivered'
    | 'invoiced'
    | 'delivery_verified'
    | 'paid'
    | 'submitted'
    | 'scheduled'
    | 'completed'
    | 'order_canceled';
  tariff?: number;
  vehicles?: VehicleDTO[];
  tags: number[];
  distance_meters?: number;

  is_on_hold: boolean;
  is_active?: boolean;
  is_archived: boolean;
  is_flagged: boolean;
  is_status_changed_manually: boolean;
  is_posted_to_loadboard: boolean;
  is_posted_to_private_loadboard: boolean;
  private_network_groups?: string[];
  posted_to_loadboard_at: string | null;
  is_posted_to_centraldispatch: boolean;
  is_instantly_dispatched: boolean;
  is_load: boolean;
  is_demo: boolean;
  has_loads: boolean;
  has_legs?: boolean;
  is_offer_canceled_by_carrier?: boolean;
  is_canceled_by_customer?: boolean;
  can_be_instantly_booked: boolean | null;

  online_bol_url?: string;
  pdf_bol_url?: string;
  pdf_bol_url_with_template?: string;
  pdf_delivery_receipt_url?: string;
  dispatch_sheet_url?: string;

  instructions: string | null;
  loadboard_instructions: string | null;
  purchase_order_number?: string;
  offer_decline_reasons?: string;
  offer_decline_comment?: string;
  offer_cancel_comment?: string;
  offer_cancel_reasons?: string[];

  eta_information?: ETAInformationDTO;

  delivery_verification_status: DeliveryVerificationStatus | null;
}

export interface OrderContactDTO {
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  counterparty_guid?: string;
  counterparty_type?: 'CUSTOMER' | 'TERMINAL' | null;
  email?: string;
  phone?: string;
  business_type?: CustomerType;
  notes?: string;
  bill_the_delivery?: boolean;
  contact_name?: string | null;
  contact_email?: string | null;
  contact_phone?: string | null;
  contact_mobile_phone?: string | null;
  contact_title?: string | null;
  save_as_recent?: boolean;
  selected_from_recent?: boolean;
}

export interface OrderRequestCount {
  load_request_count: number;
  has_new_load_request: boolean;
}

export interface RequestedOrder extends OrderRequestCount {
  order: Order;
}

export default Order;
