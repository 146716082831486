import {
  Card,
  CardContent,
  FormControlLabel,
  Link,
  Switch,
  Typography,
} from '@material-ui/core';
import { Language } from '@material-ui/icons';
import {
  Button,
  Color,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { useState } from 'react';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import styled from 'styled-components';
import {
  useGeneralSettings,
  useGeneralSettingsAPI,
} from './data/GeneralSettingsAPI';
import { GeneralSettingsDTO } from './data/GeneralSettingsDTO';
import { DisableSingleSignOnDialog } from './DisableSingleSignOnDialog';
import { StyledBodyText } from './GeneralSettingsPage';

const StyledLanguageIcon = styled(Language)`
  color: ${Color.Dark100};
`;

function ContactSupportButton() {
  const { showCustomerDialog } = useCustomerSupport();
  return (
    <Button
      onClick={() => {
        showCustomerDialog();
      }}
    >
      Contact Support to Add Domain
    </Button>
  );
}

const SetupSSOButton = ({
  isLoading,
  onGeneratePortalLinkClick,
}: {
  isLoading: boolean;
  onGeneratePortalLinkClick: () => void;
}) => (
  <Button
    variant="contained"
    onClick={onGeneratePortalLinkClick}
    isLoading={isLoading}
  >
    Set Up SSO
  </Button>
);

const ManageSSOButton = ({
  isLoading,
  onGeneratePortalLinkClick,
}: {
  isLoading: boolean;
  onGeneratePortalLinkClick: () => void;
}) => (
  <Button onClick={onGeneratePortalLinkClick} isLoading={isLoading}>
    Manage SSO
  </Button>
);

const SSOSwitcher = ({
  checked,
  handleChange,
}: {
  checked: boolean;
  handleChange: (value: boolean) => void;
}) => (
  <FormControlLabel
    label=""
    labelPlacement="start"
    control={
      <Switch
        defaultChecked={checked}
        checked={checked}
        onChange={(event) => {
          handleChange(event.target.checked);
        }}
      />
    }
  />
);

const Domain = ({ domain }: { domain: string }) => (
  <Stack space="xsmall">
    <Typography color="textSecondary">Your domain:</Typography>

    <Inline verticalAlign="center" space="xxsmall">
      <StyledLanguageIcon fontSize="small" />
      <Typography>{domain}</Typography>
    </Inline>
  </Stack>
);

const ManageSSOContent = ({
  settings,
  onGeneratePortalLinkClick,
  isLoading,
}: ManageSSOContentProps) => (
  <Stack space="small">
    {settings?.domain && <Domain domain={settings.domain} />}

    {settings?.is_sso_connection_active ? (
      <ManageSSOButton
        onGeneratePortalLinkClick={onGeneratePortalLinkClick}
        isLoading={isLoading}
      />
    ) : (
      <SetupSSOButton
        onGeneratePortalLinkClick={onGeneratePortalLinkClick}
        isLoading={isLoading}
      />
    )}
  </Stack>
);

export function SingleSignOnSettingsCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: settings, refetch } = useGeneralSettings();
  const { getOrganizationPortalLink } = useGeneralSettingsAPI();
  const [isSSOEnabled, setIsSSOEnabled] = useState(
    Boolean(settings?.is_sso_enabled),
  );

  const onGeneratePortalLinkClick = () => {
    setIsLoading(true);

    void getOrganizationPortalLink().then((response) => {
      if (response.url) {
        setIsLoading(false);
        window.location.replace(response.url);
      }
    });
  };

  const onSSOStateChange = (value: boolean) => {
    setIsSSOEnabled(value);
    setDialogOpen(true);
  };

  return (
    <Card>
      <CardContent>
        <Stack space="xxsmall">
          <Stack space="small">
            <Columns align="center">
              <Column width="fluid">
                <Stack space="xxsmall">
                  <Typography variant="h4">Single Sign-On</Typography>
                  <Inline space="xxsmall">
                    <StyledBodyText>
                      Allows to use a simplified authentication method using
                      your corporate domain.
                    </StyledBodyText>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.superdispatch.com/en/articles/6221496-signing-on-with-single-sign-on-sso"
                    >
                      Learn More.
                    </Link>
                  </Inline>
                </Stack>
              </Column>

              {settings?.is_sso_connection_active && (
                <Column width="content">
                  <SSOSwitcher
                    checked={!!settings.is_sso_enabled}
                    handleChange={onSSOStateChange}
                  />
                </Column>
              )}
            </Columns>

            {settings?.is_sso_organization_created ? (
              <ManageSSOContent
                settings={settings}
                onGeneratePortalLinkClick={onGeneratePortalLinkClick}
                isLoading={isLoading}
              />
            ) : (
              <ContactSupportButton />
            )}
          </Stack>
        </Stack>

        <DisableSingleSignOnDialog
          open={dialogOpen}
          SSOState={isSSOEnabled}
          onSubmit={() => {
            void refetch();
          }}
          onClose={() => {
            setIsSSOEnabled(false);
            setDialogOpen(false);
          }}
        />
      </CardContent>
    </Card>
  );
}

interface ManageSSOContentProps {
  settings?: GeneralSettingsDTO;
  isLoading: boolean;
  onGeneratePortalLinkClick: () => void;
}
