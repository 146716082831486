import { Divider as MuiDivider, MenuItem } from '@material-ui/core';
import { FormikTextField, FormikTextFieldProps } from '@superdispatch/forms';
import {
  ACH_PAYMENT_METHODS,
  CASH_PAYMENT_METHODS,
  CHECK_PAYMENT_METHODS,
  CREDIT_CARD_PAYMENT_METHODS,
  ELECTRONIC_PAYMENT_METHODS,
  formatPaymentMethod,
  OTHER_PAYMENT_METHODS,
  PaymentMethod,
  SUPERPAY_PAYMENT_METHODS,
} from '@superdispatch/sdk';
import { useUserState } from 'shared/data/AppUserState';
import styled from 'styled-components';

const Divider = styled(MuiDivider)`
  margin: 8px 0;
`;

function getPaymentMethodGroups(excludeSuperPay?: boolean): PaymentMethod[][] {
  return [
    excludeSuperPay ? [] : SUPERPAY_PAYMENT_METHODS,
    CASH_PAYMENT_METHODS,
    CHECK_PAYMENT_METHODS,
    ACH_PAYMENT_METHODS,
    CREDIT_CARD_PAYMENT_METHODS,
    ELECTRONIC_PAYMENT_METHODS,
    OTHER_PAYMENT_METHODS,
  ];
}

export function getPaymentMethodOptions(excludeSuperPay?: boolean) {
  const paymentMethodsGroups = getPaymentMethodGroups(excludeSuperPay);
  return paymentMethodsGroups.reduce<JSX.Element[]>(
    (acc, methods, index) => [
      ...acc,
      ...methods.map((method) => (
        <MenuItem key={method} value={method}>
          {formatPaymentMethod(method)}
        </MenuItem>
      )),
      ...(index < paymentMethodsGroups.length - 1 && methods.length
        ? [<Divider key={`${index}-divider`} />]
        : []),
    ],
    [],
  );
}

export function FormikPaymentMethodField({
  excludeSuperPay: excludeSuperPayProps,
  ...formikTextFieldProps
}: { excludeSuperPay?: boolean } & FormikTextFieldProps) {
  const { user } = useUserState();
  const excludeSuperPay =
    excludeSuperPayProps || !user?.shipper.is_super_pay_enabled;

  return (
    <FormikTextField {...formikTextFieldProps} select={true}>
      {getPaymentMethodOptions(excludeSuperPay)}
    </FormikTextField>
  );
}
