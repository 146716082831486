import { useFeatureToggle } from 'shared/data/FeatureToggle';
import {
  hideIntercomButton,
  showIntercomButton,
  showIntercomDialog,
  showNewMessage,
} from './intercom/Intercom';
import { useZendesk } from './zendesk/zendesk';

export function useCustomerSupport() {
  const zendeskEnabled = useFeatureToggle('stms.zendesk-widget.enabled');

  const { showZendeskButton, hideZendeskButton, showZendeskDialog } =
    useZendesk(zendeskEnabled);

  const showCustomerButton = () => {
    if (zendeskEnabled) {
      hideIntercomButton();
      showZendeskButton();
    } else {
      hideZendeskButton();
      showIntercomButton();
    }
  };

  const hideCustomerButton = () => {
    if (zendeskEnabled) {
      hideZendeskButton();
    } else {
      hideIntercomButton();
    }
  };

  const hideCustomerButtons = () => {
    hideZendeskButton();
    hideIntercomButton();
  };

  const showCustomerDialog = (message?: string) => {
    if (zendeskEnabled) {
      showZendeskDialog();
    } else if (message) {
      showNewMessage(message);
    } else {
      showIntercomDialog();
    }
  };

  return {
    showCustomerButton,
    hideCustomerButton,
    showCustomerDialog,
    hideCustomerButtons,
  };
}
