import { useResponsiveValue } from '@superdispatch/ui';
import { ProgressStep, ProgressStepper } from 'shared/ui/ProgressStepper';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';

interface VerificationStepperProps {
  step: 1 | 2 | 3 | 4;
}

export function VerificationStepper({ step }: VerificationStepperProps) {
  const isMobile = useResponsiveValue(true, false);
  const isPhoneVerificationEnabled = useFeatureToggle(
    'compliance.phone-verification.enabled',
  );
  const numberOfSteps = isPhoneVerificationEnabled ? 4 : 3;

  function getStepTitle(stepNumber: number) {
    return isMobile
      ? `Step ${stepNumber} of ${numberOfSteps}`
      : `Step ${stepNumber}`;
  }

  return (
    <ProgressStepper progress={100 * (step / numberOfSteps)}>
      <ProgressStep
        title={getStepTitle(1)}
        subtitle="Your Company"
        isActive={step === 1}
        isCompleted={false}
      />
      <ProgressStep
        title={getStepTitle(2)}
        subtitle="Identification"
        isActive={step === 2}
        isCompleted={false}
      />
      {isPhoneVerificationEnabled ? (
        <>
          <ProgressStep
            title={getStepTitle(3)}
            subtitle="Phone Verification"
            isActive={step === 3}
            isCompleted={false}
          />
          <ProgressStep
            title={getStepTitle(4)}
            subtitle="Insurance"
            isActive={step === 4}
            isCompleted={false}
          />{' '}
        </>
      ) : (
        <ProgressStep
          title={getStepTitle(3)}
          subtitle="Insurance"
          isActive={step === 3}
          isCompleted={false}
        />
      )}
    </ProgressStepper>
  );
}
