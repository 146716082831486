import { IconButton, Link, Typography } from '@material-ui/core';
import { Add, Business, Edit, Person, Phone } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { TextBoxIcon } from 'shared/icons/Icons';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { useVerificationApplication } from 'shipper-profile/data/VerificationApplicationAPI';
import { getInsuranceTypeLabel, isEmpty } from '../data/ShipperProfileUtils';
import { EmptySection, SectionLayout } from '../SectionLayout';
import { BondInsuranceEditDrawer } from './BondInsuranceEditDrawer';

interface BondInsuranceSectionProps {
  onUpdate?: () => void;
}

export function BondInsuranceSection({ onUpdate }: BondInsuranceSectionProps) {
  const { showCustomerDialog } = useCustomerSupport();
  const { addSnackbar } = useSnackbarStack();

  const {
    data: verificationApplication,
    isLoading,
    isError,
  } = useVerificationApplication({
    onError() {
      addSnackbar('Failed to load insurance information.', {
        variant: 'error',
      });
    },
  });

  const drawerMatch = useMatch('/profile/bond-insurance/edit');
  const isContentEmpty = useMemo(
    () =>
      verificationApplication &&
      isEmpty(verificationApplication, [
        'company_insurance_type',
        'bond_agent_phone',
        'bond_agent_name',
        'bond_company_name',
        'bond_expiration_date',
      ]),
    [verificationApplication],
  );

  const navigate = useNavigate();
  const { tryBack } = useTryBack();

  const openDrawer = () => navigate('/profile/bond-insurance/edit');
  const closeDrawer = () => tryBack({ pathname: '/profile' });

  return (
    <>
      <BondInsuranceEditDrawer
        open={!!drawerMatch}
        onClose={closeDrawer}
        onUpdate={onUpdate}
      />

      {isError || isLoading ? (
        <SectionLayout title="Insurance">
          <LoadingContainer />
        </SectionLayout>
      ) : isContentEmpty ? (
        <EmptySection onClick={openDrawer} startIcon={<Add />}>
          Add Insurance
        </EmptySection>
      ) : (
        <SectionLayout
          title="Insurance"
          editComponent={
            verificationApplication?.status !== 'VERIFIED' ? (
              <IconButton
                aria-label="edit insurance"
                size="small"
                onClick={openDrawer}
              >
                <Edit />
              </IconButton>
            ) : undefined
          }
        >
          <Stack space="small">
            <Stack space="xxsmall">
              {verificationApplication?.company_insurance_type && (
                <DescriptionItem
                  wrap={true}
                  icon={<TextBoxIcon />}
                  label="Type"
                >
                  {getInsuranceTypeLabel(
                    verificationApplication.company_insurance_type,
                  )}
                </DescriptionItem>
              )}
              {verificationApplication?.bond_company_name && (
                <DescriptionItem
                  wrap={true}
                  icon={<Business />}
                  label="Company"
                >
                  {verificationApplication.bond_company_name}
                </DescriptionItem>
              )}
              {verificationApplication?.bond_agent_name && (
                <DescriptionItem icon={<Person />} label="Agent">
                  {verificationApplication.bond_agent_name}
                </DescriptionItem>
              )}
              {verificationApplication?.bond_agent_phone && (
                <DescriptionItem icon={<Phone />}>
                  <SuspendedPhoneLink
                    phone={verificationApplication.bond_agent_phone}
                    fallback={verificationApplication.bond_agent_name}
                  />
                </DescriptionItem>
              )}
              {verificationApplication?.bond_expiration_date && (
                <DescriptionItem
                  icon={<CalendarIcon />}
                  label="Expiration Date"
                >
                  <FormattedDate
                    variant="Date"
                    format="DateISO"
                    date={verificationApplication.bond_expiration_date}
                  />
                </DescriptionItem>
              )}
            </Stack>
          </Stack>
          {verificationApplication?.status === 'VERIFIED' && (
            <Stack space="xxsmall">
              <Typography color="textSecondary">
                Need to update your insurance information?
              </Typography>
              <Link
                href="#"
                color="primary"
                onClick={(event) => {
                  event.preventDefault();
                  showCustomerDialog(
                    'I need to update my insurance information,',
                  );
                }}
              >
                Contact Compliance
              </Link>
            </Stack>
          )}
        </SectionLayout>
      )}
    </>
  );
}
