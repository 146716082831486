import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { PricingRecommendationDTO } from 'orders/data/PricingSuggestionAPI';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArray';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { makeCityStateZip } from 'shared/utils/AddressUtils';
import { mileToKm } from 'shared/utils/MeasurementUtils';

function trackIncorrectPriceReport(
  order: Partial<Order>,
  recommendation: PricingRecommendationDTO | undefined,
  values: PricingInsightsReportDialogFormValues,
) {
  trackEvent('Shipper Clicked Report Incorrect Price on Pricing Insights', {
    comment: values.comment,
    price_based: values.price_based.join(','),

    confidence: recommendation?.confidence,
    distance_km: recommendation
      ? mileToKm(recommendation.distance_miles)
      : undefined,

    order_id: order.id,
    order_guid: order.guid,
    order_number: order.number,
    order_created_at: order.created_at,
    price: order.price,
    pickup: makeCityStateZip(order.pickup?.venue),
    pickup_latitude: order.pickup?.latitude,
    pickup_longitude: order.pickup?.longitude,
    delivery_latitude: order.delivery?.latitude,
    delivery_longitude: order.delivery?.longitude,
    delivery: makeCityStateZip(order.delivery?.venue),
    recommended_price: order.recommended_price,
    transport_type: order.transport_type,
    vehicles: JSON.stringify(
      order.vehicles?.map((vehicle) => ({
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
        type: vehicle.type,
        is_inoperable: vehicle.is_inoperable,
        vin: vehicle.vin,
        curb_weight: vehicle.curb_weight,
      })),
    ),
  });
}

interface PricingInsightsReportDialogProps {
  open: boolean;
  onClose: () => void;
  order: Partial<Order>;
  recommendation?: PricingRecommendationDTO;
}

export function PricingInsightsReportDialog({
  open,
  order,
  recommendation,
  onClose,
}: PricingInsightsReportDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <PricingInsightsReportDialogContent
        order={order}
        onClose={onClose}
        recommendation={recommendation}
      />
    </Dialog>
  );
}

interface PricingInsightsReportDialogContentProps {
  onClose: () => void;
  order: Partial<Order>;
  recommendation?: PricingRecommendationDTO;
}

interface PricingInsightsReportDialogFormValues {
  comment: string;
  price_based: string[];
  price_based_custom: string;
}

export function PricingInsightsReportDialogContent({
  onClose,
  order,
  recommendation,
}: PricingInsightsReportDialogContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const formik = useFormikEnhanced<
    PricingInsightsReportDialogFormValues,
    unknown
  >({
    initialValues: {
      comment: '',
      price_based: [],
      price_based_custom: '',
    },
    onSubmit: (values) => {
      const payload = {
        ...values,
        price_based: values.price_based.concat(values.price_based_custom),
      };
      trackIncorrectPriceReport(order, recommendation, payload);
      addSnackbar('Thank you! Your feedback has been submitted.', {
        variant: 'success',
      });
      onClose();
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <DialogTitle>Report Incorrect Price</DialogTitle>

        <DialogContent>
          <Stack>
            <TextBox>
              We&apos;ve documented the order details for our team to
              investigate further. Please let us know why you believe the
              recommended price is incorrect.
            </TextBox>

            <FormikTextField
              name="comment"
              multiline={true}
              fullWidth={true}
              placeholder="To skip without a comment click “Report”"
            />

            <FormikCheckboxArrayGroup name="price_based">
              <Stack space="xxsmall">
                <TextBox variant="heading-6" color="secondary">
                  What do you base your prices on?
                </TextBox>

                <FormikCheckboxArrayField
                  name="recent_moves"
                  label="Recent Moves in Pricing Insights"
                />
                <FormikCheckboxArrayField
                  name="super_loadboard"
                  label="Postings on Super Loadboard"
                />
                <FormikCheckboxArrayField
                  name="other_load_boards"
                  label="Postings on other load boards"
                />
                <FormikCheckboxArrayField
                  name="past_experience"
                  label="My past experience with similar moves"
                />
                <FormikCheckboxArrayField
                  name="other"
                  label="Other"
                  FormControlProps={{
                    style: { width: '100%' },
                  }}
                  helperText={
                    formik.values.price_based.includes('other') && (
                      <FormikTextField
                        name="price_based_custom"
                        fullWidth={true}
                      />
                    )
                  }
                />
              </Stack>
            </FormikCheckboxArrayGroup>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Report</Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
