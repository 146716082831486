import { useAtom } from 'jotai';
import { PopupModal } from 'react-calendly';
import { useUserState } from 'shared/data/AppUserState';
import { isCalendlyOpenAtom } from './useHandleOnboardingAction';

export function OnboardingCalendly() {
  const [isCalendlyOpen, setIsCalendlyOpen] = useAtom(isCalendlyOpenAtom);
  const { user } = useUserState();

  if (!user) {
    return null;
  }

  return (
    <PopupModal
      url="https://calendly.com/sanjar-superdispatch/superdispatch-office-hours"
      utm={{
        utmCampaign: 'Web STMS',
        utmMedium: 'Assisted Onboarding',
      }}
      prefill={{
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email ?? undefined,
        date: new Date(),
      }}
      onModalClose={() => setIsCalendlyOpen(false)}
      open={isCalendlyOpen}
      rootElement={document.getElementById('root') as HTMLElement}
    />
  );
}
