import { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response';
import createDirectionsClient, {
  DirectionsRequest,
  DirectionsResponse,
} from '@mapbox/mapbox-sdk/services/directions';
import createGeocodingClient, {
  GeocodeFeature,
  GeocodeProperties,
  GeocodeResponse,
} from '@mapbox/mapbox-sdk/services/geocoding';
import { get } from 'lodash-es';
import { useAPIQuery } from 'shared/api/APIQuery';

export interface MapboxGeocoding {
  place?: string;
  region?: string;
  region_short?: string;
  country?: string;
  country_short?: string;
  postcode?: string;
  latitude: number;
  longitude: number;
}

const ACCESS_TOKEN =
  'pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjazhwa2VhbTkxN3phM2dvMmcxZ2xwNXdvIn0.I3ydaXUdJkjnxjuRep4IBA';

const geocodingClient = createGeocodingClient({ accessToken: ACCESS_TOKEN });
const directionsClient = createDirectionsClient({ accessToken: ACCESS_TOKEN });

type PlaceType = 'region' | 'place' | 'country' | 'postcode';

function isGeocodeFeature(value: unknown): value is GeocodeFeature {
  return get(value, 'type') === 'Feature';
}

function getPlaceType({ id }: GeocodeFeature): PlaceType | undefined {
  switch (true) {
    case id.startsWith('country'):
      return 'country';
    case id.startsWith('place'):
      return 'place';
    case id.startsWith('postcode'):
      return 'postcode';
    case id.startsWith('region'):
      return 'region';
    default:
      return undefined;
  }
}

export function parseGeocodeFeature(
  geocodeFeature: GeocodeFeature,
): MapboxGeocoding {
  const geocoding: MapboxGeocoding = {
    longitude: Number(geocodeFeature.geometry.coordinates[0]),
    latitude: Number(geocodeFeature.geometry.coordinates[1]),
  };

  geocodeFeature.context
    .concat(geocodeFeature)
    .forEach((item: GeocodeProperties | GeocodeFeature) => {
      const placeType = getPlaceType(item);
      const { short_code } = isGeocodeFeature(item) ? item.properties : item;

      if (placeType) {
        geocoding[placeType] = item.text;
      }

      if (short_code) {
        if (placeType === 'country') {
          geocoding.country_short = short_code;
        }

        if (placeType === 'region') {
          geocoding.region_short = short_code
            .replace('US-', '')
            .replace('CA-', '');
        }
      }
    });

  return geocoding;
}

export const fetchPredictions = (query = '') =>
  geocodingClient
    .forwardGeocode({
      query,
      mode: 'mapbox.places',
      autocomplete: true,
      types: ['place', 'region', 'postcode'],
      countries: ['US', 'CA'],
    })
    .send()
    .then((res: MapiResponse) => {
      const body = res.body as GeocodeResponse;
      return body.features.map((feature: GeocodeFeature) =>
        parseGeocodeFeature(feature),
      );
    });

export function usePredictions(query: string | undefined) {
  return useAPIQuery(
    ['mapbox', 'predictions', { query }],
    () => fetchPredictions(query),
    {
      refetchOnWindowFocus: false,
      enabled: !!query,
      staleTime: Infinity,
    },
  );
}

export function fetchDirections(request: DirectionsRequest) {
  return directionsClient
    .getDirections(request)
    .send()
    .then((res: MapiResponse) => {
      const body = res.body as DirectionsResponse;
      return body;
    });
}

export function useDirections(
  origin: string | undefined,
  destination: string | undefined,
) {
  return useAPIQuery(
    ['mapbox', 'directions', { origin, destination }],
    async () => {
      const originLocations = await fetchPredictions(origin);
      const destinationLocations = await fetchPredictions(destination);

      if (!originLocations[0] || !destinationLocations[0]) {
        throw new Error('No origin or destination found');
      }

      const [originLocation, destinationLocation] = [
        originLocations[0],
        destinationLocations[0],
      ];

      const request: DirectionsRequest = {
        profile: 'driving',
        // @ts-expect-error Mapbox SDK types are incorrect
        exclude: 'ferry,country_border',
        waypoints: [
          {
            coordinates: [originLocation.longitude, originLocation.latitude],
          },
          {
            coordinates: [
              destinationLocation.longitude,
              destinationLocation.latitude,
            ],
          },
        ],
      };
      return fetchDirections(request);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!origin && !!destination,
      staleTime: Infinity,
    },
  );
}
