import {
  AllInbox,
  Check,
  DateRange,
  FiberNew,
  MoveToInbox,
} from '@material-ui/icons';
import { Container, Navbar, NavbarBottomBarItem } from '@superdispatch/ui-lab';
import { CentralDispatchMfaErrorDialog } from 'core/cental-dispatch/CentralDispatchMfaErrorDialog';
import LoadboardSettingsIssuesDialog from 'core/LoadboardSettingsIssuesDialog';
import { OnboardingCalendly } from 'onboarding/OnboardingCalendly';
import { OnboardingImmediateDialogManager } from 'onboarding/OnboardingImmediateDialogManager';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import styled from 'styled-components';
import { AuthorityRevocationGlobalDialog } from '../../shipper-profile/core/AuthorityRevocationDialog';
import { SuperPayNotifications } from '../../superpay/notifications/SuperPayNotifications';
import { useOrderCounts } from '../api/OrderCountAPI';
import { useUserState } from '../data/AppUserState';
import { useUnreadNotifications } from '../data/notifications/NotificationAPI';
import { ArrowUpIcon } from '../icons/ArrowUpIcon';
import { BulletinIcon } from '../icons/BulletinIcon';
import { useNavbarItems } from './AppNavbarItems';
import { AppNavbarMenu } from './AppNavbarMenu';
import logoCustomerPortal from './assets/logo-customer.svg';
import logoWhite from './assets/logo-white.svg';
import { HeaderBannersStack } from './HeaderBannersStack';

const AppContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const navbarContainerStyles = { overflow: 'hidden' };

let currentTab = '';

export function setCurrentTab(tab: string) {
  currentTab = tab;
}

export function getCurrentTab(): string {
  return currentTab;
}

function useBottomItems(): NavbarBottomBarItem[] {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserState();
  const { data: counts } = useOrderCounts();

  const shipper = user?.shipper;
  const shipperType = shipper?.shipper_type;
  const isCustomer = shipperType === 'CUSTOMER';
  const isOrdersPage = location.pathname.startsWith('/orders');

  const makeOrderPageUrl = (url: string) =>
    isOrdersPage ? `${url}${location.search}` : url;

  const brokerMenuItems: NavbarBottomBarItem[] = [
    {
      value: makeOrderPageUrl('/orders'),
      label: 'All Orders',
      icon: <AllInbox fontSize="small" />,
    },

    {
      value: makeOrderPageUrl('/orders/new'),
      label: 'New',
      icon: <FiberNew fontSize="small" />,
    },

    {
      value: makeOrderPageUrl('/orders/posted_to_lb'),
      label: 'Posted',
      icon: <BulletinIcon fontSize="small" />,
    },

    {
      value: makeOrderPageUrl('/orders/requests'),
      label: 'Requests',
      hasBadge: counts?.has_new_load_request,
      icon: <MoveToInbox fontSize="small" />,
    },
  ];

  const customerMenuItems: NavbarBottomBarItem[] = [
    {
      value: makeOrderPageUrl('/orders'),
      label: 'All Orders',
      icon: <AllInbox fontSize="small" />,
    },

    {
      value: makeOrderPageUrl('/orders/submitted'),
      label: 'Submitted',
      icon: <Check fontSize="small" />,
    },

    {
      value: makeOrderPageUrl('/orders/scheduled'),
      label: 'Scheduled',
      icon: <DateRange fontSize="small" />,
    },

    {
      value: makeOrderPageUrl('/orders/picked_up'),
      label: 'Picked Up',
      icon: <ArrowUpIcon fontSize="small" />,
    },
  ];

  const items = isCustomer ? customerMenuItems : brokerMenuItems;

  return items.map((item) => {
    return {
      ...item,
      active: item.value === location.pathname,
      onClick: () => navigate(item.value, { replace: true }),
    };
  });
}

interface Props {
  children: ReactNode;
}

export function AppNavbar({ children }: Props) {
  const isProductOnboardingV1Enabled = useFeatureToggle(
    'stms.product-onboarding-v1.enabled',
  );
  const isCD2FAEnabled = useFeatureToggle('cd-integration-web-2fa.enabled');
  const isSystemAlertsEnabled = useFeatureToggle('system-alerts.ui');
  const { user } = useUserState();
  const shipper = user?.shipper;
  const shipperType = shipper?.shipper_type;

  const { data: { objects: notifications = [] } = {} } =
    useUnreadNotifications();
  const hasNotifications = notifications.length > 0;

  const navbarItems = useNavbarItems();
  const bottomItems = useBottomItems();

  const isVerifiedShipper = user?.shipper.verification_status === 'VERIFIED';

  if (!user) {
    return null;
  } //to prevent flashing of navbar items under feature toggles before FT list is loaded

  return (
    <AppContainer fullViewportHeight={true}>
      {user.shipper.shipper_type === 'BROKER' && (
        <>
          <HeaderBannersStack />

          {isProductOnboardingV1Enabled && isVerifiedShipper && (
            <>
              <OnboardingImmediateDialogManager />
              <OnboardingCalendly />
            </>
          )}
        </>
      )}
      <AuthorityRevocationGlobalDialog />
      {!isSystemAlertsEnabled && <SuperPayNotifications />}
      {isCD2FAEnabled && <CentralDispatchMfaErrorDialog />}
      <LoadboardSettingsIssuesDialog />
      <Navbar
        items={navbarItems}
        bottomItems={bottomItems}
        hasExtraBadge={hasNotifications}
        containerStyle={navbarContainerStyles}
        header={
          shipperType === 'CUSTOMER' ? (
            <img
              src={logoCustomerPortal}
              width="137px"
              alt="Super Dispatch Shipper TMS"
            />
          ) : (
            <img
              src={logoWhite}
              width="161px"
              alt="Super Dispatch Shipper TMS"
            />
          )
        }
        footer={<AppNavbarMenu />}
      >
        {children}
      </Navbar>
    </AppContainer>
  );
}
